import { CreateProfileRequestUpdateData } from 'api/profile'
import { makeJsonSchema, SimpleSchema } from './validate'

const simpleSchema: SimpleSchema<CreateProfileRequestUpdateData> = {
  type: 'object',
  properties: {
    comment: { type: 'string' },
    scanStatement: { type: 'string' }
  }
}

const schema = makeJsonSchema<CreateProfileRequestUpdateData>(simpleSchema)

export default schema
