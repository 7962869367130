import { useEffect, useState } from 'react'
import { Shipping, shippingList, shippingRefuse, ShippingTruckRun, ShippingTruckRunStatus } from 'api/shipping'
import './styles.sass'
import ShippingCard from './ShippingCard'
import { Navigate, useParams } from 'react-router-dom'
import { useMainRoutes } from 'routes'
import NavigatePanel from 'common/CarrierBids/NavigatePanel'
import InfoCard from 'ui/InfoCard'
import { Stack } from '@mui/material'
import InfoCell from 'ui/InfoCell'
import { Pieces } from 'util/Pieces'
import { toTon } from 'util/weight'
import InfoCellValue from 'ui/InfoCell/InfoCellValue'
import statusToOrdering from 'common/TruckRuns/statusToOrdering'
import QRCodeModel from 'pages/CarrierTrip/QrcodeModel'
import { AstonTrafficReservedSlot } from 'api/aston-traffic'
import AstonTrafficReserveCancelModal from 'pages/CarrierTrip/AstonTrafficReserveCancelModal'
import { Bid } from 'api/bid'
import CarrierProfileCompletenessAlert from 'ui/CarrierProfileCompletenessAlert'

const STATUSES = [ShippingTruckRunStatus.new, ShippingTruckRunStatus.confirmed, ShippingTruckRunStatus.archiveReject]
// params.status in PARAMETERS_AllOWED
const PARAMETERS_AllOWED = [...STATUSES, 'all'] as string[]

const getStatInfo = (
  tabs: ShippingTruckRunStatus,
  data: { totalWeight: number, totalTruckRuns: number }) => {
  const { totalTruckRuns, totalWeight } = data
  const weight = toTon(totalWeight)
  const count = `${totalTruckRuns} ${Pieces(totalTruckRuns, 'рейс', 'рейса', 'рейсов')}`

  switch (tabs) {
    case ShippingTruckRunStatus.new:
      return <>
        <InfoCell label='Всего на рассмотрении' value={count} />
        <InfoCell label='На вывоз' value={`${weight} тн`} />
      </>
    case ShippingTruckRunStatus.confirmed:
      return <>
      <InfoCell label='Всего подтверждено' value={count} />
      <InfoCell label='На вывоз' value={`${weight} тн`} />
    </>
    case ShippingTruckRunStatus.archiveReject:
      return <>
      <InfoCell label='Всего отказано' value={count} />
      <InfoCell label='На вывоз' value={`${weight} тн`} />
    </>
    default:
      return <></>
  }
}

const getAllStatInfo = (
  status: ShippingTruckRunStatus,
  data: { totalWeight: number, totalTruckRuns: number }) => {
  const { totalTruckRuns, totalWeight } = data
  const weight = toTon(totalWeight)
  const count = `${totalTruckRuns} ${Pieces(totalTruckRuns, 'рейс', 'рейса', 'рейсов')}`
  const key = `getAllStatInfo-${status}`

  switch (status) {
    case ShippingTruckRunStatus.new:
      return <InfoCell label='Всего на рассмотрении' value={<InfoCellValue value1={count} value2={`${weight} тн`}/>} key={key} />
    case ShippingTruckRunStatus.confirmed:
      return <InfoCell label='Всего подтверждено' value={<InfoCellValue value1={count} value2={`${weight} тн`}/>} key={key} />
    case ShippingTruckRunStatus.archiveReject:
      return <InfoCell label='Всего отказано' value={<InfoCellValue value1={count} value2={`${weight} тн`}/>} key={key} />
    default:
      return <></>
  }
}

export default function CarrierShipping () {
  const [localData, setLocalData] = useState<Shipping[]>()
  const params = useParams()
  const { links } = useMainRoutes()
  const [notFoundPage, setNotFoundPage] = useState<boolean>(false)
  const [statistics, setStatistics] = useState({ totalWeight: 0, totalTruckRuns: 0 })
  const [resultsByStatus, setResultsByStatus] = useState<Shipping['resultsByStatus']>()
  const [viewQrcode, setViewQrcode] = useState<ShippingTruckRun>()
  const [refuseConflict, setRefuseConflict] = useState<{ data: ShippingTruckRun & { bid: Omit<Bid, 'freeSpace'>}, slot: AstonTrafficReservedSlot }>()

  useEffect(() => {
    if (localData === undefined) {
      shippingList({
        filters: {
          truckRunStatus: params.status === 'all'
            ? STATUSES
            : [params.status as ShippingTruckRunStatus]
        }
      })
        .then(result => {
          const { totalWeight, count, resultsByStatus } = result
          setLocalData(result.list)
          setStatistics({ totalWeight, totalTruckRuns: count })
          setResultsByStatus(resultsByStatus)
        })
    }
  })

  useEffect(() => {
    if (typeof params.status === 'string') {
      if (!PARAMETERS_AllOWED.includes(params.status)) {
        setNotFoundPage(true)
      }
      setLocalData(undefined)
    }
  }, [params.status])

  const handleRefuse = async (data: ShippingTruckRun & { bid: Omit<Bid, 'freeSpace'>}) => {
    const { success, conflicts } = await shippingRefuse(data.id)

    if (!success) {
      if (conflicts && conflicts.reservedSlot) {
        setRefuseConflict({ data, slot: conflicts.reservedSlot })
      }

      return
    }

    setRefuseConflict(undefined)
    setLocalData(undefined)
  }

  if (notFoundPage) {
    return <Navigate to={links.HOME_PAGE} />
  }

  return (<>
    <QRCodeModel truckRun={viewQrcode} onClose={() => setViewQrcode(undefined)}/>
    <AstonTrafficReserveCancelModal
        truckRun={refuseConflict?.data}
        conflictSlot={refuseConflict?.slot}
        onClose={() => setRefuseConflict(undefined) }
        onCancel={() => {
          if (refuseConflict) {
            handleRefuse(refuseConflict.data)
          }

          setRefuseConflict(undefined)
        }}
      />
    <div className='carrierShipping'>
      <CarrierProfileCompletenessAlert />
      <NavigatePanel />
      <div className='carrierShipping__body'>
        <div className='carrierShipping__body_items'>
          <InfoCard style={{ marginBottom: '1em' }} >
          { params.status !== 'all'
            ? <Stack direction='row' spacing={2}>{getStatInfo(params.status as ShippingTruckRunStatus, statistics)}</Stack>
            : <Stack direction='row' gap={1}>{
              Object.entries(resultsByStatus ?? {})
                .sort((a, b) => statusToOrdering(a[0] as ShippingTruckRunStatus) - statusToOrdering(b[0] as ShippingTruckRunStatus))
                .map(([status, { totalWeight, count }]) => getAllStatInfo(status as ShippingTruckRunStatus, { totalTruckRuns: count, totalWeight }))
            }</Stack>
          }
          </InfoCard>
        { localData?.map((v, i) => <ShippingCard
            data={v}
            key={i}
            onChange={() => setLocalData(undefined) }
            groupByStatus={params.status === 'all'}
            onViewQRCode={setViewQrcode}
            onRefuse={handleRefuse}
          />) }
        </div>
      </div>
    </div>
  </>)
}
