import { Alert, Box, Button, Stack, Typography } from '@mui/material'
import Modal from 'ui/Modal'
import { bidAllChangeCustomer, Bid } from 'api/bid'
import { useAuthContext } from 'AuthContext'
import { Customer } from 'api/customer'
import Select from 'ui/Select'
import { useState } from 'react'
import { Id } from 'api/Id'
import formateParties from 'util/formateParties'
import LightbulbIcon from '@mui/icons-material/Lightbulb'

export interface Params {
  bid?: Bid
  customers: Customer[]
  onClose: () => void
  onSuccess: () => void
}

export default function ChangeCustomerModal ({ bid, customers, onClose, onSuccess }: Params) {
  const [newCustomerId, setNewCustomerId] = useState<Id>()
  const [conflicts, setConflicts] = useState<Awaited<ReturnType<typeof bidAllChangeCustomer>>['conflicts']>()
  const { handleResponseSuccess } = useAuthContext()

  const currentCustomerId = bid?.customer.id

  const handleChange = async () => {
    if (!bid) {
      return
    }

    if (!newCustomerId) {
      return
    }

    const { id } = bid
    const { success, conflicts } = await bidAllChangeCustomer(id, newCustomerId)

    if (success) {
      handleResponseSuccess('Данные изменены')
      onSuccess()
      return
    }

    setConflicts(conflicts)
  }

  const handleCancle = () => {
    setNewCustomerId(undefined)
    setConflicts(undefined)
    onClose()
  }

  return (<Modal
    maxWidth='lg'
    open={!!bid}
    onClose={handleCancle}
    title='Сменить заказчика перевозки'
    content={<Box minWidth='500px'>
      { !conflicts && <Stack direction='column' gap={1}>
        <Alert color='info'>
          <Typography>Текущий заказчик: {bid?.customer.shortName ?? bid?.customer.fullName}</Typography>
        </Alert>
        <Alert color='warning' icon={<LightbulbIcon />}>
        <Typography>При смене заказчика все рейсы в статусе "Завершен" будут переведены в статус "Выгружен".<br />
        Ранее загруженные копии ТТН будут удалены!</Typography>
        </Alert>
        <Select
          name='customerId'
          label='Новый заказчик перевозки'
          placeholder='Выберите заказчика'
          options={customers.filter(item => item.id !== currentCustomerId).map(item => ({ value: item.id, name: formateParties(item) }))}
          value={newCustomerId}
          onChange={setNewCustomerId}
          disabled={customers.length === 0}
        />
      </Stack> }
      { conflicts && <Alert color='error'>
        {conflicts.actStatusSignedByCustomer?.length && <Typography>
          Нельзя сменить заказчика перевозки!<br />
          Есть подписанные с двух сторон пакеты документов
        </Typography>}
        {conflicts.actStatusWaitingSigning && <Typography>
          Нельзя сменить заказчика перевозки!<br />
          Для перевозки созданы документы, подписанные со стороны перевозчика<br />
          Для смены заказчика необходимо аннулировать документы: {conflicts.actStatusWaitingSigning.map(item => item.num).join('; ')}
        </Typography>}
      </Alert> }
    </Box>}
    actions={<>
      {<Stack spacing={2} justifyContent='end' direction='row'>
        <Button variant='outlined' size='small' onClick={handleCancle}>
          Отменить
        </Button>
        { !conflicts && <Button variant='contained' size='small' onClick={handleChange} >
          Сменить
        </Button>}
      </Stack> }
    </>}
/>)
}
