import { Visibility } from '@mui/icons-material'
import { Button, Chip, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { ProfileAll, ProfileContractStatus } from 'api/profile'
import CollapseList from 'ui/CollapseList'
import InfoCard from 'ui/InfoCard'
import None from 'ui/None'
import TsToFormatDate from 'util/TsToFormatDate'
import download from 'util/download'

export interface Params {
  profile?: ProfileAll
  onSave: () => void
}

export default function Contracts ({ profile, onSave }: Params) {
  if (profile === undefined) {
    return (<></>)
  }

  return (<>
    <InfoCard title='Договора' >
      <>
      { profile.contracts && profile.contracts.length > 0
        ? <Stack direction='column' spacing={2}>
          <CollapseList
            list={profile.contracts.sort((a, b) => b.createTs - a.createTs)}
            previewItems={3}
            element={item => (<>
              <Stack direction='row' spacing={2} alignItems='center' key={`contract-${item.id}`}>
                { item.status === ProfileContractStatus.new && <Typography>Согласие от {TsToFormatDate(item.dateTs, 'dd.MM.yyyy')} сформировано {TsToFormatDate(item.createTs, 'dd.MM.yyyy')} для {item.customer.shortName ?? item.customer.fullName}</Typography> }
                { item.status !== ProfileContractStatus.new && !item.inDiadoc && <Typography>Согласие от {TsToFormatDate(item.dateTs, 'dd.MM.yyyy')} отправлено вручную {TsToFormatDate(item.sendTs, 'dd.MM.yyyy')} в {item.customer.shortName ?? item.customer.fullName}</Typography> }
                { item.status !== ProfileContractStatus.new && item.inDiadoc && <Typography>Согласие от {TsToFormatDate(item.dateTs, 'dd.MM.yyyy')} отправлено через Диадок {TsToFormatDate(item.sendTs, 'dd.MM.yyyy')} в {item.customer.shortName ?? item.customer.fullName}</Typography> }

                { item.customer.scanCarrierOffer && <Button
                    size='small'
                    variant='contained'
                    color='secondary'
                    endIcon={<Visibility />}
                    onClick={() => download(item.customer.scanCarrierOffer as string)}
                  >Договор оферты</Button> }
                <>
                  { item.scanSignedСontract
                    ? <>{ item.scanSignedСontract !== '' && <Button
                      size='small'
                      variant='contained'
                      color='secondary'
                      endIcon={<Visibility />}
                      onClick={() => download(item.scanSignedСontract as string)} >Заявление</Button> }</>
                    : <>{ item.filename !== undefined && item.filename !== '' && <Button
                      size='small'
                      variant='contained'
                      color='secondary'
                      endIcon={<Visibility />}
                      onClick={() => download(item.filename as string)} >Заявление</Button> }</>
                  }
                  { item.status === ProfileContractStatus.new && <Chip label="Не подписан" variant='outlined' size='small' /> }
                  { item.status === ProfileContractStatus.signed && <Chip label="Подписан" color="primary" variant='outlined' size='small' />}
                  { item.status === ProfileContractStatus.verification && <Chip label="Ожидает проверки" color="primary" variant='outlined' size='small' />}
                  { item.status === ProfileContractStatus.canceled && <>
                    <Chip label="Отменен перевозчиком" color="error" variant='outlined' size='small' />
                    { item.inDiadoc && <Typography>Необходимо подтвердить аннуляцию в Диадок</Typography> }
                  </> }
                  { item.status === ProfileContractStatus.rejected && <>
                      <Chip label="Отклонено" color="error" variant='outlined' size='small' />
                      <Typography>{TsToFormatDate(item.createTs, 'dd.MM.yyyy hh:mm')}</Typography>
                      <Typography>{item.rejectionComment}</Typography>
                    </>
                  }
                </>
              </Stack>
            </>)}
          />
      </Stack>
        : <Stack minHeight='50px'>
        <None desc="Договора отсутствуют" />
      </Stack>}
      </>
    </InfoCard>
  </>)
}
