import { Chip, Stack, Typography } from '@mui/material'
import { Profile, ProfileAll, ProfileRequest, ProfileRequestSlugs, ProfileRequestStatus } from 'api/profile'
import CollapseList from 'ui/CollapseList'
import InfoCard from 'ui/InfoCard'
import TsToFormatDate from 'util/TsToFormatDate'
import DetailsModal from './DetailsModal'
import { useState } from 'react'
import SquareButton from 'ui/SquareButton'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

export interface Params {
  profile?: Profile | ProfileAll
}

export const PROFILE_REQUESTS_MAP: Map<ProfileRequestSlugs, string> = new Map()
PROFILE_REQUESTS_MAP.set(ProfileRequestSlugs.addDrivers, 'Запрос на разрешение добавления водителей')
PROFILE_REQUESTS_MAP.set(ProfileRequestSlugs.updateData, 'Запрос на изменения данных')

export const getStatus = ({ slug, status }: ProfileRequest) => {
  switch (`${slug}:${status}`) {
    case `${ProfileRequestSlugs.updateData}:${ProfileRequestStatus.new}`:
    case `${ProfileRequestSlugs.addDrivers}:${ProfileRequestStatus.new}`:
      return <>
        <Chip variant='outlined' color='primary' size='small' label='На рассмотрении' />
      </>
    case `${ProfileRequestSlugs.updateData}:${ProfileRequestStatus.accept}`:
      return <>
        <Chip variant='outlined' color='success' size='small' label='Выполнен' />
      </>
    case `${ProfileRequestSlugs.addDrivers}:${ProfileRequestStatus.accept}`:
      return <>
        <Chip variant='outlined' color='success' size='small' label='Одобрен' />
      </>
    case `${ProfileRequestSlugs.updateData}:${ProfileRequestStatus.cancel}`:
    case `${ProfileRequestSlugs.addDrivers}:${ProfileRequestStatus.cancel}`:
      return <>
        <Chip variant='outlined' color='error' size='small' label='Отклонен' />
        {/* { cancelComment && <Typography>Комментарий диспетчера: {cancelComment}</Typography>} */}
      </>
    default:
      return <></>
  }
}

export default function ProfileRequests ({ profile }: Params) {
  const [open, setOpen] = useState<ProfileRequest>()

  if (profile === undefined) {
    return (<></>)
  }

  if ((profile?.requests ?? []).length === 0) {
    return (<></>)
  }

  return (<>
    <InfoCard
      title='Запросы'
    >
      <CollapseList
        list={profile.requests}
        previewItems={3}
        element={(item) => {
          const { slug, createTs } = item
          return (<Stack direction='row' justifyItems='center' alignItems='center' spacing={1}>
            <Typography fontSize='14px' fontWeight={600}>{PROFILE_REQUESTS_MAP.get(slug)}:</Typography>
            <Typography fontSize='14px' fontWeight={500}>Отправлен {TsToFormatDate(createTs, 'dd.MM.yyyy HH:mm')}</Typography>
            {getStatus(item)}
            <SquareButton widthHeight='30px' variant='contained' onClick={() => setOpen(item)}><MoreHorizIcon /></SquareButton>
          </Stack>)
        }}
      />
    </InfoCard>
    <DetailsModal
      open={open}
      onClose={() => setOpen(undefined)}
    />
  </>)
}
