import { InputBaseComponentProps, Box, Stack } from '@mui/material'
import { ChangeEvent, forwardRef, ReactNode, useMemo } from 'react'
import './styles.sass'
import MaskedInput, { MaskedInputProps } from 'react-text-mask'
import clsx from 'clsx'
import { ValidateError } from 'validation/validate'
import InputBase from 'ui/InputBase'

export interface Params {
  label?: string
  hint?: ReactNode
  name?: string
  value?: string | number
  width?: string | number
  id?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
  endAdornment?: React.ReactNode
  maskParams?: MaskedInputProps
  placeholder?: string
  errors?: ValidateError
  style?: React.CSSProperties
  type?: string
  inputProps?: InputBaseComponentProps
  errorMessage?: string
  disabled?: boolean
  rows?: number
  typeNumber?: boolean
  styleContainer?: React.CSSProperties
  precision?: number
  tabIndex?: number
}

const TextField = forwardRef(({
  disabled,
  errorMessage,
  label,
  hint,
  name,
  value,
  width,
  onChange,
  onBlur,
  id,
  type,
  endAdornment,
  maskParams,
  placeholder,
  errors,
  style,
  inputProps,
  rows,
  typeNumber = false,
  styleContainer,
  precision,
  tabIndex
}: Params, ref) => {
  const eMessage = useMemo(() => {
    if (errorMessage !== undefined) {
      return errorMessage
    }

    const required = errors?.find(({ keyword, params }) => keyword === 'required' && params.missingProperty === name)

    if (required) {
      return 'Поле обязательно к заполнению'
    }

    const error = errors?.find(item => name && item.instancePath === `/${name}`)

    return error?.message
  }, [errors, name, errorMessage])

  return <>
    <div className='TextField' style={{ width, ...styleContainer }}>
      <Stack direction='row' justifyContent='space-between' sx={{
        fontWeight: '500',
        fontSize: '13px',
        lineHeight: '16px',
        letterSpacing: '-0.02em',
        whiteSpace: 'nowrap'
      }}>
        <Box sx={{ color: '#111111' }}>{label}</Box>
        { hint && <Box sx={{ color: '#B2B2B2' }}>{hint}</Box> }
      </Stack>
      { maskParams === undefined
        ? <InputBase
        className={clsx('TextField_field', { TextField_field_error: !!eMessage })}
        fullWidth
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        id={id}
        endAdornment={endAdornment}
        placeholder={placeholder}
        style={style}
        type={type}
        inputProps={inputProps}
        sx={{
          mt: '8px',
          height: rows !== undefined && rows > 1 ? 'auto' : '40px'
        }}
        disabled={disabled}
        multiline={rows !== undefined && rows > 1}
        rows={rows ?? 1}
        typeNumber={typeNumber}
        precision={precision}
        tabIndex={tabIndex}
      />
        : <MaskedInput
          {...maskParams}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          id={id}
          disabled={disabled}
          tabIndex={tabIndex}
          render={(ref, props) => <InputBase
            className={clsx('TextField_field', { TextField_field_error: !!eMessage })}
            fullWidth
            endAdornment={endAdornment}
            inputRef={ref}
            placeholder={placeholder}
            style={style}
            type={type}
            {...props}
            sx={{
              mt: '8px'
            }}
          />}
        />
      }
      <div style={{ color: 'red', fontSize: '10px', marginTop: '0.5em', height: '10px' }}>{eMessage}</div>
    </div>
  </>
})

export default TextField
