import { mainService } from 'api/service'
import { Id } from 'api/Id'

export interface AuthConfirmParams {
  thumbprint: string
  token: string
}

export interface PostMessageParams {
  boxId: string
  fromBoxId: string
  fileName: string
  documentNumber: string
  documentTs: number
  content: string
  signature: string
  needRecipientSignature: boolean
}

export interface RejectionXmlParams {
  actId: Id
  comment: string
  boxId: string
  certificateBase64: string
  thumbprint: string
}

export interface RevocationXmlParams {
  actId?: Id
  contractId?: Id
  comment: string
  boxId: string
  certificateBase64: string
  thumbprint: string
}

export interface RevocationRequestParams {
  actId?: Id
  contractId?: Id
  boxId: string
  xmlBase64: string
  signature: string
}

export interface RejectionRequestParams {
  actId: Id
  boxId: string
  xmlBase64: string
  signature: string
}

export interface GetDocumentParams {
  boxId: string
  actId: Id
}

export interface SignParams {
  boxId: string
  actId: Id
  signature: string
}

export interface SignPackParams {
  boxId: string
  actId: Id
  signatures?: {
    ParentEntityId: string
    Signature: string
  }[]
  recipientTitles?: {
    ParentEntityId: string
    SignedContent: {
      Content: string
      Signature: string
    }
  }[]
}

export enum OkResponseStatus {
  ok = 'ok',
}

export enum ErrorResponseStatus {
  err = 'error',
  tokenRequired = 'token_required',
}

export interface OkResponse {
  status: OkResponseStatus
}

export interface ErrorResponse {
  status: ErrorResponseStatus
  error?: string
}

export interface Organization {
  boxId: string
  shortName: string
  fnsParticipantId?: string
}

export interface DiadocSettings {
  boxId?: string
  routeId?: string
  watermarkText?: string
}

export type BasicResponse = OkResponse | ErrorResponse
export type ResponseString = OkResponse & { data: string } | ErrorResponse
export type ResponseOrganizationList = (OkResponse & { organizations: Organization[] }) | ErrorResponse
export type ResponsePostMessage = (OkResponse & { messageId: string, entityId: string }) | ErrorResponse
export type ResponseXml = (OkResponse & { xmlBase64: string }) | ErrorResponse
export type ResponseGetDocument = (OkResponse & { contentBase64: string }) | ErrorResponse
export type ResponseGetDocuments = (OkResponse & { documents: Record<string, string> }) | ErrorResponse

const request = (method: string, params: object) => mainService.request(`diadoc.${method}`, params)

export const diadocAuth = (certificateBase64: string) => request('auth', { certificateBase64 }) as Promise<ResponseString>
export const diadocAuthConfirm = (params: AuthConfirmParams) => request('auth-confirm', params) as Promise<ResponseString>
export const diadocLogout = () => request('logout', {}) as Promise<BasicResponse>
export const diadocGetMyOrganizations = () => request('organizations', {}) as Promise<ResponseOrganizationList>
export const diadocPostMessage = (params: PostMessageParams) => request('post-message', params) as Promise<ResponsePostMessage>
export const diadocRevocationXml = (params: RevocationXmlParams) => request('revocation-xml', params) as Promise<ResponseXml>
export const diadocRevocationRequest = (params: RevocationRequestParams) => request('revocation-request', params) as Promise<BasicResponse>
export const diadocRejectionRequest = (params: RejectionRequestParams) => request('rejection-request', params) as Promise<BasicResponse>
export const diadocRejectionXml = (params: RejectionXmlParams) => request('rejection-xml', params) as Promise<ResponseXml>
export const diadocGetDocument = (params: GetDocumentParams) => request('get-document', params) as Promise<ResponseGetDocument>
export const diadocGetDocuments = (params: GetDocumentParams) => request('get-documents', params) as Promise<ResponseGetDocuments>
export const diadocSign = (params: SignParams) => request('sign', params) as Promise<BasicResponse>
export const diadocSignPack = (params: SignPackParams) => request('sign-pack', params) as Promise<BasicResponse>
export const diadocSetOrganization = (params: { boxId: string, fnsParticipantId?: string }) => request('set-organization', params) as Promise<BasicResponse>
export const diadocGenerateTitles = (boxId: string, actIds: Id[]) => request('generate-titles', { boxId, actIds }) as Promise<BasicResponse>

export const diadocSettingsPut = (params: DiadocSettings) => request('settings.put', params) as Promise<{ upserted: boolean, modified: boolean }>
export const diadocSettingsGet = () => request('settings.get', {}) as Promise<DiadocSettings>
