import { NotifyEvent } from '.'
import { useEffect } from 'react'

export interface Data {
  createTime: string
  comment: string
  message: string
}

const SLUGS = ['profile_requests_cancel_add_drivers_event', 'profile_requests_cancel_update_data_event']

export default function ProfileRequestAddDriversCancel ({ slug, data, toast } :NotifyEvent) {
  useEffect(() => {
    if (!slug || !SLUGS.includes(slug)) {
      return
    }

    if (toast === undefined) {
      return
    }

    if (data === undefined) {
      return
    }

    const { message } = data as Data

    toast(message, 'warning')
  }, [slug, data, toast])

  return (<></>)
}
