import { Box, Button, Stack, Typography } from '@mui/material'
import Modal from 'ui/Modal'
import { bidAllUpdate, BidForUpdate } from 'api/bid'
import { ChangeEvent } from 'react'
import { useAuthContext } from 'AuthContext'
import TextField from 'ui/TextField'
import valueMethod from 'util/valueMethod'
import { toKilogram, toTon } from 'util/weight'
import DatePicker from 'ui/DatePicker'
import { clear } from 'util/date'

export interface Params {
  bid?: BidForUpdate
  setBid: (b: BidForUpdate) => void
  onClose: () => void
  onSuccess: () => void
}

export default function ExtensionBidModal ({ bid, setBid, onClose, onSuccess }: Params) {
  const { handleResponseSuccess, handleResponseFailure } = useAuthContext()

  const save = async () => {
    if (!bid) {
      return
    }

    const { id, totalWeight, endTs } = bid
    const success = await bidAllUpdate({ id, totalWeight, endTs })

    if (success) {
      handleResponseSuccess('Данные изменены')
      onSuccess()
      return
    }

    handleResponseFailure('Данные не были сохранены')
  }

  const updateTotalWeight = (e: ChangeEvent<HTMLInputElement>) => {
    const num = Number(e.target.value)
    bid && setBid({ ...bid, totalWeight: num ? toKilogram(num) : undefined })
  }

  return (<Modal
    maxWidth='lg'
    open={!!bid}
    onClose={onClose}
    title='Продление заявки'
    content={<Box minWidth='500px'>
      <Stack direction='column' gap={1}>
        <TextField
          name='totalWeight'
          label='Общий вес перевозки, тн'
          placeholder='​Укажите общий вес перевозки'
          value={valueMethod(bid?.totalWeight)(toTon)}
          onChange={updateTotalWeight}
          endAdornment={<Typography
            fontWeight={500}
            fontSize='14px'
            lineHeight='17px'
            color='#B2B2B2'
            m='1em'
          >тн.</Typography>}
          typeNumber
        />
        <DatePicker
          name='endTs'
          label='Дата завершения перевозок'
          value={bid?.endTs}
          onChange={(endTs) => bid && setBid({ ...bid, endTs })}
          shouldDisableDate={(day) => bid?.beginTs !== undefined && clear(day.getTime()) < bid?.beginTs}
        />
      </Stack>
    </Box>}
    actions={<>
      {<Stack spacing={2} justifyContent='end' direction='row'>
        <Button variant='outlined' size='small' onClick={onClose}>
          Отменить
        </Button>
        <Button variant='contained' size='small' onClick={save} >
          Продлить
        </Button>
      </Stack> }
    </>}
/>)
}
