import './styles.sass'
import { StepProps } from 'ui/ModalSigning'
import { CarrierRecreateContractWizardType } from './index'
import { Alert, Box, CircularProgress, Stack, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { profileVerifyContracts } from 'api/profile'

export default function VerifyContracts ({ isActive, data, handleNext, handleToStep }: StepProps) {
  const [error, setError] = useState('')
  const once = useRef(true)

  useEffect(() => {
    if (!isActive) {
      return
    }

    if (!once.current) {
      return
    }
    once.current = false

    const { customer, setInDiadoc, setContractId, onDateTs } = data as CarrierRecreateContractWizardType

    profileVerifyContracts()
      .then(({ conflicts: { recreate = [] } = {} }) => {
        const contract = recreate.find(item => item.customer.id === customer.id)

        if (!contract) {
          setError('Нельзя пересоздать договор!')
          return
        }

        setInDiadoc(contract.inDiadoc)
        setContractId(contract.id)
        onDateTs(contract.requiredDateTs)

        if (contract.inDiadoc) {
          handleNext()
          return
        }

        handleToStep(8)
      })
  }, [isActive, handleNext, handleToStep, data])

  if (!isActive) {
    return <></>
  }

  return (<>
    <Box sx={{ display: 'flex', minWidth: '500px', minHeight: '250px', alignItems: 'center', justifyContent: 'center' }}>
      <Stack display='flex' flexDirection='column' alignItems='center'>
        <CircularProgress />
        <Typography>Загрузка информации о договоре</Typography>
        { error && <Alert severity="error">{error}</Alert> }
      </Stack>
    </Box>
  </>)
}
