import './styles.sass'
import NavigatePanel from 'ui/NavigatePanel'
import { Alert, Button, Stack, Typography } from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useMainRoutes } from 'routes'
import { useNavigate } from 'react-router-dom'
import SaveIcon from '@mui/icons-material/Save'
import LogoutIcon from '@mui/icons-material/Logout'
import { useAuthContext } from 'AuthContext'
import InfoCard from 'ui/InfoCard'
import { ProfileForUpdate, profileUpdate, profileGet, ProfilePosition } from 'api/profile'
import TextField from 'ui/TextField'
import PasswordField from 'ui/PasswordField'
import { userUpdatePass } from 'api/user'
import { Interface } from 'api/Interface'
import { EmailMask } from 'common/Mask/EmailMask'
import schemaProfile from 'validation/DispatcherProfile'
import useValidate from 'validation/validate'
import onlyFields from 'util/onlyFields'
import DispatcherPositions from 'ui/DispatcherPositions'
import { checkCompletenessProfile, checkPosition } from 'ui/DispatcherProfileCompletenessAlert'

interface Passwords {
  passwordOld: string
  passwordNew1: string
  passwordNew2: string
}

const EMPTY_MESSAGE_FOR_FIELD = 'Заполните поле'

export default function DispatcherProfile () {
  const { logout, handleResponseSuccess, handleResponseFailure, profileRefresh, currentInterface, phone } = useAuthContext()
  const { links, routesMap } = useMainRoutes()

  const navigate = useNavigate()

  const [data, setData] = useState<ProfileForUpdate>({})
  const [dataPassword, setDataPassword] = useState<Passwords>({
    passwordOld: '',
    passwordNew1: '',
    passwordNew2: ''
  })

  const [showPassword, setShowPassword] = useState(false)
  const { check: checkProfile, errors: errorsProfile } = useValidate(schemaProfile)

  const [positions, setPositions] = useState<ProfilePosition[]>()
  const [emptyProfile, setEmptyProfile] = useState(false)
  const [emptyAllPositions, setEmptyAllPositions] = useState(false)
  const [emptyPositions, setEmptyPositions] = useState<string[]>()
  const [willExpired, setWillExpired] = useState<ProfilePosition[]>()
  const [expired, setExpired] = useState<ProfilePosition[]>()

  const isDispatcher = currentInterface ? [Interface.chief_dispatcher, Interface.dispatcher].includes(currentInterface) : false

  const init = useCallback(() => {
    profileGet().then(result => {
      if (result !== null) {
        setData({
          email: result.email,
          firstName: result.firstName,
          familyName: result.familyName,
          secondName: result.secondName
        })
        setPositions(result.positions)
        setEmptyProfile(!checkCompletenessProfile(result))

        const { isEmpty = false, willExpired, expired, emptyPositions = [] } = checkPosition(result)

        const emptyPositionsNames = emptyPositions.map(item => item.customer.shortName || item.customer.fullName)
        setEmptyPositions(emptyPositionsNames.length > 0 ? emptyPositionsNames : undefined)

        setEmptyAllPositions(isEmpty)
        setWillExpired(willExpired)
        setExpired(expired)
      }
    })
  }, [])

  useEffect(() => {
    init()
  }, [init])

  const breadcrumbsItems = useMemo(
    () => routesMap.getBreadcrumbs(links.PROFILE_PAGE),
    [links, routesMap]
  )

  const updateDataString = (key: keyof ProfileForUpdate) => (e: ChangeEvent<HTMLInputElement>) => {
    setData(prev => ({ ...prev, [key]: e.target.value }))
  }

  const updatePasswordString = (key: keyof Passwords) => (e: ChangeEvent<HTMLInputElement>) => {
    setDataPassword(prev => ({ ...prev, [key]: e.target.value }))
  }

  const saveData = useCallback(() => {
    const clear = onlyFields(data, 'email', 'firstName', 'familyName', 'secondName')
    if (!checkProfile(clear)) {
      return
    }

    profileUpdate(data).then(result => {
      if (result) {
        profileRefresh()
        handleResponseSuccess('Данные сохранены')
      }
    })
  }, [data, handleResponseSuccess, profileRefresh, checkProfile])

  const changePassword = useCallback(() => {
    if (dataPassword.passwordNew1 === '' && dataPassword.passwordNew2 === '') {
      return
    }

    if (dataPassword.passwordNew1 !== dataPassword.passwordNew2) {
      handleResponseFailure('Пароли не совпадает')
      return
    }

    userUpdatePass(dataPassword.passwordOld, dataPassword.passwordNew1).then(result => {
      if (result) {
        handleResponseSuccess('Пароль изменен')
      } else {
        handleResponseFailure('Старый пароль не подходит')
      }
    })
  }, [dataPassword, handleResponseFailure, handleResponseSuccess])

  const handleSave = () => {
    saveData()
    changePassword()
  }

  return (
    <>
      <div className='dispatcherProfile'>
        <NavigatePanel
          title={currentInterface && [Interface.chief_dispatcher, Interface.dispatcher].includes(currentInterface) ? 'Профиль диспетчера' : 'Профиль администратора'}
          breadcrumbs={{
            items: [{ title: 'Профиль' }],
            defaultItems: breadcrumbsItems
          }}
          actions={
            <Stack direction='row' spacing={2} justifyContent='end'>
              <Button variant='contained' color='error' size='small' onClick={() => logout()}>
                Выйти из профиля <LogoutIcon sx={{ width: '15px', height: '15px', ml: '10px' }}/>
              </Button>
              <Button variant='outlined' size='small' onClick={() => navigate(-1)}>
                Отменить
              </Button>
              <Button variant='contained' onClick={handleSave} size='small' sx={{ background: '#6DCD45' }} >
                Сохранить <SaveIcon sx={{ width: '15px', height: '15px', ml: '10px' }}/>
              </Button>
            </Stack>
          }
        />
        <div className='dispatcherProfile__body'>
          <div className='dispatcherProfile__content'>
            {isDispatcher && <Stack direction='column' gap={1}>
              { (emptyProfile || emptyAllPositions || emptyPositions) && <Alert color='error'>
                { emptyProfile && <Typography fontWeight={500}>Заполните основные данные профиля!</Typography> }
                { emptyAllPositions
                  ? <Typography fontWeight={500}>Заполните информацию о должностях в компаниях заказчиков!</Typography>
                  : emptyPositions
                    ? emptyPositions.length > 1
                      ? <Typography fontWeight={500}>Заполните информацию о должностях в компаниях заказчиков {emptyPositions.join(', ')}!</Typography>
                      : <Typography fontWeight={500}>Заполните информацию о должности в компании заказчика {emptyPositions[0]}!</Typography>
                    : <></>
                }
              </Alert> }
              { willExpired && willExpired.map((item, idx) => (<Alert key={idx} color='warning'>
                <Typography>Истекает срок действия доверенности для {item.customer.shortName ?? item.customer.fullName }</Typography>
              </Alert>))}
              { expired && expired.map((item, idx) => (<Alert key={idx} color='error'>
                <Typography>Истек срок действия доверенности для {item.customer.shortName ?? item.customer.fullName }</Typography>
              </Alert>))}
            </Stack> }
            <InfoCard title='Данные профиля'>
              <Stack direction='row' justifyContent='space-between' spacing={2}>
                <TextField
                  name='email'
                  width='20%'
                  label='Email'
                  value={data.email}
                  onChange={updateDataString('email')}
                  maskParams={{
                    mask: EmailMask,
                    guide: false
                  }}
                  errors={errorsProfile}
                  errorMessage={emptyProfile ? EMPTY_MESSAGE_FOR_FIELD : undefined}
                />
                <TextField
                  name='familyName'
                  width='20%'
                  label='Фамилия'
                  value={data.familyName}
                  onChange={updateDataString('familyName')}
                  errors={errorsProfile}
                  errorMessage={emptyProfile ? EMPTY_MESSAGE_FOR_FIELD : undefined}
                />
                <TextField
                  name='firstName'
                  width='20%'
                  label='Имя'
                  value={data.firstName}
                  onChange={updateDataString('firstName')}
                  errors={errorsProfile}
                  errorMessage={emptyProfile ? EMPTY_MESSAGE_FOR_FIELD : undefined}
                />
                <TextField
                  name='secondName'
                  width='20%'
                  label='Отчество'
                  value={data.secondName}
                  onChange={updateDataString('secondName')}
                  errors={errorsProfile}
                />
                <TextField
                  width='20%'
                  disabled={true}
                  label='Телефон'
                  value={phone}
                />
              </Stack>
            </InfoCard>
            <InfoCard title='Изменение пароля'>
              <Stack direction='row' justifyContent='space-between' spacing={2}>
                <PasswordField
                  width='33%'
                  label='Старый пароль'
                  value={dataPassword.passwordOld}
                  onChange={updatePasswordString('passwordOld')}
                  showPassword={showPassword}
                  onShowPassword={() => setShowPassword(prev => !prev)}
                />
                <PasswordField
                  width='33%'
                  label='Новый пароль'
                  value={dataPassword.passwordNew1}
                  onChange={updatePasswordString('passwordNew1')}
                  showPassword={showPassword}
                  onShowPassword={() => setShowPassword(prev => !prev)}
                />
                <PasswordField
                  width='33%'
                  label='Повторите новый пароль'
                  value={dataPassword.passwordNew2}
                  onChange={updatePasswordString('passwordNew2')}
                  showPassword={showPassword}
                  onShowPassword={() => setShowPassword(prev => !prev)}
                />
              </Stack>
            </InfoCard>
            { isDispatcher && positions && <DispatcherPositions positions={positions} onSuccess={init} disabled={emptyProfile} /> }
          </div>
        </div>
      </div>
    </>
  )
}
