import './styles.sass'
import { Box, CircularProgress, IconButton, Stack, Typography } from '@mui/material'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { ComponentParamsInterface, Steps, ChangeStepStatus } from '..'
import { metaOrganization } from 'entity/Organization'
import TextField from 'ui/TextField'
import {
  ORGANIZATION_INN_MASK,
  ORGANIZATION_INN_ST_MASK,
  cleanMaskNum,
  ORGANIZATION_OKVAD_MASK, formatToOkved, ORGANIZATION_KPP_MASK,
  ORGANIZATION_OKPO_ST_MASK,
  ORGANIZATION_OKPO_MASK
} from 'common/Mask/Organization'
import { schemaLtd, schemaSt, clear } from 'validation/Organization'
import useValidate from 'validation/validate'
import { Organization, TypeLegalPerson, TypeTaxation } from 'api/organization'
import Select from 'ui/Select'
import excludeFields from 'util/excludeFields'
import validateInn from 'validation/validateInn'
import { FocusFindBranch, focusFind } from 'api/focus'
import { useEnqueueSnackbar } from 'util/useEnqueueSnackbar'
import Upload from 'ui/Upload'
import validateBik from 'validation/validateBik'
import { CBRBank, cbrFindBank } from 'api/cbr'
import checkCheckingRusAccount from 'util/checkCheckingRusAccount'
import { Profile } from 'api/profile'
import { okvedList } from 'api/okved'
import { Sync } from '@mui/icons-material'
import Tooltip from 'ui/Tooltip'
import OrganisationParticipants, { ParticipantsUpdate, validateParticipants } from 'ui/OrganisationParticipants'
import AddressSuggestionsInput, { AddressData } from 'ui/AddressSuggestionsInput'

export function disableOrganization (): boolean {
  return process.env.REACT_APP_DISABLE_ORGANIZATION === 'true'
}

export function enableNewOkved () : boolean {
  return process.env.REACT_APP_ENABLE_NEW_OKVED === 'true'
}

export default function OrganizationData (props: {
  params: ComponentParamsInterface
  data: Partial<Organization>
  profile: Partial<Profile>
  checkInn: boolean
  checkKpp: boolean
  checkBik: boolean
  checkAccount: boolean
  blockedField: boolean
  bankData?: CBRBank
  doubleCarrier: boolean
  blockedKpp: boolean
  selectBranch: boolean
  branches?: FocusFindBranch[]
  participants?: ParticipantsUpdate[]
  legalAddressData?: AddressData
  mailingAddressData?: AddressData
  setParticipants:React.Dispatch<React.SetStateAction<ParticipantsUpdate[] | undefined>>
  setBranches: React.Dispatch<React.SetStateAction<FocusFindBranch[] | undefined>>
  setSelectBranch: React.Dispatch<React.SetStateAction<Partial<boolean>>>
  setBlockedKpp: React.Dispatch<React.SetStateAction<Partial<boolean>>>
  setDoubleCarrier: React.Dispatch<React.SetStateAction<Partial<boolean>>>
  setData: React.Dispatch<React.SetStateAction<Partial<Organization>>>
  setCheckInn: React.Dispatch<React.SetStateAction<boolean>>
  setCheckKpp: React.Dispatch<React.SetStateAction<boolean>>
  setBlockedField: React.Dispatch<React.SetStateAction<boolean>>
  setCheckBik: React.Dispatch<React.SetStateAction<boolean>>
  setCheckAccount: React.Dispatch<React.SetStateAction<boolean>>
  setBankData: React.Dispatch<React.SetStateAction<CBRBank | undefined>>
  setProfile: React.Dispatch<React.SetStateAction<Partial<Profile>>>
  setLegalAddressData : React.Dispatch<React.SetStateAction<AddressData | undefined>>
  setMailingAddressData : React.Dispatch<React.SetStateAction<AddressData | undefined>>
}) {
  const { check: checkLtd, errors: errorsLtd } = useValidate(schemaLtd)
  const { check: checkSt, errors: errorsSt } = useValidate(schemaSt)

  const {
    data, checkInn, setData, setCheckInn, blockedField, setBlockedField,
    checkBik, setCheckBik, checkAccount, setCheckAccount, bankData, setBankData, setProfile,
    checkKpp, setCheckKpp, blockedKpp, setBlockedKpp, setDoubleCarrier, doubleCarrier,
    selectBranch, setSelectBranch, branches, setBranches, participants, setParticipants,
    legalAddressData, setLegalAddressData, mailingAddressData, setMailingAddressData
  } = props
  const { currentStep, setCurrentStep, changeStep, setChangeStep, nextStep, prevStep } = props.params
  const [okvedOptions, setOkvedOptions] = useState<{name: string, value: string}[]>([])

  const isLtd = useMemo(() =>
    data.typeLegalPerson === TypeLegalPerson.organization
  , [data.typeLegalPerson])

  const metaOrg = useMemo(() =>
    metaOrganization(data.typeLegalPerson)
  , [data.typeLegalPerson])

  const [searchForOrganization, setSearchForOrganization] = useState(false)
  const [errorScanRequisites, setErrorScanRequisites] = useState<string>()

  const [searchBank, setSearchBank] = useState(false)
  const [disabledBankField, setDisabledBankField] = useState(true)

  const { toast, snack } = useEnqueueSnackbar()

  const doubleCarrierMessage = useCallback(() => {
    let title = `Нельзя зарегистрировать перевозчика с ИНН: ${data.inn}`

    if (checkKpp) {
      title += ` КПП: ${data.kpp}`
    }

    snack({
      title,
      color: 'warning',
      content: (<Box>
        <Typography>В системе уже зарегистрирован перевозчик, с указанным ИНН{checkKpp ? ', КПП' : ''}</Typography>
      </Box>),
      options: {
        autoHideDuration: null
      }
    })
  }, [data.inn, data.kpp, checkKpp, snack])

  const onCheck = useCallback((): boolean => {
    if (doubleCarrier) {
      doubleCarrierMessage()
      return false
    }

    const clean = excludeFields(data,
      'scanCertificate', 'scanCode',
      'scanInn', 'scanRegistration',
      'scanTaxReturnOrFeeDeclaration', 'manningTable',
      'calcPersonalIncomeTax', 'accountingBalance', 'scanReportEFS',
      'scanReceiptConsentTaxpayer', 'scanConsentTaxpayer', 'scanInsuredPersons',
      'scanPatent', 'scanLicense', 'scanFinancialResults',
      'scanRegistrationAddress', 'scanRequisites', 'scanBankCard', 'scanRegulations', 'scanAdministrationProtocol',
      'scanQuestionnaire', 'participants')

    const vinn = validateInn(clean.inn)
    setCheckInn(vinn)

    const vbik = bankData?.bik === clean.bik
    setCheckBik(vbik)

    const vAccaunt = (!!bankData?.accounts.find(item => item.correspondentAccountNumber === data.correspondentAccountNumber) && checkCheckingRusAccount(data.correspondentAccountNumber))

    setCheckAccount(vAccaunt)

    const vParticipants = validateParticipants(participants, setParticipants)

    const validate = isLtd
      ? checkLtd(clean)
      : checkSt(clean)

    if (!data.scanRequisites) {
      setErrorScanRequisites('Поле обязательно к заполнению')
      return false
    }

    return vinn && validate && vbik && vAccaunt && vParticipants
  }, [data, isLtd, bankData, checkLtd, checkSt, setCheckInn, setCheckBik,
    setCheckAccount, doubleCarrier, doubleCarrierMessage, participants, setParticipants])

  useEffect(() => {
    if (currentStep === Steps.editOrganization) {
      if (changeStep === ChangeStepStatus.next) {
        setChangeStep(ChangeStepStatus.inactive)

        if (!onCheck()) {
          return
        }

        setCurrentStep(nextStep)
      } else if (changeStep === ChangeStepStatus.prev && prevStep !== undefined) {
        setChangeStep(ChangeStepStatus.inactive)
        setCurrentStep(prevStep)
      }
    }
  }, [currentStep, nextStep, onCheck, changeStep, prevStep, setChangeStep, setCurrentStep])

  useEffect(() => {
    if (searchForOrganization && data.inn && data.typeLegalPerson) {
      focusFind(data.typeLegalPerson, data.inn, undefined, data.kpp || undefined)
        .then(({ success, organization, conflicts }) => {
          if (!success && !conflicts?.double && (organization === null || conflicts?.requestError)) {
            throw new Error('not found')
          }

          setBlockedField(true)
          setBranches(undefined)
          if (!success && conflicts && (conflicts.dissolved || conflicts.reorganizing)) {
            snack({
              title: `Нельзя зарегистрировать перевозчика с ИНН: ${data.inn}`,
              color: 'warning',
              content: (<Box>
                <Typography>Данные из ФНС: {conflicts.description}</Typography>
              </Box>),
              options: {
                autoHideDuration: null
              }
            })
            return
          }

          if (!success && conflicts && conflicts.double) {
            if (organization?.kpp) {
              if (!data.kpp) {
                setData(prev => ({ ...prev, kpp: organization.kpp }))
              }

              setBlockedKpp(false)
            }

            setDoubleCarrier(true)
            doubleCarrierMessage()
            return
          }

          if (organization) {
            const { branches, kpp, legalAddress, mailingAddress, ...org } = organization
            setData(prev => ({ ...prev, ...org }))

            if (data.typeLegalPerson === TypeLegalPerson.organization) {
              setBranches(branches)
              if (!data?.kpp?.length && branches?.length) {
                setSelectBranch(true)
              }

              if (!checkKpp && !kpp) {
                setBlockedKpp(false)
                return
              }

              if (!data.kpp && kpp) {
                setCheckKpp(true)
                setBlockedKpp(false)
                setData(prev => ({ ...prev, kpp }))
              }
            }
          }

          setCheckKpp(false)
          setBlockedField(false)
          setDoubleCarrier(false)
        })
        .catch(() => {
          toast('Данные не были получены', 'info')
          setBlockedField(false)
        })
        .finally(() => {
          setSearchForOrganization(false)
        })
    }
  }, [
    searchForOrganization, data.inn, data.kpp,
    data.typeLegalPerson, checkKpp, setCheckKpp,
    setData, toast, snack, setBlockedField,
    setBlockedKpp, doubleCarrierMessage, setDoubleCarrier,
    setSelectBranch, setBranches
  ])

  useEffect(() => {
    if (!data.bik) {
      return
    }

    const checkValue = cleanMaskNum(data.bik)
    if (!validateBik(checkValue)) {
      return
    }

    setSearchBank(true)
    cbrFindBank(data.bik)
      .then(response => {
        const { success, result, conflicts } = response

        setDisabledBankField(false)
        if (!success && conflicts?.bikNotFound) {
          setBankData(undefined)
          setCheckBik(false)
          return
        }

        setCheckBik(true)
        setBankData(result ?? undefined)

        if (result === null) {
          return
        }

        const { name: bankName, accounts } = result

        const rusAccounts = accounts
          .filter(item => checkCheckingRusAccount(item.correspondentAccountNumber))
          .map(({ correspondentAccountNumber }) => correspondentAccountNumber)

        const correspondentAccountNumber = rusAccounts.length === 1 ? rusAccounts[0] : undefined

        setData(prev => ({ ...prev, bankName, correspondentAccountNumber }))
      })
      .finally(() => setSearchBank(false))
  }, [data.bik, setCheckBik, setBankData, setData])

  useEffect(() => {
    setDisabledBankField(!bankData)
  }, [bankData])

  useEffect(() => {
    if (bankData === undefined) {
      return
    }

    if (!data?.correspondentAccountNumber?.match(/\d{20}/)) {
      return
    }

    if (bankData.accounts.find(item => item.correspondentAccountNumber === data.correspondentAccountNumber)) {
      setCheckAccount(true)
      return
    }

    setCheckAccount(false)
  }, [bankData, data.correspondentAccountNumber, setCheckAccount])

  useEffect(() => {
    if (isLtd) {
      okvedList()
        .then((result) => {
          const options = result.filter(ok => ok.isActive).map((item) => ({
            name: `${formatToOkved(item.code)} ${item.description}`,
            value: formatToOkved(item.code)
          }))
          setOkvedOptions(options)
        })
    }
  }, [isLtd])

  const handleSelectTypeTaxation = (value: TypeTaxation) => {
    setData((prev) => clear({ ...prev, typeTaxation: value } as Organization))
  }

  const updateOrganizationString = (key: keyof Organization) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      setData((prev) => clear({ ...prev, [key]: e.target.value } as Organization))
    }
  }

  const updateOrganizationOkved = (key: keyof Organization) => {
    return (value: string[]) => {
      setData((prev) => clear({ ...prev, [key]: value.length ? value : undefined } as Organization))
    }
  }
  const updateOrganizationInn = () => {
    return ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      const checkValue = cleanMaskNum(value)
      if (checkValue.length >= 10) {
        const vInn = validateInn(checkValue)
        setCheckInn(vInn)
        setSearchForOrganization(vInn)
        setDoubleCarrier(false)
        setData(prev => ({ ...prev, kpp: undefined }))
      }

      setData((prev) => clear({ ...prev, inn: value } as Organization))
    }
  }

  const updateOrganizationKpp = () => {
    return ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      const checkValue = cleanMaskNum(value)
      if (checkValue.length >= 9) {
        setCheckKpp(true)
        setSearchForOrganization(true)
      }

      setData((prev) => clear({ ...prev, kpp: value } as Organization))
    }
  }

  const updateOrganizationBik = () => {
    return ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setData((prev) => clear({ ...prev, bik: value } as Organization))
    }
  }

  const updateUpload = (key: keyof Organization) =>
    (filename?: string) =>
      setData((data) => ({ ...data, [key]: filename || undefined }))

  const typeTaxationOptions = useCallback((typeLegalPerson?: TypeLegalPerson) => {
    if (typeLegalPerson === undefined) {
      return []
    }

    const result = []

    result.push({ value: TypeTaxation.vat, name: 'Общая система налогообложения (НДС)' })
    result.push({ value: TypeTaxation.sts6, name: 'Упрощенная система налогообложения (6%)' })
    result.push({ value: TypeTaxation.sts13, name: 'Упрощенная система налогообложения (доходы минус расходы)' })
    result.push({ value: TypeTaxation.vat5, name: 'Упрощенная система налогообложения (НДС 5%)' })
    result.push({ value: TypeTaxation.vat7, name: 'Упрощенная система налогообложения (НДС 7%)' })

    if (typeLegalPerson === TypeLegalPerson.st) {
      result.push({ value: TypeTaxation.patent, name: 'Патент' })
    }

    return result
  }, [])

  const changeTypeLegalPerson = (type: TypeLegalPerson) => {
    setData(({
      inn, shortName, fullName, okved, ogrn, ogrnip, okpo, scanReportEFS, scanInsuredPersons,
      organizationHeadName, appointmenDocumentTitle, legalAddress, mailingAddress, ...data
    }) => clear({
      ...data,
      typeLegalPerson: type,
      typeTaxation: type === TypeLegalPerson.organization ? TypeTaxation.vat : TypeTaxation.sts6,
      hasAccounting: type === TypeLegalPerson.organization,
      hasLicense: false
    }))

    if (type === TypeLegalPerson.organization) {
      setProfile(prev => ({ ...prev, hasHiredDrivers: true }))
    }

    if (type === TypeLegalPerson.st) {
      setProfile(prev => ({ ...prev, hasHiredDrivers: undefined }))
      setLegalAddressData(undefined)
      setMailingAddressData(undefined)
    }

    setDoubleCarrier(false)
    setCheckKpp(false)
    setBlockedField(true)
  }

  const changeBranch = (kpp: string) => {
    const branch = branches?.find(item => item.kpp === kpp)

    if (branch === undefined) {
      return
    }

    setData(prev => ({ ...prev, kpp }))
    setSelectBranch(false)
    setCheckKpp(true)
    setSearchForOrganization(true)
  }

  return (
    <>
      <Typography fontWeight={700} fontSize='21px' mb='1.2em'>Укажите информацию о компании</Typography>
      { !disableOrganization() && <Box>
        <Typography fontWeight={600} fontSize='18px' mb='1em'>Выберите тип организации</Typography>
        <Stack direction='row' spacing={2} mb='0.5em'>
          <Select
            label='Тип организации'
            options={[
              { value: TypeLegalPerson.st, name: 'ИП' },
              { value: TypeLegalPerson.organization, name: 'ООО' }
            ]}
            value={data.typeLegalPerson}
            onChange={changeTypeLegalPerson}
            disableClearable={true}
            width='50%'
          />
          <Box width='50%'></Box>
        </Stack>
      </Box> }
      <Box position='relative'>
        { (searchForOrganization || searchBank) && <Box position='absolute' sx={{ backgroundColor: 'rgb(255 255 255 / .7)' }} width='100%' height='100%' zIndex={1000}>
          <Stack width='100%' height='100%' alignItems='center' justifyContent='center' direction='column' spacing={2} >
            <CircularProgress />
            <Typography>Поиск данных организации, подождите...</Typography>
          </Stack>
        </Box> }
        {/* <Typography fontWeight={600} fontSize='18px' mb='1em'>Информация о компании</Typography> */}
        <Stack direction='row' spacing={2} mb='0.5em'>
          <TextField
            width='33.33%'
            name='inn'
            label={metaOrg.title('inn')}
            value={data.inn ?? ''}
            onChange={updateOrganizationInn()}
            placeholder={isLtd ? 'XXXXXXXXXX' : 'XXXXXXXXXXXX'}
            maskParams={{
              mask: isLtd ? ORGANIZATION_INN_MASK : ORGANIZATION_INN_ST_MASK
            }}
            errors={isLtd ? errorsLtd : errorsSt}
            errorMessage={!checkInn ? 'ИНН не прошел проверку' : doubleCarrier ? 'В системе есть перевозчик с указанными данными' : undefined}
            disabled={searchForOrganization}
          />
          { isLtd
            ? <>
              { branches?.length && selectBranch && <Select
                name='kpp'
                label='Выберите филиал'
                options={branches?.map(({ kpp, branchName }) => ({ value: kpp, name: `${kpp} - ${branchName}` })) ?? []}
                value={data.kpp}
                onChange={changeBranch}
                disableClearable={true}
                width='33.33%'
                errors={isLtd ? errorsLtd : errorsSt}
              /> }
              { (!branches?.length || !selectBranch) && <TextField
                width='33.33%'
                name='kpp'
                label={metaOrg.title('kpp')}
                value={data.kpp}
                onChange={updateOrganizationKpp()}
                placeholder='XXXXXXXXX'
                maskParams={{
                  mask: ORGANIZATION_KPP_MASK
                }}
                errors={isLtd ? errorsLtd : errorsSt}
                disabled={blockedKpp || searchForOrganization}
                errorMessage={doubleCarrier ? 'В системе есть перевозчик с указанными данными' : undefined}
                endAdornment={branches?.length && !selectBranch && data?.kpp && <IconButton onMouseDown={() => setSelectBranch(true)}>
                  <Tooltip title='Сменить филиал' view={true}><Sync /></Tooltip>
                </IconButton>}
              /> }
            </>
            : <Box width='33.33%'></Box>
          }
          <Box width='33.33%'></Box>
        </Stack>
        <Stack direction='row' spacing={2} mb='0.5em'>
          <TextField
            width='50%'
            name='shortName'
            label={metaOrg.title('shortName')}
            placeholder={metaOrg.title('shortName')}
            value={data.shortName}
            onChange={updateOrganizationString('shortName')}
            errors={isLtd ? errorsLtd : errorsSt}
            disabled={blockedField}
          />
          <TextField
            width='50%'
            name='fullName'
            label={metaOrg.title('fullName')}
            placeholder={metaOrg.title('fullName')}
            value={data.fullName}
            onChange={updateOrganizationString('fullName')}
            errors={isLtd ? errorsLtd : errorsSt}
            disabled={blockedField}
          />
        </Stack>
        { isLtd && enableNewOkved() && <Stack direction='row' spacing={2} mb='0.5em'>
          <Select
              width='100%'
              name='okveds'
              label={metaOrg.title('okveds')}
              value={data.okveds?.map((v:string) => formatToOkved(v))}
              onChange={updateOrganizationOkved('okveds')}
              options={okvedOptions}
              errors={isLtd ? errorsLtd : errorsSt}
              disabled={blockedField}
              placeholder='XX.XX.XX'
              multiple={true}
              inputScroll={true}
            />
        </Stack> }
        <Stack direction='row' spacing={2} mb='0.5em'>
        { !isLtd
          ? <>
              <TextField
                width='50%'
                name='ogrnip'
                label={metaOrg.title('ogrnip')}
                value={data.ogrnip ?? ''}
                onChange={updateOrganizationString('ogrnip')}
                placeholder='XXXXXXXXXXXXXXX'
                maskParams={{
                  mask: metaOrg.mask('ogrnip')
                }}
                errors={isLtd ? errorsLtd : errorsSt}
                disabled={blockedField}
              />
              <TextField
                width='50%'
                name='okpo'
                label={metaOrg.title('okpo')}
                value={data.okpo ?? ''}
                onChange={updateOrganizationString('okpo')}
                placeholder='XXXXXXXXXX'
                maskParams={{
                  mask: ORGANIZATION_OKPO_ST_MASK
                }}
                errors={isLtd ? errorsLtd : errorsSt}
                disabled={blockedField}
              />
            </>
          : <>
            {
            !enableNewOkved() && <TextField
                  width='50%'
                  name='okved'
                  label={metaOrg.title('okved')}
                  value={data.okved}
                  onChange={updateOrganizationString('okved')}
                  placeholder='XX.XX.XX'
                  maskParams={{
                    mask: ORGANIZATION_OKVAD_MASK
                  }}
                  errors={isLtd ? errorsLtd : errorsSt}
                  disabled={blockedField}
                />
            }
              <TextField
                width='50%'
                name='ogrn'
                label={metaOrg.title('ogrn')}
                value={data.ogrn}
                onChange={updateOrganizationString('ogrn')}
                placeholder='XXXXXXXXXXXXX'
                maskParams={{
                  mask: metaOrg.mask('ogrn')
                }}
                errors={isLtd ? errorsLtd : errorsSt}
                disabled={blockedField}
              />
              <TextField
                width='50%'
                name='okpo'
                label={metaOrg.title('okpo')}
                value={data.okpo ?? ''}
                onChange={updateOrganizationString('okpo')}
                placeholder='XXXXXXXX'
                maskParams={{
                  mask: ORGANIZATION_OKPO_MASK
                }}
                errors={isLtd ? errorsLtd : errorsSt}
                disabled={blockedField}
              />
            </>
          }
          <Select
            name='typeTaxation'
            label='Система налогообложения'
            placeholder='Система налогообложения'
            options={typeTaxationOptions(data.typeLegalPerson)}
            value={data.typeTaxation}
            onChange={handleSelectTypeTaxation}
            disableClearable={true}
            errors={isLtd ? errorsLtd : errorsSt}
            width='50%'
            disabled={blockedField}
          />
        </Stack>
        { !isLtd
          ? <Stack direction='row' spacing={2} mb='0.5em'>
            <TextField
              width='25%'
              name='bik'
              label={metaOrg.title('bik')}
              value={data.bik}
              onChange={updateOrganizationBik()}
              placeholder='XXXXXXXXX'
              maskParams={{
                mask: metaOrg.mask('bik')
              }}
              errors={isLtd ? errorsLtd : errorsSt}
              disabled={blockedField}
              errorMessage={!checkBik ? 'Указанный БИК не найден в справочнике ЦБР' : undefined}
            />
            <TextField
              width='25%'
              name='bankName'
              label={metaOrg.title('bankName')}
              placeholder={metaOrg.title('bankName')}
              value={data.bankName}
              onChange={updateOrganizationString('bankName')}
              errors={isLtd ? errorsLtd : errorsSt}
              disabled={blockedField || disabledBankField}
            />
            <TextField
              width='25%'
              name='correspondentAccountNumber'
              label={metaOrg.title('correspondentAccountNumber')}
              value={data.correspondentAccountNumber}
              onChange={updateOrganizationString('correspondentAccountNumber')}
              placeholder='XXXXXXXXXXXXXXXXXXXX'
              maskParams={{
                mask: metaOrg.mask('correspondentAccountNumber')
              }}
              errors={isLtd ? errorsLtd : errorsSt}
              disabled={blockedField || disabledBankField}
              errorMessage={!checkCheckingRusAccount(data.correspondentAccountNumber) ? 'Некорректный к/с' : !checkAccount ? 'Номер к/с не найден для указанного БИК' : undefined}
            />
            <TextField
              width='25%'
              name='checkingAccountNumber'
              label={metaOrg.title('checkingAccountNumber')}
              value={data.checkingAccountNumber}
              onChange={updateOrganizationString('checkingAccountNumber')}
              placeholder='XXX.XX.XXX.X.XXXX.XXXXXXX'
              maskParams={{
                mask: metaOrg.mask('checkingAccountNumber')
              }}
              errors={isLtd ? errorsLtd : errorsSt}
              disabled={blockedField}
            />
          </Stack>
          : <>
            <Stack direction='row' spacing={2} mb='0.5em'>
              <TextField
                width='25%'
                name='bik'
                label={metaOrg.title('bik')}
                value={data.bik}
                onChange={updateOrganizationBik()}
                placeholder='XXXXXXXXX'
                maskParams={{
                  mask: metaOrg.mask('bik')
                }}
                errors={isLtd ? errorsLtd : errorsSt}
                disabled={blockedField}
                errorMessage={!checkBik ? 'Указанный БИК не найден в справочнике ЦБР' : undefined}
              />
              <TextField
                width='25%'
                name='bankName'
                label={metaOrg.title('bankName')}
                placeholder={metaOrg.title('bankName')}
                value={data.bankName}
                onChange={updateOrganizationString('bankName')}
                errors={isLtd ? errorsLtd : errorsSt}
                disabled={blockedField}
              />
              <TextField
                width='25%'
                name='correspondentAccountNumber'
                label={metaOrg.title('correspondentAccountNumber')}
                value={data.correspondentAccountNumber}
                onChange={updateOrganizationString('correspondentAccountNumber')}
                placeholder='XXXXXXXXXXXXXXXXXXXX'
                maskParams={{
                  mask: metaOrg.mask('correspondentAccountNumber')
                }}
                errors={isLtd ? errorsLtd : errorsSt}
                disabled={blockedField || disabledBankField}
                errorMessage={!checkCheckingRusAccount(data.correspondentAccountNumber) ? 'Некорректный к/с' : !checkAccount ? 'Номер к/с не найден для указанного БИК' : undefined}
              />
              <TextField
                width='25%'
                name='checkingAccountNumber'
                label={metaOrg.title('checkingAccountNumber')}
                value={data.checkingAccountNumber}
                onChange={updateOrganizationString('checkingAccountNumber')}
                placeholder='XXX.XX.XXX.X.XXXX.XXXXXXX'
                maskParams={{
                  mask: metaOrg.mask('checkingAccountNumber')
                }}
                errors={isLtd ? errorsLtd : errorsSt}
                disabled={blockedField || disabledBankField}
              />
            </Stack>
            <Stack direction='row' spacing={2} mb='0.5em'>
              <TextField
                width='50%'
                name='organizationHeadName'
                label={metaOrg.title('organizationHeadName')}
                placeholder={metaOrg.title('organizationHeadName')}
                value={data.organizationHeadName ?? ''}
                onChange={updateOrganizationString('organizationHeadName')}
                errors={isLtd ? errorsLtd : errorsSt}
                disabled={blockedField}
              />
              <TextField
                width='50%'
                name='position'
                label={metaOrg.title('position')}
                placeholder={metaOrg.title('position')}
                value={data.position ?? ''}
                onChange={updateOrganizationString('position')}
                errors={isLtd ? errorsLtd : errorsSt}
                disabled={blockedField}
              />
              <TextField
                width='50%'
                name='appointmenDocumentTitle'
                label={metaOrg.title('appointmenDocumentTitle')}
                placeholder={metaOrg.title('appointmenDocumentTitle')}
                value={data.appointmenDocumentTitle ?? ''}
                onChange={updateOrganizationString('appointmenDocumentTitle')}
                errors={isLtd ? errorsLtd : errorsSt}
                disabled={blockedField}
              />
            </Stack>
            <Stack direction='row' spacing={2} mb='0.5em'>
              <AddressSuggestionsInput
                width='50%'
                name='legalAddress'
                label={metaOrg.title('legalAddress')}
                placeholder={metaOrg.title('legalAddress')}
                value={data.legalAddress}
                addressData={legalAddressData}
                onChange={(legalAddress, addressData) => {
                  setData((prev) => clear({ ...prev, legalAddress } as Organization))
                  setLegalAddressData(addressData)
                }}
                errors={isLtd ? errorsLtd : errorsSt}
                disabled={blockedField}
              />
              <AddressSuggestionsInput
                width='50%'
                name='mailingAddress'
                label={metaOrg.title('mailingAddress')}
                value={data.mailingAddress}
                addressData={mailingAddressData}
                onChange={(mailingAddress, addressData) => {
                  setData((prev) => clear({ ...prev, mailingAddress } as Organization))
                  setMailingAddressData(addressData)
                }}
                placeholder={metaOrg.title('mailingAddress')}
                errors={isLtd ? errorsLtd : errorsSt}
                disabled={blockedField}
              />
            </Stack>
          </> }
        <Stack direction='row' spacing={2} mb='0.5em'>
          <Upload
            label='Справка с реквизитами, предоставленная банком'
            name="scanRequisites"
            value={data.scanRequisites}
            onChange={updateUpload('scanRequisites')}
            disabled={blockedField}
            errorMessage={errorScanRequisites}
            sx={{ width: '33%' }}
          />
        </Stack>
        { isLtd && <OrganisationParticipants rows={participants} setRows={setParticipants} disabled={blockedField} /> }
      </Box>
    </>
  )
}
