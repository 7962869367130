import './styles.sass'
import { Box, Checkbox, Stack, Typography } from '@mui/material'
import InfoCard from 'ui/InfoCard'
import Upload from 'ui/Upload'
import AntSwitch from 'ui/AntSwitch'
import { TypeLegalPerson, Organization, TypeTaxation } from 'api/organization'
import { SectionParams } from 'pages/CarrierProfile/index'
import { useMemo, useState } from 'react'
import { ProfileForCreate } from 'api/profile'
import download from 'util/download'
import { Information } from 'api/information'

type Data = Organization
type Params = SectionParams<Data> & {
  profile: ProfileForCreate
  setProfile: React.Dispatch<React.SetStateAction<Partial<ProfileForCreate>>>
  info: Information
}

export default function AccountingDocumentsProfile ({ data, setData, disabled, errors, profile, setProfile, info }: Params) {
  const [loadReportEFS, setLoadReportEFS] = useState(data.scanReportEFS !== undefined)

  const updateUpload = (key: keyof Data) =>
    (filename?: string) =>
      setData((data) => ({ ...data, [key]: filename || undefined }))

  const isLtd = useMemo(() =>
    data.typeLegalPerson === TypeLegalPerson.organization
  , [data.typeLegalPerson])
  const isVat = useMemo(() => data.typeTaxation === TypeTaxation.vat, [data.typeTaxation])

  const updateHasHiredDrivers = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    setProfile(prev => ({ ...prev, hasHiredDrivers: checked }))
    const cleaner = checked
      ? { scanInformationLetter: undefined }
      : {
          scanInsuredPersons: undefined,
          scanReportEFS: undefined,
          manningTable: undefined
        }

    setData(prev => ({ ...prev, ...cleaner }))

    if (!checked) {
      setLoadReportEFS(false)
    }
  }

  return (
    <>
      <InfoCard>
        <>
          <Stack direction='row' justifyContent='left' alignItems='center' sx={{ gap: '10px' }}>
            <AntSwitch
              checked={data.hasLicense ?? false}
              onChange={e => setData(prev => ({ ...prev, hasLicense: e.target.checked }))}
              inputProps={{ 'aria-label': 'ant design' }}
              disabled={disabled}
            />
            <Typography>Лицензия</Typography>
          </Stack>
          { data.hasLicense && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} mt={2} >
            <Typography width='67%' >Лицензия (разрешение) на осуществление деятельности (предмет договора лицензируемый вид деятельности)</Typography>
            <Upload
              name="scanLicense"
              value={data.scanLicense}
              onChange={updateUpload('scanLicense')}
              disabled={disabled}
              errors={errors}
              errorMessage={!data.scanLicense ? 'Поле обязательно к заполнению' : undefined}
              sx={{ width: '33%' }}
            />
          </Stack> }
        </>
      </InfoCard>
      <InfoCard title='Бухгалтерские документы'>
      <>
        { isLtd && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
          <Typography width='67%' >Копия банковской карточки с образцами подписей</Typography>
          <Upload
            name='scanBankCard'
            value={data.scanBankCard}
            onChange={updateUpload('scanBankCard')}
            sx={{ width: '33%' }}
            disabled={disabled}
            errors={errors}
            errorMessage={!data.scanBankCard ? 'Поле обязательно к заполнению' : undefined}
          />
        </Stack> }
        { isLtd && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
          <Typography width='67%' >Устав (все страницы), действующие изменения к Уставу</Typography>
          <Upload
            name='scanRegulations'
            value={data.scanRegulations}
            onChange={updateUpload('scanRegulations')}
            sx={{ width: '33%' }}
            disabled={disabled}
            errors={errors}
            errorMessage={!data.scanRegulations ? 'Поле обязательно к заполнению' : undefined}
          />
        </Stack> }
        { isLtd && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
          <Typography width='67%' >
              Протокол собрания участников (акционеров) или Решение единств. участника/акционера о назначении ЕИО/Передаче полномочий ЕИО управляющей компании.
              В случае передачи полномочий ЕИО Управляющей компании - Протокол собрания участников (акционеров) или Решение единств. участника/акционера Управляющей компании о назначении ЕИО УК
          </Typography>
          <Upload
            name='scanAdministrationProtocol'
            value={data.scanAdministrationProtocol}
            onChange={updateUpload('scanAdministrationProtocol')}
            sx={{ width: '33%' }}
            disabled={disabled}
            errors={errors}
            errorMessage={!data.scanAdministrationProtocol ? 'Поле обязательно к заполнению' : undefined}
          />
        </Stack> }
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
          <Typography width='67%'>Копии документов, подтверждающих место нахождения {isLtd ? 'юридического лица' : 'ИП' } по месту регистрации (договор аренды, свидетельство о праве собственности{isLtd ? '' : ', штамп регистрации по месту жительства'})</Typography>
          <Upload
            name="scanRegistrationAddress"
            value={data.scanRegistrationAddress}
            onChange={updateUpload('scanRegistrationAddress')}
            disabled={disabled}
            errors={errors}
            sx={{ width: '33%' }}
            errorMessage={!data.scanRegistrationAddress ? 'Поле обязательно к заполнению' : undefined}
          />
        </Stack>
        { (isLtd || isVat) && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
          <Typography width='67%' >Отчет о финансовых результатах за последний отчетный период с отметкой ИФНС или квитанцией о приеме ИФНС (в электр.виде)</Typography>
          <Upload
            name="scanFinancialResults"
            value={data.scanFinancialResults}
            onChange={updateUpload('scanFinancialResults')}
            disabled={disabled}
            errors={errors}
            sx={{ width: '33%' }}
            errorMessage={!data.scanFinancialResults ? 'Поле обязательно к заполнению' : undefined}
          />
        </Stack> }
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
          <Typography width='67%' >Копия налоговой декларации за {isLtd ? 'предыдущий' : 'последний' } отчетный период (УСН, ЕНВД, ЕСХН) с отметками налоговой инспекции о принятии данной декларации{isVat ? ' либо Декларация по НДС за последний отчетный период и подтверждение ее получения ИФНС (квитанция о приеме налоговой декларации в электр.виде)' : ''}</Typography>
          <Upload
            name="scanTaxReturnOrFeeDeclaration"
            value={data.scanTaxReturnOrFeeDeclaration}
            onChange={updateUpload('scanTaxReturnOrFeeDeclaration')}
            disabled={disabled}
            errors={errors}
            sx={{ width: '33%' }}
            errorMessage={!data.scanTaxReturnOrFeeDeclaration ? 'Поле обязательно к заполнению' : undefined}
          />
        </Stack>
        {/*
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
          <Typography width='67%' >Расчет сумм налога на доходы физических лиц, исчисленных и удержанных налоговым агентом (Форма 6-НДФЛ) за предыдущий отчетный период (при аттестации/заключении договора,+ не позже 10 календарных дней с даты истечение срока сдачи отчета по Форме 6-НДФЛ за отчетный период, в течение которого выполнялась перевозка</Typography>
          <Upload
            name="calcPersonalIncomeTax"
            value={data.calcPersonalIncomeTax}
            onChange={updateUpload('calcPersonalIncomeTax')}
            disabled={disabled}
            errors={errors}
            sx={{ width: '33%' }}
          />
        </Stack>
        */}
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
          <Typography width='67%' >Согласие на признание сведений, составляющих налоговую тайну, общедоступными по форме, утвержденной приказом ФНС России от 14.11.2022 N ЕД-7-19/1085@ «Об утверждении документов, предусмотренных подпунктом 1 пункта 1 и пунктом 2.3 статьи 102 Налогового кодекса Российской Федерации» (Код комплекта сведений 20013), или (при предоставлении Согласия до 13.12.2022 г) по форме, утвержденной Приказом ФНС России от 15.11.2016 № ММВ-7-17/615@, с соблюдением порядка заполнения, установленного Письмом ФНС России от 19.03.2020 г №СД-4-2/4748</Typography>
          <Upload
            name="scanConsentTaxpayer"
            value={data.scanConsentTaxpayer}
            onChange={updateUpload('scanConsentTaxpayer')}
            disabled={disabled}
            errors={errors}
            sx={{ width: '33%' }}
            errorMessage={!data.scanConsentTaxpayer ? 'Поле обязательно к заполнению' : undefined}
          />
        </Stack>
        { isVat && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
          <Typography width='67%' >Квитанция о приеме налоговым органом по телекоммуникационным каналам связи Согласия налогоплательщика (плательщика страховых взносов) на признание сведений, составляющих налоговую тайну, общедоступными</Typography>
          <Upload
            name="scanReceiptConsentTaxpayer"
            value={data.scanReceiptConsentTaxpayer}
            onChange={updateUpload('scanReceiptConsentTaxpayer')}
            disabled={disabled}
            errors={errors}
            sx={{ width: '33%' }}
            errorMessage={!data.scanReceiptConsentTaxpayer ? 'Поле обязательно к заполнению' : undefined}
          />
        </Stack> }
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
          <Typography width='67%'>Анкета по требованиям Хартии АПК РФ {info.sampleQuestionnaire !== undefined && <span
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => download(info.sampleQuestionnaire as string)}>(скачать образец)</span>}</Typography>
          <Upload
            name='scanQuestionnaire'
            value={data?.scanQuestionnaire}
            onChange={updateUpload('scanQuestionnaire')}
            sx={{ width: '33%' }}
            disabled={disabled}
            errors={errors}
            errorMessage={!data.scanQuestionnaire ? 'Поле обязательно к заполнению' : undefined}
          />
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
          <Typography width='67%' >
            Информационное письмо о деятельности {(isLtd ? info.sampleInformationLetterOrg : info.sampleInformationLetter) !== undefined && <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => download((isLtd ? info.sampleInformationLetterOrg : info.sampleInformationLetter) as string)}>(скачать образец)</span>}
          </Typography>
          <Upload
            name='scanInformationLetter'
            value={data?.scanInformationLetter}
            onChange={updateUpload('scanInformationLetter')}
            sx={{ width: '33%' }}
            errors={errors}
            disabled={disabled}
            errorMessage={!data.scanInformationLetter ? 'Поле обязательно к заполнению' : undefined}
          />
        </Stack>
        <Stack direction='row' alignItems='center' width='67%'>
          <Checkbox
            checked={!!profile.hasHiredDrivers}
            onChange={updateHasHiredDrivers}
            sx={{ ml: 0, pl: 0 }}
            disabled={disabled || isLtd}
          />
          <Typography >Предусмотрены наемные водители в штате</Typography>
        </Stack>
        { profile.hasHiredDrivers && <>
            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
              <Typography width='67%' >Сведения о застрахованных лицах Формы КНД 1151162 «Персонифицированные сведения о физических лицах», утвержденный Приказом ФНС России от 29.09.2022 N ЕД-7-11/878@ (в части сведений о водителях, направляемых для оказания услуги по Договору, с закрытыми на копии/скан-копии данными СНИЛС, ИНН и Сведениями о сумме выплат и иных вознаграждений, начисленных в пользу физического лица его Работодателем)</Typography>
              <Upload
                name="scanInsuredPersons"
                value={data.scanInsuredPersons}
                onChange={updateUpload('scanInsuredPersons')}
                disabled={disabled}
                errors={errors}
                sx={{ width: '33%' }}
                errorMessage={!data.scanInsuredPersons ? 'Поле обязательно к заполнению' : undefined}
              />
            </Stack>
            <Box>
              <Stack direction='row' alignItems='center' width='67%' spacing={2}>
                <AntSwitch
                  checked={loadReportEFS}
                  onChange={({ target: { checked } }) => setLoadReportEFS(checked)}
                  inputProps={{ 'aria-label': 'ant design' }}
                  disabled={disabled}
                />
                <Typography >Данные о водителе в Форме КНД 1151162 отсутствуют</Typography>
              </Stack>
              { loadReportEFS && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} mt={2} >
                <Typography width='67%'>Удостоверенная уполномоченным лицом копия отчета ЕФС-1, подраздел 1.1 с титульным листом утвержденная Постановлением Правления ПФ РФ от 31.10.2022 N 245п (в части соответствующего физического лица) с отметкой о его принятии Фондом пенсионного и социального страхования РФ.)</Typography>
                <Upload
                  name="scanReportEFS"
                  value={data.scanReportEFS}
                  onChange={updateUpload('scanReportEFS')}
                  disabled={disabled}
                  errors={errors}
                  sx={{ width: '33%' }}
                  errorMessage={!data.scanReportEFS ? 'Поле обязательно к заполнению' : undefined}
                />
              </Stack> }
            </Box>
            <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2, mt: 2 }}>
              <Typography width='67%' >
                Выписка из штатной расстановки {info.sampleStaffing !== undefined && <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => download(info.sampleStaffing as string)}>(скачать образец)</span>}
              </Typography>
              <Upload
                name="manningTable"
                value={data.manningTable}
                onChange={updateUpload('manningTable')}
                disabled={disabled}
                errors={errors}
                sx={{ width: '33%' }}
                errorMessage={!data.manningTable ? 'Поле обязательно к заполнению' : undefined}
              />
            </Stack>
          </> }
      </>
    </InfoCard>
    <InfoCard>
      <>
        <Stack direction='row' justifyContent='left' alignItems='center' sx={{ gap: '10px' }}>
          <AntSwitch
            checked={data.hasAccounting ?? false}
            onChange={e => setData(prev => ({ ...prev, hasAccounting: e.target.checked }))}
            inputProps={{ 'aria-label': 'ant design' }}
            disabled={isLtd || disabled}
          />
          <Typography>Ведется бухгалтерский учет</Typography>
        </Stack>
        { data.hasAccounting && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} mt={2} >
          <Typography width='67%' >Бухгалтерский баланс за последний отчетный период с отметкой ИФНС или квитанцией о приеме ИФНС (в электр.виде)</Typography>
          <Upload
            name="accountingBalance"
            value={data.accountingBalance}
            onChange={updateUpload('accountingBalance')}
            disabled={disabled}
            errors={errors}
            sx={{ width: '33%' }}
            errorMessage={!data.accountingBalance ? 'Поле обязательно к заполнению' : undefined}
          />
        </Stack> }
      </>
    </InfoCard>
    </>
  )
}
