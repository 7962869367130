import './styles.sass'
import { StepProps } from 'ui/ModalSigning'
import { Box, CircularProgress, Stack } from '@mui/material'
import { useEffect, useRef } from 'react'
import { profileVerifyContracts } from 'api/profile'
import { CarrierContractWizardType } from '.'

export default function GetDataContract ({ isActive, data, handleNext }: StepProps) {
  const once = useRef(true)

  useEffect(() => {
    if (!isActive) {
      return
    }

    if (!once.current) {
      return
    }
    once.current = false

    const { customer, onDateTs } = data as CarrierContractWizardType

    profileVerifyContracts()
      .then(({ conflicts: { withoutContract = [] } = {} }) => {
        const currentCustomer = withoutContract.find(item => item.id === customer.id)

        if (currentCustomer?.carrierContractDateTs) {
          onDateTs(currentCustomer.carrierContractDateTs)
        }

        handleNext()
      })
  }, [isActive, handleNext, data])

  if (!isActive) {
    return <></>
  }

  return (<>
    <Box sx={{ display: 'flex', minWidth: '500px', minHeight: '250px', alignItems: 'center', justifyContent: 'center' }}>
      <Stack display='flex' flexDirection='column' alignItems='center'>
        <CircularProgress />
      </Stack>
    </Box>
  </>)
}
