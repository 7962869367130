import { Stack, Typography } from '@mui/material'
import { Interface } from 'api/Interface'
import { Organization, TypeLegalPerson, TypeTaxation } from 'api/organization'
import { Profile, ProfileAll } from 'api/profile'
import { useAuthContext } from 'AuthContext'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useMainRoutes } from 'routes'
import ClosingAlert from 'ui/ClosingAlert'

const isEmpty = (field: unknown) => !field
const or = (...b: boolean[]) => !!b.find(item => item)

export const checkCompletenessAccountingDocs = (data: Organization, { hasHiredDrivers = false }: Profile | ProfileAll): boolean => {
  const {
    typeLegalPerson,
    typeTaxation,
    hasAccounting = false,
    hasLicense = false,
    scanBankCard,
    scanRegulations,
    scanAdministrationProtocol,
    scanRegistrationAddress,
    scanFinancialResults,
    scanTaxReturnOrFeeDeclaration,
    scanConsentTaxpayer,
    scanReceiptConsentTaxpayer,
    scanQuestionnaire,
    scanInformationLetter,
    scanInsuredPersons,
    manningTable,
    accountingBalance,
    scanLicense
  } = data
  const isLtd = typeLegalPerson === TypeLegalPerson.organization

  const completeness = isLtd
    ? or(
      isEmpty(scanBankCard),
      isEmpty(scanRegulations),
      isEmpty(scanAdministrationProtocol),
      isEmpty(scanRegistrationAddress),
      isEmpty(scanFinancialResults),
      isEmpty(scanTaxReturnOrFeeDeclaration),
      isEmpty(scanConsentTaxpayer),
      typeTaxation === TypeTaxation.vat && isEmpty(scanReceiptConsentTaxpayer),
      isEmpty(scanQuestionnaire),
      isEmpty(scanInformationLetter),
      hasHiredDrivers && isEmpty(scanInsuredPersons),
      hasHiredDrivers && isEmpty(manningTable),
      hasAccounting && isEmpty(accountingBalance),
      hasLicense && isEmpty(scanLicense)
    )
    : or(
      isEmpty(scanRegistrationAddress),
      typeTaxation === TypeTaxation.vat && isEmpty(scanFinancialResults),
      typeTaxation === TypeTaxation.vat && isEmpty(scanReceiptConsentTaxpayer),
      isEmpty(scanTaxReturnOrFeeDeclaration),
      isEmpty(scanConsentTaxpayer),
      isEmpty(scanQuestionnaire),
      isEmpty(scanInformationLetter),
      hasHiredDrivers && isEmpty(scanInsuredPersons),
      hasHiredDrivers && isEmpty(manningTable),
      hasAccounting && isEmpty(accountingBalance),
      hasLicense && isEmpty(scanLicense)
    )

  return completeness
}

export const ACCOUNTING_DOCS_EMPTY_MSG = 'Необходимо загрузить бухгалтерские документы!'

export default function CarrierProfileCompletenessAlert () {
  const { links } = useMainRoutes()
  const { profile, currentInterface } = useAuthContext()

  const [accountingDocsError, setAccountingDocsError] = useState<string>()

  useEffect(() => {
    if (currentInterface !== Interface.carrier) {
      return
    }

    if (!profile) {
      return
    }

    const { organization } = profile

    if (!organization) {
      return
    }

    if (checkCompletenessAccountingDocs(organization, profile)) {
      setAccountingDocsError(ACCOUNTING_DOCS_EMPTY_MSG)
    } else {
      setAccountingDocsError(undefined)
    }
  }, [profile, currentInterface])

  if (profile === undefined) {
    return (<></>)
  }

  if (!accountingDocsError) {
    return (<></>)
  }

  return (<>
    <ClosingAlert >
      <Stack direction='row' gap={1} justifyContent='center' alignItems='center' width='100%'>
        <Typography fontWeight={500}>{accountingDocsError}</Typography>
        <NavLink to={links.PROFILE_PAGE} style={{ color: '#111111', fontWeight: 200 }}>Перейти в профиль</NavLink>
      </Stack>
    </ClosingAlert>
  </>)
}
