import './styles.sass'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { StepProps } from 'ui/ModalSigning'
import { skipDiadoc } from 'ui/ActWizard'
import { useEffect, useRef, useState } from 'react'
import Alert from '@mui/material/Alert'
import { diadocRevocationRequest, ErrorResponseStatus } from 'api/diadoc'
import { DiadocUnauthorized } from 'ui/Diadoc/error/DiadocUnauthorized'
import { CarrierRecreateContractWizardType } from './index'
import { profileContractCancel } from 'api/profile'

export default function RevocationRequest ({ isActive, data, handleNext, handleToStep }: StepProps) {
  const [error, setError] = useState('')
  const once = useRef(true)

  const d = data as CarrierRecreateContractWizardType

  useEffect(() => {
    if (!isActive) {
      return
    }

    if (!d.contractId) {
      setError('Не указан документ для аннуляции')
      return
    }

    if (!once.current) {
      if (!error) {
        handleNext()
      }
      return
    }

    if (skipDiadoc() || !d.inDiadoc) {
      once.current = false

      profileContractCancel(d.contractId)
        .then((success) => {
          if (success) {
            handleNext()
            return
          }

          setError('Документ не был аннулирован')
        })
    } else {
      if (!d.boxId) {
        setError('Компания для подписания документов не выбрана')
        return
      }
      if (!d.xmlBase64) {
        setError('XML не найден')
        return
      }
      if (!d.signature) {
        setError('Подпись не найдена')
        return
      }

      once.current = false
      const contractId = d.contractId // только чтобы IDE не ругался

      diadocRevocationRequest({
        contractId,
        boxId: d.boxId,
        xmlBase64: d.xmlBase64,
        signature: d.signature
      })
        .then((response) => {
          switch (response.status) {
            case ErrorResponseStatus.tokenRequired:
              throw new DiadocUnauthorized('Нет доступа для авторизации в Diadoc')
            case ErrorResponseStatus.err:
              throw new Error(response.error !== undefined ? response.error : 'Неизвестная ошибка')
          }
        })
        .then(() => profileContractCancel(contractId))
        .then((success) => {
          if (success) {
            handleNext()
            return
          }

          throw new Error('Документ не был аннулирован')
        })
        .catch((err) => {
          if (err instanceof DiadocUnauthorized) {
            d.onAuthorized(false)
            handleToStep(d.authFirstStep)
          } else {
            setError(err.message)
          }
        })
    }
  }, [isActive, handleNext, handleToStep, d, error])

  if (!isActive) {
    return <></>
  }

  return (<>
    <Typography fontSize='14px' fontWeight={400} color='#B2B2B2' mb='2em'>
      Пожалуйста, не закрывайте это окно и дождитесь окончания отправки.
    </Typography>
    <Box sx={{ display: 'flex', minWidth: '500px', minHeight: '250px', alignItems: 'center', justifyContent: 'center' }}>
      <Stack display='flex' flexDirection='column' alignItems='center'>
        <CircularProgress />
        <Typography>Отправляем запрос</Typography>
        { error && <Alert severity="error">{error}</Alert> }
      </Stack>
    </Box>
  </>)
}
