import { Alert, Button, Stack } from '@mui/material'
import { useState } from 'react'
import Modal from 'ui/Modal'
import { useAuthContext } from 'AuthContext'
import TextField from 'ui/TextField'
import Upload from 'ui/Upload'
import { Send } from '@mui/icons-material'
import schema from 'validation/ProfileRequestUpdateData'
import useValidate from 'validation/validate'
import { CreateProfileRequestUpdateData, profileRequestUpdateData } from 'api/profile'

interface Props {
  open: boolean
  onCancel: () => void
  onSuccess: () => void
}

export default function CarrierUpdateDataRequestModal ({ open, onCancel, onSuccess }: Props) {
  const { handleResponseFailure, handleResponseSuccess } = useAuthContext()
  const [reqData, setReqData] = useState<Partial<CreateProfileRequestUpdateData>>({})
  const { check, errors, cleanErrors } = useValidate(schema)
  const [conflicts, setConflicts] = useState<string>()

  const handleCancel = () => {
    setReqData({})
    cleanErrors()
    setConflicts(undefined)
    onCancel()
  }

  const handleSand = () => {
    if (!check(reqData)) {
      console.log(errors)
      return
    }

    profileRequestUpdateData(reqData)
      .then(({ success, conflicts: { exists } = {} }) => {
        if (success) {
          handleResponseSuccess('Запрос отправлен', 'carrier-update-data-request-success')
          onSuccess()
          return
        }

        if (exists) {
          setConflicts('Уже отправлен запрос на изменение данных, дождитесь результата!')
          return
        }

        handleResponseFailure('Запрос небыл отправлен', 'carrier-update-data-request-cancle')
      })
      .catch(() => {
        handleResponseFailure('Запрос небыл отправлен', 'carrier-update-data-request-cancle')
      })
  }

  return (
    <>
      <Modal
        title='Отправить заявление на изменение данных'
        open={open}
        onClose={handleCancel}
        content={<Stack direction='column' gap={1}>
          { conflicts !== undefined
            ? <Alert color='error'>{conflicts}</Alert>
            : <>
            <Upload
              name='scanStatement'
              label='Скан заявления на изменение данных'
              value={reqData.scanStatement}
              onChange={scanStatement => setReqData(prev => ({ ...prev, scanStatement }))}
              errors={errors}
            />
            <TextField
              name='comment'
              label='Сообщение'
              rows={3}
              value={reqData.comment}
              onChange={e => setReqData(prev => ({ ...prev, comment: e.target.value || undefined }))}
              errors={errors}
            />
            </>}
        </Stack>}
        actions={<>
          <Button color='secondary' variant="outlined" onClick={handleCancel}>Отменить</Button>
          {!conflicts && <Button size='small' variant="contained" onClick={handleSand} endIcon={<Send />}>
            Отправить
          </Button>}
        </>}
      />
    </>
  )
}
