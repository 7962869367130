import './styles.sass'
import { useState } from 'react'
import { Certificate } from 'crypto-pro'
import ModalSigning from 'ui/ModalSigning'
import GetOrganizationList from '../ActWizard/GetOrganizationList'
import AuthCertificateSelect from '../ActWizard/AuthCertificateSelect'
import AuthProcess from '../ActWizard/AuthProcess'
import OrganizationSelect from '../ActWizard/OrganizationSelect'
import Info from './Info'
import CreateContract from './CreateContract'
import Exit from './Exit'
import { Organization } from 'api/diadoc'
// import SendIcon from '@mui/icons-material/Send'
import { ActWizardData } from 'ui/ActWizard'
import { RequireOnly } from 'util/type/RequireOnly'
import { Customer as CustomerOrigin } from 'api/customer'
import Signing from './Signing'
import SignManually from './SignManually'
import GetDataContract from './GetDataContract'

export type Customer = RequireOnly<CustomerOrigin, 'id' | 'fullName'>

export interface Props {
  open: boolean
  onClose: (success?: boolean) => void
  customer?: Customer
  signing?: boolean
}

export interface CarrierContractWizardType extends ActWizardData {
  contract: string,
  customer: Customer
  signing?: boolean
  isManually?: boolean
  scanSignedСontract?: string
  diadocSignStap: number
  dateTs?: number
  onCreated?: (b: boolean) => void
  onContract: (c?: string) => void
  onSuccess: (s: boolean) => void
  onManually: (s: boolean) => void
  onScanSignedСontract: (f: string) => void
  onDateTs: (d?: number) => void
}

export const DIADOC_SIGN_STAP = 5

export default function CarrierContractWizard ({ open, onClose, customer, signing }: Props) {
  const [authorized, setAuthorized] = useState(false) // has a Diadoc token
  const [organizationList, setOrganizationList] = useState<Organization[]>()
  const [certificate, setCertificate] = useState<Certificate>()
  const [boxId, setBoxId] = useState('')
  // const [isCreated, setCreated] = useState(false)
  const [contract, setContract] = useState<string>()
  const [success, setSuccess] = useState<boolean>(false)
  const [signManually, setSignManually] = useState<boolean>(false)
  const [scanSignedСontract, setScanSignedСontract] = useState<string>()
  const [dateTs, setDateTs] = useState<number>()

  return (
    <>
      { open && <ModalSigning
        open={open}
        onClose={() => onClose(success)}
        data={{
          authFirstStep: DIADOC_SIGN_STAP,
          authorized,
          organizationList,
          certificate,
          customer,
          boxId,
          signing,
          isManually: signManually,
          diadocSignStap: DIADOC_SIGN_STAP,
          onAuthorized: (b: boolean) => {
            setAuthorized(b)
            if (!b) {
              setOrganizationList(undefined)
              setCertificate(undefined)
              setBoxId('')
            }
          },
          onOrganizationListLoaded: setOrganizationList,
          onCertificateSelected: setCertificate,
          onBoxIdSelected: setBoxId,
          contract,
          onContract: setContract,
          // onCreated: setCreated,
          onSuccess: setSuccess,
          onManually: setSignManually,
          scanSignedСontract,
          onScanSignedСontract: setScanSignedСontract,
          dateTs,
          onDateTs: setDateTs
        }}
        steps={[
          {
            title: 'Получение даты договора',
            fn: GetDataContract,
            cancel: 'Отменить'
          },
          {
            title: 'Создание заявления',
            fn: Info,
            cancel: 'Отменить',
            next: 'Далее'
          },
          {
            title: 'Создание заявления',
            fn: CreateContract
            // cancel: 'Отменить',
            // next: isCreated ? <>Подписать <SendIcon sx={{ width: '16px', ml: '10px' }} /></> : undefined
          },
          {
            title: 'Подписание и отправка',
            fn: SignManually
            // cancel: 'Отменить',
            // next: isCreated ? <>Подписать <SendIcon sx={{ width: '16px', ml: '10px' }} /></> : undefined
          },
          {
            title: 'Загружаем список организаций',
            fn: GetOrganizationList, // тут мы решаем задачу определения валиден ли токен Диадока + получаем список компаний
            cancel: 'Отменить',
            next: 'Далее'
          },
          {
            title: 'Выбор сертификата',
            fn: AuthCertificateSelect,
            cancel: 'Отменить',
            next: certificate ? 'Далее' : null
          },
          {
            title: 'Авторизация в Diadoc',
            fn: AuthProcess
          },
          {
            title: 'Загружаем список организаций',
            fn: GetOrganizationList // если на первом шаге получили список компаний, этот шаг пропускается
          },
          {
            title: 'Выбор организации',
            fn: OrganizationSelect,
            cancel: 'Отменить',
            next: boxId ? 'Далее' : null
          },
          {
            title: 'Подписание и отправка',
            fn: Signing
          },
          {
            title: 'Договор подписан',
            fn: Exit
          }
        ]}
      /> }
    </>
  )
}
