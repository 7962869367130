import './styles.sass'
import { Box, Stack, Typography } from '@mui/material'
import { ChangeEvent, useEffect } from 'react'
import { ComponentParamsInterface, Steps, ChangeStepStatus } from '..'
import metaPassport from 'entity/Passport'
import { useAuthContext } from 'AuthContext'
import schemaPassport from 'validation/Passport'
import schemaProfile from 'validation/ProfileCreate'
import useValidate from 'validation/validate'
import TextField from 'ui/TextField'
import DatePicker from 'ui/DatePicker'
import { Profile } from 'api/profile'
import InfoField from 'ui/InfoField'
import excludeFields from 'util/excludeFields'
import { Passport } from 'api/passport'
import dayjs from 'dayjs'
import { EmailMask } from 'common/Mask/EmailMask'
import { PASSPORT_DEPARTAMENT_CODE_MASK } from 'common/Mask/Passport'
import formatPhone from 'util/formatPhone'
import AddressSuggestionsInput, { AddressData } from 'ui/AddressSuggestionsInput'

const cleanMaskPassportData = (data: Partial<Passport>): Partial<Passport> => ({
  ...data,
  passportSeries: data.passportSeries ? metaPassport.cleanMask('passportSeries', data.passportSeries) : undefined,
  departmentCode: data.departmentCode ? metaPassport.cleanMask('departmentCode', data.departmentCode) : undefined
})

export default function PassportAndProfileData (
  props: {
    params: ComponentParamsInterface
    profileData: Partial<Profile>
    setProfileData: React.Dispatch<React.SetStateAction<Partial<Profile>>>
    passportData: Partial<Passport>
    setPassportData: React.Dispatch<React.SetStateAction<Partial<Passport>>>
    registrationAddressData?: AddressData
    setRegistrationAddressData : React.Dispatch<React.SetStateAction<AddressData | undefined>>
  }
) {
  const { check: checkPassport, errors: errorsPassport } = useValidate(schemaPassport)
  const { check: checkProfile, errors: errorsProfile } = useValidate(schemaProfile)

  const { phone } = useAuthContext()
  const {
    profileData, setProfileData, passportData, setPassportData,
    registrationAddressData, setRegistrationAddressData
  } = props
  const { currentStep, setCurrentStep, changeStep, setChangeStep, nextStep } = props.params

  useEffect(() => {
    if (currentStep === Steps.editPassport) {
      if (changeStep === ChangeStepStatus.next) {
        setChangeStep(ChangeStepStatus.inactive)

        const cPrfl = checkProfile(profileData)
        const cPsrt = checkPassport(excludeFields(passportData, 'scan1', 'scan2', 'status'))

        if (!cPrfl || !cPsrt) {
          return
        }

        setCurrentStep(nextStep)
      }
    }
  }, [currentStep, checkPassport, checkProfile, nextStep, profileData, passportData, changeStep, setChangeStep, setCurrentStep])

  const updateProfileString = (key: keyof Profile) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === '') {
        const newState = { ...profileData }
        delete newState[key]
        setProfileData(newState)
      } else {
        setProfileData({ ...profileData, [key]: e.target.value })
      }
    }
  }

  const updatePassportString = (key: keyof Passport) => {
    return (e: ChangeEvent<HTMLInputElement>) => {
      setPassportData(cleanMaskPassportData({ ...passportData, [key]: e.target.value }))
    }
  }

  const updatePassportDate = (key: keyof Passport) => {
    return (value: number) => {
      setPassportData(cleanMaskPassportData({ ...passportData, [key]: value }))
    }
  }

  return (
    <>
      <Typography fontWeight={700} fontSize='21px' mb='1.2em'>Укажите общую информацию</Typography>
      <Box>
        <Typography fontWeight={600} fontSize='18px' mb='1em'>Данные профиля</Typography>
        <Stack direction='row' spacing={2} mb='0.5em'>
          <TextField
            name='representativeFio'
            width='50%'
            label='ФИО представителя'
            placeholder='ФИО представителя'
            value={profileData.representativeFio}
            onChange={updateProfileString('representativeFio')}
            errors={errorsProfile}
          />
          <TextField
            name='email'
            width='50%'
            label='Email'
            placeholder='Email'
            value={profileData.email}
            onChange={updateProfileString('email')}
            errors={errorsProfile}
            maskParams={{
              mask: EmailMask,
              guide: false
            }}
          />
          <InfoField
            width='50%'
            label='Телефон'
            value={formatPhone(phone)}
          />
        </Stack>
      </Box>
      <Box>
        <Typography fontWeight={600} fontSize='18px' mb='1em'>Паспортные данные</Typography>
        <Stack direction='row' spacing={2} mb='0.5em'>
          <TextField
            name='familyName'
            width='33.33%'
            label='Фамилия'
            placeholder='Фамилия'
            value={profileData.familyName}
            onChange={updateProfileString('familyName')}
            errors={errorsProfile}
          />
          <TextField
            name='firstName'
            width='33.33%'
            label='Имя'
            placeholder='Имя'
            value={profileData.firstName}
            onChange={updateProfileString('firstName')}
            errors={errorsProfile}
          />
          <TextField
            name='secondName'
            width='33.33%'
            label='Отчество'
            placeholder='Отчество'
            value={profileData.secondName}
            onChange={updateProfileString('secondName')}
            errors={errorsProfile}
          />
        </Stack>
        <Stack direction='row' spacing={2} mb='0.5em'>
          <DatePicker
            width='33.33%'
            name='dateOfBirth'
            label={metaPassport.title('dateOfBirth')}
            value={passportData.dateOfBirth}
            onChange={updatePassportDate('dateOfBirth')}
            errors={errorsPassport}
            shouldDisableDate={(day => dayjs().isBefore(day, 'day'))}
          />
          <TextField
            width='33.33%'
            name='passportSeries'
            label={metaPassport.title('passportSeries')}
            value={passportData.passportSeries}
            onChange={updatePassportString('passportSeries')}
            placeholder='11 22'
            maskParams={{
              mask: metaPassport.mask('passportSeries')
            }}
            errors={errorsPassport}
          />
          <TextField
            width='33.33%'
            name='passportNum'
            label={metaPassport.title('passportNum')}
            value={passportData.passportNum}
            onChange={updatePassportString('passportNum')}
            placeholder='123456'
            maskParams={{
              mask: metaPassport.mask('passportNum')
            }}
            errors={errorsPassport}
          />
        </Stack>
        <Stack direction='row' spacing={2} mb='0.5em'>
          <TextField
            width='50%'
            name='issuedDepartment'
            label={metaPassport.title('issuedDepartment')}
            placeholder='Паспорт выдан'
            value={passportData.issuedDepartment}
            onChange={updatePassportString('issuedDepartment')}
            errors={errorsPassport}
          />
          <DatePicker
            width='50%'
            name='dateIssue'
            label={metaPassport.title('dateIssue')}
            value={passportData.dateIssue}
            onChange={updatePassportDate('dateIssue')}
            errors={errorsPassport}
            shouldDisableDate={(day => dayjs().isBefore(day, 'day'))}
          />
        </Stack>
        <Stack direction='row' spacing={2} mb='0.5em'>
          <TextField
            width='50%'
            name='departmentCode'
            label={metaPassport.title('departmentCode')}
            value={passportData.departmentCode}
            onChange={updatePassportString('departmentCode')}
            placeholder='123'
            maskParams={{
              mask: PASSPORT_DEPARTAMENT_CODE_MASK
            }}
            errors={errorsPassport}
          />
          {<AddressSuggestionsInput
            width='50%'
            name='registrationAddress'
            label={metaPassport.title('registrationAddress')}
            placeholder='Адрес регистрации (прописка)'
            value={passportData.registrationAddress}
            onChange={(registrationAddress, addressData) => {
              setPassportData(cleanMaskPassportData({ ...passportData, registrationAddress }))
              setRegistrationAddressData(addressData)
            }}
            errors={errorsPassport}
            addressData={registrationAddressData}
          />}
        </Stack>
      </Box>
    </>
  )
}
