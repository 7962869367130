import './styles.sass'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { StepProps } from 'ui/ModalSigning'
import { skipDiadoc } from 'ui/ActWizard'
import { useEffect, useState } from 'react'
import Alert from '@mui/material/Alert'
import { diadocRevocationXml, OkResponseStatus, ErrorResponseStatus } from 'api/diadoc'
import { DiadocUnauthorized } from 'ui/Diadoc/error/DiadocUnauthorized'
import { CarrierRecreateContractWizardType } from './index'

export default function RevocationXml ({ isActive, data, handleNext, handleToStep }: StepProps) {
  const [error, setError] = useState('')

  const d = data as CarrierRecreateContractWizardType

  useEffect(() => {
    if (!isActive) {
      return
    }

    if (skipDiadoc() || d.xmlBase64 !== '') {
      handleNext()
      return
    }

    if (!d.contractId) {
      setError('Не указан документ для аннуляции')
      return
    }

    if (!d.boxId) {
      setError('Компания для подписания документов не выбрана')
      return
    }

    if (!d.certificate) {
      throw new Error('Сертификат для подписания документов не выбран')
    }

    // чтобы IDE не ругалась на возможные undefined значения
    const thumbprint = d.certificate.thumbprint
    const contractId = d.contractId

    d.certificate.exportBase64()
      .then((b64) => diadocRevocationXml({
        contractId,
        comment: '',
        boxId: d.boxId,
        certificateBase64: b64,
        thumbprint
      }))
      .then((response) => {
        switch (response.status) {
          case ErrorResponseStatus.tokenRequired:
            throw new DiadocUnauthorized('Нет доступа для авторизации в Diadoc')
          case ErrorResponseStatus.err:
            throw new Error(response.error !== undefined ? response.error : 'Неизвестная ошибка')
          case OkResponseStatus.ok:
            d.onXmlLoaded(response.xmlBase64)
            handleNext()
        }
      })
      .catch((err) => {
        if (err instanceof DiadocUnauthorized) {
          d.onAuthorized(false)
          handleToStep(d.authFirstStep)
        } else {
          setError(err.message)
        }
      })
  }, [isActive, d, handleNext, handleToStep])

  if (!isActive) {
    return <></>
  }

  return (<>
    <Typography fontSize='14px' fontWeight={400} color='#B2B2B2' mb='2em'>
      Пожалуйста, не закрывайте это окно и дождитесь окончания отправки.
    </Typography>
    <Box sx={{ display: 'flex', minWidth: '500px', minHeight: '250px', alignItems: 'center', justifyContent: 'center' }}>
      <Stack display='flex' flexDirection='column' alignItems='center'>
        <CircularProgress />
        <Typography>Формируем запрос</Typography>
        { error && <Alert severity="error">{error}</Alert> }
      </Stack>
    </Box>
  </>)
}
