import './styles.sass'
import NearestTrips from './NearestTrips'
import DashBoardNotifications from '../../ui/DashBoardNotifications'
import CarrierProfileCompletenessAlert from 'ui/CarrierProfileCompletenessAlert'

export default function CarrierHome () {
  return (
    <div className='carrierHome'>
      <CarrierProfileCompletenessAlert />
      <div className='carrierHome__content'>
        <div className='carrierHome__content_trips'>
          <NearestTrips />
        </div>
        <div className='carrierHome__content_notify'>
          <DashBoardNotifications />
        </div>
      </div>
    </div>
  )
}
