import './styles.sass'
import { Bid } from 'api/bid'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Modal from 'ui/Modal'
import InfoField from 'ui/InfoField'
import formatDate from 'common/CarrierBids/TsToFormatDate'
import { Alert, Box, Button, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import schemaShipping from 'validation/Shipping'
import useValidate from 'validation/validate'
import { shippingCreate, shippingList as apiShippingList, Shipping, ShippingTruckRunStatus as ShippingStatus } from 'api/shipping'
import { useNavigate } from 'react-router-dom'
import { useMainRoutes } from 'routes'
import { useAuthContext } from 'AuthContext'
import { Reserve } from '../../../ui/FreeSpaceItem'
import { CalendarTodaySharp } from '@mui/icons-material'
import { Id, isId } from 'api/Id'
import InfoCell from 'ui/InfoCell'
import ChipTruckRunStatus from 'common/ChipTruckRunStatus'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Customer } from 'api/customer'
import { RequireOnly } from 'util/type/RequireOnly'
import RespondTruckRuns, { TruckRun } from '../RespondTruckRuns'

interface ShippingItemProps {
  bid: Shipping['bid']
  truckRun: Shipping['truckRuns'][0]
  onGoto: (doc: Shipping['truckRuns'][0]) => void
}

const ShippingItem = ({ bid, truckRun, onGoto }: ShippingItemProps) => {
  return <Box sx={{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '1em',
    border: '1px solid #EBEBEB',
    borderRadius: '4px',
    background: '#F7F7F7',
    padding: '1em'
  }}>
    <Stack direction='row' spacing={2}>
      <InfoCell value={`Перевозка ${bid.num}`} label='Перевозка' />
      {/* <InfoCell value={`${bid.loadingAddress.city}-${bid.unloadingAddress.city}`} label='Маршрут' /> */}
    </Stack>
    <Stack direction='row' spacing={2}>
    <>{ChipTruckRunStatus(truckRun.status)}</>
    <Button
      variant='outlined'
      size='small'
      onClick={() => { onGoto(truckRun) }}
      sx={{
        maxWidth: '36px',
        minWidth: '36px',
        borderColor: '#EBEBEB'
      }}
    >
      <MoreHorizIcon />
    </Button>
    </Stack>
  </Box>
}

interface RespondModalParams {
  bid: Bid | undefined
  reserveSeat?: Reserve
  open: boolean
  onClose: () => void
  openContractModal: (customer: RequireOnly<Customer, 'id' | 'fullName'>) => void
}

export default function RespondModal ({ open, onClose, bid, reserveSeat, openContractModal }: RespondModalParams) {
  const { check } = useValidate(schemaShipping)
  const [sendOk, setSendOk] = useState<boolean>(false)
  const [isCancle, setIsCancle] = useState<boolean>(false)
  const navigate = useNavigate()
  const { links } = useMainRoutes()
  const { handleResponseFailure } = useAuthContext()

  const [warning, setWarning] = useState<string>()

  const [newItem, setNewItem] = useState<TruckRun>()
  const [trips, setTrips] = useState<number>(1)
  const [shippingList, setShippingList] = useState<Shipping[]>()

  const freeSpaceForDay = useMemo(() => bid?.freeSpace.find(item => item.shipmentTs === reserveSeat?.shipmentTs), [bid?.freeSpace, reserveSeat?.shipmentTs])
  const seatCount = useMemo(() => [...Array(freeSpaceForDay?.seats ?? 1).keys()].map(num => num + 1), [freeSpaceForDay])
  const involvedInShippingAndFree = useMemo(() => {
    if (bid === undefined || reserveSeat === undefined) {
      return
    }

    const { shipmentTs: loadingTs } = reserveSeat
    const { id: bidId } = bid
    return { bidId, loadingTs }
  }, [reserveSeat, bid])

  const truckRunItems = useMemo(() => {
    if (newItem === undefined) {
      return []
    }

    return [...Array(trips).keys()].map<TruckRun>(() => ({ ...newItem }))
  }, [newItem, trips])

  const shippingInfo = useMemo(() => (shippingList ?? []).reduce((prev, cur) => {
    const current = prev.current
    const active = prev.active

    const cTR = cur.truckRuns.filter(item => [ShippingStatus.new, ShippingStatus.confirmed].includes(item.status))
    if (cTR.length > 0) {
      current.push({ bid: cur.bid, truckRuns: cTR })
    }

    const aTR = cur.truckRuns.filter(item => item.status === ShippingStatus.way)
    if (aTR.length > 0) {
      active.push({ bid: cur.bid, truckRuns: aTR })
    }

    return { current, active }
  }, { current: [] as Shipping[], active: [] as Shipping[] }), [shippingList])

  const handleOpenTrip = (truckRun: Shipping['truckRuns'][0]) => {
    const link = [ShippingStatus.new, ShippingStatus.archiveReject].includes(truckRun.status)
      ? links.CARRIER_BIDS_SHIPPING_INFO_PAGE
      : links.CARRIER_TRIPS_PAGE
    navigate(`${link}/${truckRun.id}`)
  }

  useEffect(() => {
    if (reserveSeat === undefined) {
      return
    }

    const { shipmentTs: loadingTs } = reserveSeat

    setNewItem({ loadingTs })
  }, [reserveSeat])

  const driverFilter = useRef<Id>()
  useEffect(() => {
    if (newItem === undefined) {
      return
    }

    const { loadingTs, driverId } = newItem

    if (driverId === driverFilter.current) {
      return
    }

    driverFilter.current = driverId

    apiShippingList({
      filters: {
        truckRunStatus: [ShippingStatus.new, ShippingStatus.confirmed, ShippingStatus.way],
        loadingTs,
        driverId
      }
    })
      .then(r => r.list)
      .then(setShippingList)
  }, [newItem])

  const cancel = useCallback(() => {
    driverFilter.current = undefined
    setShippingList(undefined)
    setIsCancle(false)
    setSendOk(false)
    setWarning(undefined)
    onClose()
  }, [onClose])

  // TODO
  // const hasEmptyTrailer = newItem?.driverId && newItem?.vehicleId
  //   ? trailers(newItem?.driverId, newItem?.vehicleId).find(({ value }) => value === 'none') !== undefined
  //   : false

  const checkChanges = () => {
    let isChanged = false

    for (const item of truckRunItems) {
      if (isId(item.driverId)) {
        isChanged = true
        break
      }
    }

    return isChanged
  }

  const handleCancel = () => {
    if (checkChanges() && !sendOk) {
      setIsCancle(true)
    } else {
      cancel()
    }
  }

  const handleClose = () => (isCancle ? cancel : handleCancel)()

  const send = useCallback(() => {
    if (bid === undefined || truckRunItems.length === 0) {
      return
    }

    const shipping = {
      bidId: bid.id,
      truckRuns: truckRunItems
    }

    if (!check(shipping)) {
      handleResponseFailure('Необходимо заполнить все поля')
      return
    }

    shippingCreate(shipping).then(({ success, conflicts }) => {
      if (success) {
        setSendOk(true)
      } else if (conflicts?.verificationContract) {
        setWarning('Ваше заявление проверяется диспетчером')
      } else if (conflicts?.customer) {
        cancel()
        openContractModal(conflicts.customer)
      }
    })
  }, [truckRunItems, bid, check, handleResponseFailure, openContractModal, cancel])

  return (
    <>
      <Modal
        maxWidth={false}
        open={open}
        onClose={warning !== undefined ? cancel : handleClose}
        title={!sendOk ? 'Откликнуться на заявку' : 'Отклик на заявку отправлен'}
        content={<>
          {
            warning !== undefined
              ? <Alert color='warning'>{warning}</Alert>
              : !isCancle
                  ? !sendOk
                      ? <div className='bidShippingRespondModal'>
                { reserveSeat && <div className='bidShippingRespondModal_days'>
                  <div className='bidShippingRespondModal_days_day' key={reserveSeat.shipmentTs}>
                    <div className='bidShippingRespondModal_days_day_desc'>
                      <TextField
                        variant='outlined'
                        size='small'
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='start'>
                              <CalendarTodaySharp style={{ color: '#B2B2B2' }} />
                            </InputAdornment>
                          )
                        }}
                        value={formatDate(reserveSeat.shipmentTs, 'dd MMMM yyyy')}
                        sx={{
                          width: '45%',
                          height: '40px',
                          background: '#F7F7F7',
                          '& fieldset': {
                            border: '1px solid #EBEBEB',
                            borderRadius: '4px'
                          }
                        }}
                      />
                      <InfoField
                        value={freeSpaceForDay ? `Свободно ${freeSpaceForDay.seats} из ${freeSpaceForDay.totalSeats} машино-мест` : ''}
                        width='45%'
                        color='green'
                      />
                    </div>
                    <RespondTruckRuns
                      truckRun={newItem}
                      setTruckRun={setNewItem}
                      trips={trips}
                      setTrips={setTrips}
                      seatCount={seatCount}
                      involvedInShippingAndFree={involvedInShippingAndFree}
                      loadOptions={reserveSeat !== undefined}
                      title='Основная информация'
                    />
                  </div>
                </div> }
                <div className='bidShippingRespondModal_days_day_shipping'>
                  { shippingInfo.current.length > 0 && <>
                    <Typography fontWeight={600} fontSize='18px' mb='1em'>Текущие отклики</Typography>
                    <div className='bidShippingRespondModal_days_day_shipping_items'>
                    { shippingInfo.current.map(({ bid, truckRuns }) => {
                      return truckRuns.map((item, idx) => <ShippingItem bid={bid} truckRun={item} onGoto={handleOpenTrip} key={`current-${idx}`}/>)
                    }) }
                    </div>
                    </> }
                  { shippingInfo.active.length > 0 && <>
                    <Typography fontWeight={600} fontSize='18px' mb='1em'>Активные рейсы</Typography>
                    <div className='bidShippingRespondModal_days_day_shipping_items'>
                    { shippingInfo.active.map(({ bid, truckRuns }) => {
                      return truckRuns.map((item, idx) => <ShippingItem bid={bid} truckRun={item} onGoto={handleOpenTrip} key={`active-${idx}`}/>)
                    }) }
                    </div>
                    </> }
                </div>
              </div>
                      : <Typography>Благодарим за отклик на заявку. Мы сообщим о статусе Вашего отклика уведомлением.</Typography>
                  : <Typography>Вы уверены, что хотите закрыть заявку? Заполненные данные не будут сохранены.</Typography>}
        </>}
        actions={<Stack direction='row' justifyContent='flex-end' spacing={1} width='100%'>
          { warning
            ? <Button variant='outlined' onClick={cancel} >Отменить</Button>
            : !isCancle
                ? !sendOk
                    ? <>
                <Button variant='outlined' onClick={handleCancel} >Отменить</Button>
                <Button variant='contained' onClick={send} className='carrierBid_content_buttonResponse' >Отправить</Button>
              </>
                    : <>
                <Button variant='outlined' onClick={() => navigate(links.CARRIER_BIDS_SHIPPING_PAGE + '/new')} >Просмотреть мой отклик</Button>
                <Button variant='contained' onClick={() => navigate(links.CARRIER_BIDS_PAGE)} className='carrierBid_content_buttonResponse' >Вернуться к списку заявок</Button>
              </>
                : <>
                <Button variant='outlined' onClick={cancel}>Отменить</Button>
                <Button variant='contained' onClick={() => setIsCancle(false)} className='carrierBid_content_buttonResponse' >Остаться на странице</Button>
            </>}
        </Stack>}
      />
    </>
  )
}
