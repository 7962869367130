import { useEffect, useRef, useState } from 'react'
import './styles.sass'
import { StepProps } from 'ui/ModalSigning'
import { ActWizardData } from './index'
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import { ProfileContract, ProfileContractCustomer, profileVerifyContracts } from 'api/profile'
import { useNavigate } from 'react-router-dom'
import { useMainRoutes } from 'routes'

export default function VerifyContracts ({ isActive, data, handleNext }: StepProps) {
  const [recreate, setRecreate] = useState<ProfileContract[]>([])
  const [withoutContract, setWithoutContract] = useState<ProfileContractCustomer[]>([])
  const [load, setLoad] = useState(true)

  const { links } = useMainRoutes()
  const navigate = useNavigate()

  const d = data as ActWizardData

  const once = useRef(true)
  useEffect(() => {
    if (!isActive) {
      return
    }

    if (!once.current) {
      return
    }
    once.current = false

    if (d.shippingList && d.numbering.length === 0) {
      const customerIds = [...new Set(d.shippingList.map(item => item.bid.customer.id))]

      profileVerifyContracts()
        .then(({ success, conflicts }) => {
          setLoad(false)

          if (success) {
            handleNext()
            return
          }

          const recreate = conflicts?.recreate?.filter(item => customerIds.includes(item.customer.id)) ?? []
          const withoutContract = conflicts?.withoutContract?.filter(item => customerIds.includes(item.id)) ?? []

          if (recreate.length === 0 && withoutContract.length === 0) {
            handleNext()
            return
          }

          setRecreate(recreate)
          setWithoutContract(withoutContract)
        })
    }
  }, [isActive, handleNext, d])

  if (!isActive) {
    return <></>
  }

  return (<>
    <Box sx={{ display: 'flex', minWidth: '500px', minHeight: '250px', justifyContent: 'center' }}>
      <Stack display='flex' flexDirection='column' gap={1}>
        { load && <CircularProgress /> }
        { withoutContract.length > 0 && <>
          <Typography fontWeight={600}>Необходимо отправить заявление для {withoutContract.length === 1 ? 'следующего заказчика' : 'следующих заказчиков'}:</Typography>
          { withoutContract.map(item => (<Typography key={item.id}>{item.shortName ?? item.fullName}</Typography>)) }
        </> }
        { recreate.length > 0 && <>
          <Typography fontWeight={600}>Необходимо отправить новое заявление для {recreate.length === 1 ? 'следующего заказчика' : 'следующих заказчиков'}:</Typography>
          { recreate.map(item => (<Typography key={item.id}>{item.customer.shortName ?? item.customer.fullName}</Typography>)) }
        </> }
      </Stack>
    </Box>
    { (withoutContract.length > 0 || recreate.length > 0) && <Button
      fullWidth
      variant='contained'
      onClick={() => navigate(links.PROFILE_PAGE)}
    >Перейти в профиль</Button>}
  </>)
}
