import './styles.sass'
import { Grid } from '@mui/material'
import { ChangeEvent } from 'react'
import InfoCard from 'ui/InfoCard'
import TextField from 'ui/TextField'
import { SectionParams } from 'pages/CarrierProfile/index'
import metaPassport from 'entity/Passport'
import { PassportForUpdate } from 'api/passport'
import DatePicker from 'ui/DatePicker'
import dayjs from 'dayjs'
import { PASSPORT_DEPARTAMENT_CODE_MASK } from 'common/Mask/Passport'
import AddressSuggestionsInput, { AddressData } from 'ui/AddressSuggestionsInput'
import { clear } from 'validation/Passport'
import { ProfileForCreate } from 'api/profile'
import { ValidateError } from 'validation/validate'

type Data = PassportForUpdate

type Params<T> = SectionParams<T> & {
  registrationAddressData?: AddressData
  setRegistrationAddressData : React.Dispatch<React.SetStateAction<AddressData | undefined>>
  dataProfile: ProfileForCreate
  setDataProfile: React.Dispatch<React.SetStateAction<ProfileForCreate>>
  errorsProfile: ValidateError
}

export default function PassportCarrierProfile ({
  data, setData, disabled, errors, onBlur,
  registrationAddressData, setRegistrationAddressData,
  dataProfile, setDataProfile, errorsProfile
}: Params<Data>) {
  const updateString = (key: keyof Data) =>
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
      setData((data) => clear({ ...data, [key]: value === '' ? undefined : value }))

  const updateDate = (key: keyof Data) =>
    (value: number) =>
      setData((data) => ({ ...data, [key]: value }))

  const updateProfileString = (key: keyof ProfileForCreate) =>
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
      setDataProfile((data) => ({ ...data, [key]: value === '' ? undefined : value }))

  return (
    <InfoCard title="Паспортные данные">
      <Grid container spacing={2}>
      <Grid item sm={4}>
          <TextField
            name='familyName'
            label='Фамилия'
            value={dataProfile.familyName}
            onChange={updateProfileString('familyName')}
            disabled={disabled}
            errors={errorsProfile}
            // onBlur={onBlur && onBlur('familyName')}
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            name='firstName'
            label='Имя'
            value={dataProfile.firstName}
            onChange={updateProfileString('firstName')}
            disabled={disabled}
            errors={errorsProfile}
            // onBlur={onBlur && onBlur('firstName')}
          />
        </Grid>
        <Grid item sm={4}>
          <TextField
            name='secondName'
            label='Отчество'
            value={dataProfile.secondName}
            onChange={updateProfileString('secondName')}
            disabled={disabled}
            errors={errorsProfile}
            // onBlur={onBlur && onBlur('secondName')}
          />
        </Grid>
        <Grid item sm={4} md={2}>
          <DatePicker
            name='dateOfBirth'
            label={metaPassport.title('dateOfBirth')}
            value={data.dateOfBirth}
            onChange={updateDate('dateOfBirth')}
            shouldDisableDate={(day => dayjs().isBefore(day, 'day'))}
            disabled={disabled}
            errors={errors}
          />
        </Grid>
        <Grid item sm={4} md={2}>
          <TextField
            name='passportSeries'
            label={metaPassport.title('passportSeries')}
            value={data.passportSeries}
            onChange={updateString('passportSeries')}
            placeholder='11 22'
            maskParams={{
              mask: metaPassport.mask('passportSeries')
            }}
            disabled={disabled}
            errors={errors}
            onBlur={onBlur && onBlur('passportSeries')}
          />
        </Grid>
        <Grid item sm={4} md={2}>
          <TextField
            name='passportNum'
            label={metaPassport.title('passportNum')}
            value={data.passportNum}
            onChange={updateString('passportNum')}
            placeholder='123456'
            maskParams={{
              mask: metaPassport.mask('passportNum')
            }}
            disabled={disabled}
            errors={errors}
            onBlur={onBlur && onBlur('passportNum')}
          />
        </Grid>
        <Grid item sm={8} md={6}>
          <TextField
            name='issuedDepartment'
            label={metaPassport.title('issuedDepartment')}
            value={data.issuedDepartment}
            onChange={updateString('issuedDepartment')}
            disabled={disabled}
            errors={errors}
            onBlur={onBlur && onBlur('issuedDepartment')}
          />
        </Grid>
        <Grid item sm={4} md={3}>
          <DatePicker
            name='dateIssue'
            label={metaPassport.title('dateIssue')}
            value={data.dateIssue}
            onChange={updateDate('dateIssue')}
            shouldDisableDate={(day => dayjs().isBefore(day, 'day'))}
            disabled={disabled}
            errors={errors}
          />
        </Grid>
        <Grid item sm={4} md={3}>
          <TextField
            name='departmentCode'
            label={metaPassport.title('departmentCode')}
            value={data.departmentCode}
            onChange={updateString('departmentCode')}
            placeholder='123'
            maskParams={{
              mask: PASSPORT_DEPARTAMENT_CODE_MASK
            }}
            disabled={disabled}
            errors={errors}
            onBlur={onBlur && onBlur('departmentCode')}
          />
        </Grid>
        <Grid item sm={8} md={6}>
          { data.registrationAddress && <AddressSuggestionsInput
            name='registrationAddress'
            label={metaPassport.title('registrationAddress')}
            value={data.registrationAddress}
            addressData={registrationAddressData}
            onChange={(registrationAddress, addressData) => {
              setData((data) => ({ ...data, registrationAddress }))
              setRegistrationAddressData(addressData)
            }}
            disabled={disabled}
            errors={errors}
            onBlur={onBlur && onBlur('registrationAddress')}
          /> }
        </Grid>
      </Grid>
    </InfoCard>
  )
}
