import './styles.sass'
import { Box, Button, Stack } from '@mui/material'
import { Add, Archive, Edit, Visibility } from '@mui/icons-material'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import DataTable from 'ui/DataTable'
import ActionsMenu from 'ui/ActionsMenu'
import NavigatePanel from 'ui/NavigatePanel'
import { useMainRoutes } from 'routes'
import { Trailer, trailerList, trailerArchive, TrailerStatus, TrailerArchiveParams } from 'api/trailer'
import { useNavigate } from 'react-router-dom'
import { Id } from 'api/Id'
import Modal from 'ui/Modal'
import DatePicker from 'ui/DatePicker'
import TextField from 'ui/TextField'
import { VehicleAxles } from 'api/vehicle'
import { ChipTrailerStatus } from 'common/ChipTrailerStatus'
import trailerName from 'util/trailerName'
import CarrierProfileCompletenessAlert from 'ui/CarrierProfileCompletenessAlert'

export default function CarrierTrailers () {
  const { links, routesMap } = useMainRoutes()

  const [rows, setRows] = useState<Trailer[]>()
  const [searched, setSearched] = useState<(number|string)[]>()
  const [archiveData, setArchiveData] = useState<TrailerArchiveParams | null>()

  const navigate = useNavigate()

  const init = useCallback(() => { trailerList().then(r => setRows(r)) }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { init() }, [])

  const openArchiveModal = (id?: Id) => {
    if (id === undefined) {
      return
    }

    setArchiveData({ id, date: Math.floor(Date.now() / 1000) })
  }

  const updateArchiveString = (key: keyof Pick<TrailerArchiveParams, 'comment'>) => {
    return (e: ChangeEvent<HTMLElement & {value: string}>) => {
      if (!archiveData) {
        return
      }

      setArchiveData({ ...archiveData, [key]: e.target.value })
    }
  }

  const updateArchiveDate = (key: keyof TrailerArchiveParams) => {
    return (value: number) => {
      if (!archiveData) {
        return
      }

      setArchiveData({ ...archiveData, [key]: value })
    }
  }

  const archive = useCallback(async () => {
    if (!archiveData) {
      return
    }

    await trailerArchive(archiveData)
    setArchiveData(undefined)
    init()
  }, [archiveData, init])

  return (
    <>
      <div className='carrierTrailers'>
        <CarrierProfileCompletenessAlert />
        <NavigatePanel
          search={{
            rows: rows === undefined ? [] : rows,
            fields: [
              'brand',
              'model',
              'number',
              'numberRegion',
              'certificate',
              'certificateIndex',
              ['axles', 'name']
            ],
            onChange: setSearched
          }}
          title='Прицепы'
          breadcrumbs={{
            items: routesMap.getBreadcrumbs(links.CARRIER_TRAILERS_PAGE)
          }}
          actions={
            <Button variant="contained" size="small" endIcon={<Add />} onClick={() => navigate(`${links.CARRIER_TRAILERS_PAGE}/add`)} sx={{ textTransform: 'none' }}>
              Добавить прицеп
            </Button>
          }
        />
        <div className='carrierTrailers__content'>
          <div className='carrierTrailers__content_table'>
            <DataTable
              columns = { [
                {
                  id: ['number', 'numberRegion', 'numberType'],
                  label: 'Гос.номер и регион',
                  minWidth: 100,
                  format: (number, numberRegion, numberType) => <>{trailerName({ number, numberRegion, numberType } as Trailer)}</>
                },
                {
                  id: ['brand', 'model'],
                  label: 'Марка и модель',
                  minWidth: 100,
                  format: (brand, model) => <>{brand} {model}</>
                },
                {
                  id: ['certificate', 'certificateIndex'],
                  label: 'Серия и номер СРТС',
                  minWidth: 100,
                  format: (certificate, certificateIndex) => <>{certificateIndex} {certificate}</>
                },
                {
                  id: 'axles',
                  label: 'Количество осей',
                  minWidth: 100,
                  format: (value) => {
                    const axles = value as VehicleAxles
                    return <>{axles.name}</>
                  }
                },
                {
                  id: ['status', 'truckRuns'],
                  label: 'Статус',
                  minWidth: 100,
                  format: (status, truckRuns) => ChipTrailerStatus({ status: status as TrailerStatus, truckRuns: truckRuns as Id[] })
                },
                {
                  id: ['id', 'status', 'truckRuns'],
                  label: '',
                  align: 'center',
                  minWidth: 10,
                  format: (id, status, truckRuns) => <ActionsMenu key={`${id}`} actions = {
                    status !== TrailerStatus.active || (Array.isArray(truckRuns) && truckRuns.length > 0)
                      ? [
                          {
                            caption: 'Посмотреть',
                            onClick: () => navigate(`${links.CARRIER_TRAILERS_PAGE}/${id}`),
                            icon: <Visibility />
                          }
                        ]
                      : [
                          {
                            caption: 'Редактировать',
                            onClick: () => navigate(`${links.CARRIER_TRAILERS_PAGE}/${id}`),
                            icon: <Edit />
                          },
                          {
                            caption: 'Архивировать',
                            onClick: () => openArchiveModal(id as Id),
                            icon: <Archive />
                          }
                        ]
                  } />
                }
              ] }
              rows = { (start, end) => rows === undefined ? [] : rows.filter(row => searched === undefined ? true : searched.includes(row.id)).slice(start, end) }
              rowsCount = { rows === undefined ? 0 : (searched === undefined ? rows.length : searched.length) }
            />
            <Modal
              title='Архивировать прицеп'
              open={archiveData !== undefined}
              onClose={() => setArchiveData(undefined)}
              content={ archiveData
                ? <Stack sx={{ minWidth: '500px' }} >
                    <Box sx={{ mb: 2 }}>
                      <DatePicker
                        label='Дата архивации'
                        value={archiveData.date}
                        onChange={updateArchiveDate('date')}
                        disabled
                      />
                    </Box>
                    <Box>
                      <TextField
                        rows={6}
                        label="Комментарий"
                        width='100%'
                        value={archiveData.comment}
                        onChange={updateArchiveString('comment')}
                        placeholder="Добавьте комментарий, например причину вывода из эксплуатации"
                      />
                    </Box>
                  </Stack>
                : <></>
              }
              actions={ archiveData
                ? <>
                  <Button color="secondary" variant="outlined" onClick={() => setArchiveData(undefined)}>Отменить</Button>
                  <Button variant="contained" onClick={ () => { archive() } }>Архивировать</Button>
                </>
                : <></>
              }
            />
          </div>
        </div>
      </div>
    </>
  )
}
