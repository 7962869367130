import { Clear, Task } from '@mui/icons-material'
import { Button, Stack } from '@mui/material'
import { Shipping, ShippingTruckRun, ShippingTruckRunStatus, isArchiveStatus, shippingList } from 'api/shipping'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMainRoutes } from 'routes'
import NavigatePanel from 'ui/NavigatePanel'
import TripsCard from 'ui/TripsCard'
import './styles.sass'
import ActWizard from 'ui/ActWizard'
import rub from 'util/formatRub'
import InfoCard from 'ui/InfoCard'
import InfoCell from 'ui/InfoCell'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Pieces } from 'util/Pieces'
import ChipTruckRunStatus from 'common/ChipTruckRunStatusForBid'
import formatCargo from 'util/formatCargo'
import { toTon } from 'util/weight'
import { toKilometers } from 'util/distance'
import InfoCellValue from 'ui/InfoCell/InfoCellValue'
import statusToOrdering from 'common/TruckRuns/statusToOrdering'
import DatePicker, { RangeDate } from 'ui/DatePicker'
import SelectBillNumber from 'ui/SelectBillNumber'
import CarrierCommentModal from 'ui/CarrierCommentModal'
import { Id } from 'api/Id'
import { Bid, BidStatus, bidList } from 'api/bid'
import None from 'ui/None'
import CompleteModal, { Params as CompleteModalParams } from 'pages/CarrierTrip/CompleteModal'
import CarrierProfileCompletenessAlert from 'ui/CarrierProfileCompletenessAlert'

export enum Tabs {
  new = 'new',
  confirmed = 'confirmed',
  way = 'way',
  arrived = 'arrived',
  completed = 'completed',
  archive = 'archive',
  all = 'all'
}

const isTab = (v: unknown): v is Tabs => {
  return typeof v === 'string' && Object.values<string>(Tabs).includes(v)
}

export enum SEARCH_PARAMS {
  tab = 'tab'
}

const statusMap = new Map<Tabs | undefined, string>()
  .set(Tabs.new, 'Новые перевозки')
  .set(Tabs.all, 'Все рейсы')
  .set(Tabs.confirmed, 'Запланированные')
  .set(Tabs.way, 'В пути')
  .set(Tabs.arrived, 'Выгруженные')
  .set(Tabs.completed, 'Завершенные')
  .set(Tabs.archive, 'Архив')

function getNameByStatus (status: Tabs | undefined): string {
  return statusMap.get(status) ?? ''
}

const getCountTruckRunString = (count: number) => `${count} ${Pieces(count, 'рейс', 'рейса', 'рейсов')}`
const getStatInfo = (
  tabs: Tabs,
  data: { totalWeight: number, totalTruckRuns: number }) => {
  const { totalTruckRuns, totalWeight } = data
  const weight = toTon(totalWeight)
  const count = getCountTruckRunString(totalTruckRuns)

  switch (tabs) {
    case Tabs.confirmed:
      return <>
        <InfoCell label='Всего запланировано' value={count} />
        <InfoCell label='На вывоз' value={`${weight} тн`} />
      </>
    case Tabs.way:
      return <>
      <InfoCell label='Всего в пути' value={count} />
      <InfoCell label='Вывезено' value={`${weight} тн`} />
    </>
    case Tabs.arrived:
      return <>
      <InfoCell label='Всего выгружено' value={count} />
      <InfoCell label='Вывезено' value={`${weight} тн`} />
    </>
    case Tabs.completed:
      return <>
      <InfoCell label='Всего завершено' value={count} />
      <InfoCell label='Вывезено' value={`${weight} тн`} />
    </>
    case Tabs.archive:
      return <>
      <InfoCell label='Всего в архиве' value={count} />
      <InfoCell label='Вывезено' value={`${weight} тн`} />
    </>
    default:
      return <></>
  }
}

const getAllStatInfo = (
  status: ShippingTruckRunStatus,
  data: { totalWeight: number, totalTruckRuns: number }) => {
  const { totalTruckRuns, totalWeight } = data
  const weight = toTon(totalWeight)
  const count = getCountTruckRunString(totalTruckRuns)
  const key = `getAllStatInfo-${status}`

  switch (status) {
    case ShippingTruckRunStatus.confirmed:
      return <InfoCell label='Всего запланировано' value={<InfoCellValue value1={count} value2={`${weight} тн`}/>} key={key} />
    case ShippingTruckRunStatus.way:
      return <InfoCell label='Всего в пути' value={<InfoCellValue value1={count} value2={`${weight} тн`}/>} key={key} />
    case ShippingTruckRunStatus.arrived:
      return <InfoCell label='Всего выгружено' value={<InfoCellValue value1={count} value2={`${weight} тн`}/>} key={key} />
    case ShippingTruckRunStatus.completed:
      return <InfoCell label='Всего завершено' value={<InfoCellValue value1={count} value2={`${weight} тн`}/>} key={key} />
    default:
      return <></>
  }
}

interface AllStatInfoProp {
  resultsByStatus?: Shipping['resultsByStatus']
}
const AllStatInfo = ({ resultsByStatus }: AllStatInfoProp) => {
  const list = Object.entries(resultsByStatus ?? {}) as [ShippingTruckRunStatus, { totalWeight: number, count: number }][]
  const archive = list.filter(item => isArchiveStatus(item[0])).reduce((prev, [_, cur]) => {
    return {
      totalWeight: prev.totalWeight + cur.totalWeight,
      count: prev.count + cur.count
    }
  }, { totalWeight: 0, count: 0 })

  return <>
  {list
    .filter(item => !isArchiveStatus(item[0]))
    .sort((a, b) => statusToOrdering(a[0]) - statusToOrdering(b[0]))
    .map(([status, { totalWeight, count }]) => getAllStatInfo(status, { totalWeight, totalTruckRuns: count }))}
  <InfoCell label='Всего в архиве' value={<InfoCellValue value1={getCountTruckRunString(archive.count)} value2={`${toTon(archive.totalWeight)} тн`}/>} />
  </>
}

export default function CarrierTrips () {
  const [list, setList] = useState<(Shipping & { id: string })[]>([])
  const [newBids, setNewBids] = useState<Bid[]>([])
  const [currentTab, setCurrentTab] = useState<Tabs>()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [refresh, setRefresh] = useState(0)
  const { links, routesMap } = useMainRoutes()
  const [statistics, setStatistics] = useState({ totalWeight: 0, totalTruckRuns: 0 })
  const [resultsByStatus, setResultsByStatus] = useState<Shipping['resultsByStatus']>()
  const [filterBillTs, setFilterBillTs] = useState<RangeDate>()
  const [filterBillNumber, setFilterBillNumber] = useState<string>()
  const [openCommentModal, setOpenCommentModal] = useState<ShippingTruckRun>()
  const [selectedTruckRuns, setSelectedTruckRuns] = useState<Id[]>([])
  const [completeModal, setCompleteModal] = useState<CompleteModalParams['data']>()

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const breadcrumbsItems = useMemo(
    () => routesMap.getBreadcrumbs(links.CARRIER_TRIPS_PAGE),
    [links, routesMap]
  )

  const loadList = useCallback((currentTab: Tabs) => {
    if (currentTab === Tabs.new) {
      bidList(BidStatus.new).then(setNewBids)
      return
    }

    const truckRunStatus = currentTab === Tabs.all
      ? [
          ShippingTruckRunStatus.archiveAct, ShippingTruckRunStatus.archiveProblem,
          ShippingTruckRunStatus.confirmed, ShippingTruckRunStatus.archiveOutdated,
          ShippingTruckRunStatus.arrived, ShippingTruckRunStatus.completed,
          ShippingTruckRunStatus.way
        ]
      : currentTab === Tabs.archive
        ? [ShippingTruckRunStatus.archiveAct, ShippingTruckRunStatus.archiveProblem]
        : currentTab === Tabs.confirmed
          ? [ShippingTruckRunStatus.confirmed, ShippingTruckRunStatus.archiveOutdated]
          : currentTab === Tabs.arrived
            ? [ShippingTruckRunStatus.arrived]
            : currentTab === Tabs.completed
              ? [ShippingTruckRunStatus.completed]
              : [ShippingTruckRunStatus.way]

    shippingList({
      filters: {
        truckRunStatus,
        billNumber: filterBillNumber,
        billTsFrom: filterBillTs?.startTs,
        billTsTo: filterBillTs?.endTs
      }
    })
      .then(result => {
        const { count, totalWeight, resultsByStatus } = result
        setList(result.list.map(item => ({
          ...item,
          id: `${item.bid.id}`
        })))
        setStatistics({ totalWeight, totalTruckRuns: count })
        setResultsByStatus(resultsByStatus)
      })
  }, [filterBillTs, filterBillNumber])

  useEffect(() => setRefresh((prev) => prev + 1), [filterBillTs, filterBillNumber])

  useEffect(() => {
    const searchTab = searchParams.get(SEARCH_PARAMS.tab)
    const tab = isTab(searchTab) ? searchTab : Tabs.new
    setCurrentTab(tab)
    loadList(tab)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  const changeTab = (value: string) => {
    const tab = value as Tabs
    setCurrentTab(tab)
    loadList(tab)
    navigate(`?tab=${tab}`)
  }

  const onSelect = ({ id }: ShippingTruckRun, checked: boolean) => {
    setSelectedTruckRuns(prev => {
      if (checked) {
        return [...prev, id]
      }

      return prev.filter(item => item !== id)
    })
  }

  const handleOpenCompleteModal = ({ id, vehicle }: ShippingTruckRun, { autoFill: bidAutoFill }: Shipping['bid']) => {
    setCompleteModal({ id, bidAutoFill, vehicle })
  }

  if (currentTab === undefined) {
    return <></>
  }

  return (
    <>
      <div className='trips'>
        <CarrierProfileCompletenessAlert />
        <NavigatePanel
          title='Перевозки'
          breadcrumbs={{
            items: breadcrumbsItems
          }}
          tabs={{
            items: [...statusMap.keys()].map(item => ({ label: getNameByStatus(item), value: `${item}` })),
            value: currentTab,
            onChange: changeTab,
            scrollable: true
          }}
          actions={
            <Stack direction='row' justifyContent="flex-end" gap={1}>
              <Button variant='contained' size='small' endIcon={<Task />} sx={{ textTransform: 'none' }} onClick={() => setOpenModal(true)}>
              { selectedTruckRuns.length === 0 ? 'Добавить завершенные рейсы в УПД' : 'Добавить выбранные рейсы в УПД' }
              </Button>
              { selectedTruckRuns.length > 0 && <Button
                  variant='outlined'
                  color='secondary'
                  onClick={() => { setSelectedTruckRuns([]) }}
                  endIcon={<Clear />}
                >Сбросить</Button> }
            </Stack>}
        />
        <div className='trips__content'>
          <div className='trips__content__items'>
            { currentTab === Tabs.new && newBids.length === 0 && <None desc='Нет назначенных перевозок.' sx={{ height: '20em' }}/> }
            { currentTab === Tabs.new && newBids.length > 0 && <>
              { newBids.map((bid) =>
                <TripsCard
                  data={{ bid, truckRuns: [] }}
                  descriptions={[
                    { label: 'Груз', value: formatCargo(bid.cargo) },
                    { label: 'Стоимость тн.', value: rub(bid.costTon) },
                    { label: 'Расстояние', value: `${toKilometers(bid.distance)} км` }
                  ]}
                  key={bid.id}
                  statusView={ChipTruckRunStatus}
                  onOpenBid={() => navigate(`${links.CARRIER_TRIPS_BID_PAGE}/${bid.id}`)}
                />
              )}
            </>}
            { currentTab !== Tabs.new && <>
            <InfoCard title="Фильтр по рейсам" style={{ marginBottom: '1em' }}>
              <Stack direction="row" alignItems="center" width="100%" gap={2}>
                <Stack direction="row" alignItems="center">
                  <DatePicker
                    label='Укажите диапазон для даты ТТН'
                    placeholder='Укажите диапазон для даты ТТН'
                    range={filterBillTs}
                    onChange={(startTs, endTs) => {
                      if (startTs !== undefined && endTs !== undefined) {
                        setFilterBillTs(startTs < endTs ? { startTs, endTs } : { startTs: endTs, endTs: startTs })
                      }
                    }}
                    rangeDate
                    width='22em'
                  />
                  <Button
                    variant='outlined'
                    color='secondary'
                    onClick={() => setFilterBillTs(undefined)}
                    sx={{ minWidth: '40px', minHeight: '40px', maxHeight: '40px', marginTop: '8px' }}
                  ><Clear /></Button>
                </Stack>
                <SelectBillNumber
                  label='Укажите номер ТТН'
                  placeholder='Укажите номер ТТН'
                  value={filterBillNumber}
                  onChange={setFilterBillNumber}
                  width="100%"
                />
                <Button
                  variant='outlined'
                  color='secondary'
                  onClick={() => {
                    setFilterBillTs(undefined)
                    setFilterBillNumber(undefined)
                  }}
                  sx={{ minWidth: '110px', minHeight: '40px', maxHeight: '40px', marginTop: '8px' }}
                  endIcon={<Clear />}
                >Сбросить</Button>
              </Stack>
            </InfoCard>
            <InfoCard style={{ marginBottom: '1em' }} >{
              currentTab !== Tabs.all
                ? <Stack direction='row' spacing={2}>{getStatInfo(currentTab, statistics)}</Stack>
                : <Stack direction='row' gap={1}><AllStatInfo resultsByStatus={resultsByStatus} /></Stack>
            }
            </InfoCard>
            {/* list.some(({ truckRuns }) => truckRuns.some(({ unloadingAddress }) => unloadingAddress)) && <Alert color='#ffa800' sx={{ margin: '1em 1.5em' }} >
              <>
                <Typography fontWeight={600} fontSize='18px' color='#ffffff'>Есть переадресованные рейсы</Typography>
              </>
            </Alert> */}
            { list.map((item) => <TripsCard
              data={item}
              descriptions={[
                { label: 'Груз', value: formatCargo(item.bid.cargo) },
                { label: 'Стоимость тн.', value: rub(item.bid.costTon) },
                { label: 'Расстояние', value: `${toKilometers(item.bid.distance)} км` }
              ]}
              key={item.id}
              selectedIds={selectedTruckRuns}
              statusView={ChipTruckRunStatus}
              highlight={{ billNumber: filterBillNumber, billTs: filterBillTs }}
              onAddComment={setOpenCommentModal}
              onSelect={currentTab === Tabs.completed ? onSelect : undefined}
              onOpenBid={() => navigate(`${links.CARRIER_TRIPS_BID_PAGE}/${item.bid.id}`)}
              onOpenComplete={truckRun => handleOpenCompleteModal(truckRun, item.bid)}
            />) }
          </>}
          </div>
        </div>
      </div>

      <ActWizard
        open={openModal}
        truckRunIds={selectedTruckRuns}
        onClose={() => {
          setOpenModal(false)
          setRefresh((prev) => prev + 1)
          setSelectedTruckRuns([])
        }}
      />

      <CarrierCommentModal
        data={openCommentModal}
        onClose={() => { setOpenCommentModal(undefined) }}
      />

      <CompleteModal
        data={completeModal}
        onClose={(done) => {
          setCompleteModal(undefined)
          if (done) {
            loadList(currentTab)
          }
        }}
        onDone={() => {
          setCompleteModal(undefined)
          loadList(currentTab)
        }}
      />
    </>
  )
}
