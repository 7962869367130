import './styles.sass'
import { useState } from 'react'
import { Certificate } from 'crypto-pro'
import ModalSigning from 'ui/ModalSigning'
import GetOrganizationList from '../ActWizard/GetOrganizationList'
import AuthCertificateSelect from '../ActWizard/AuthCertificateSelect'
import AuthProcess from '../ActWizard/AuthProcess'
import OrganizationSelect from '../ActWizard/OrganizationSelect'
import { Organization } from 'api/diadoc'
import { RequireOnly } from 'util/type/RequireOnly'
import { Customer as CustomerOrigin } from 'api/customer'
import VerifyContracts from './VerifyContracts'
import SignManually from 'ui/CarrierContractWizard/SignManually'
import Signing from 'ui/CarrierContractWizard/Signing'
import Exit from 'ui/CarrierContractWizard/Exit'
import CreateContract from 'ui/CarrierContractWizard/CreateContract'
import { CarrierContractWizardType } from 'ui/CarrierContractWizard'
import RevocationXml from './RevocationXml'
import RevocationRequest from './RevocationRequest'
import { Id } from 'api/Id'
import SigningRevocation from './Signing'

export type Customer = RequireOnly<CustomerOrigin, 'id' | 'fullName'>

export interface Props {
  open: boolean
  onClose: (success?: boolean) => void
  customer?: Customer
}

export interface CarrierRecreateContractWizardType extends CarrierContractWizardType {
  inDiadoc: boolean
  setInDiadoc: (i?: boolean) => void
  contractId: Id
  setContractId: (c: Id) => void
  xmlBase64: string,
  signature: string,
  onXmlLoaded: (c: string) => void,
  onSignatureLoaded: (c: string) => void
}

export default function CarrierRecreateContractWizard ({ open, onClose, customer }: Props) {
  const [authorized, setAuthorized] = useState(false) // has a Diadoc token
  const [organizationList, setOrganizationList] = useState<Organization[]>()
  const [certificate, setCertificate] = useState<Certificate>()
  const [boxId, setBoxId] = useState('')
  const [contract, setContract] = useState<string>()
  const [success, setSuccess] = useState<boolean>(false)
  const [signManually, setSignManually] = useState<boolean>(false)
  const [scanSignedСontract, setScanSignedСontract] = useState<string>()
  const [inDiadoc, setInDiadoc] = useState<boolean>()
  const [contractId, setContractId] = useState<Id>()
  const [xmlBase64, setXmlBase64] = useState('')
  const [signature, setSignature] = useState('')
  const [dateTs, setDateTs] = useState<number>()

  return (
    <>
      { open && <ModalSigning
        open={open}
        onClose={() => onClose(success)}
        data={{
          authFirstStep: 4,
          diadocSignStap: 12,
          authorized,
          organizationList,
          certificate,
          customer,
          boxId,
          isManually: signManually,
          onAuthorized: (b: boolean) => {
            setAuthorized(b)
            if (!b) {
              setOrganizationList(undefined)
              setCertificate(undefined)
              setBoxId('')
            }
          },
          onOrganizationListLoaded: setOrganizationList,
          onCertificateSelected: setCertificate,
          onBoxIdSelected: setBoxId,
          contract,
          onContract: setContract,
          onSuccess: setSuccess,
          onManually: setSignManually,
          scanSignedСontract,
          onScanSignedСontract: setScanSignedСontract,
          inDiadoc,
          setInDiadoc,
          contractId,
          setContractId,
          xmlBase64,
          signature,
          onXmlLoaded: setXmlBase64,
          onSignatureLoaded: setSignature,
          dateTs,
          onDateTs: setDateTs
        }}
        steps={[
          {
            title: 'Проверка договора',
            fn: VerifyContracts,
            cancel: 'Отменить'
          },
          {
            title: 'Загружаем список организаций',
            fn: GetOrganizationList,
            cancel: 'Отменить',
            next: 'Далее'
          },
          {
            title: 'Выбор сертификата',
            fn: AuthCertificateSelect,
            cancel: 'Отменить',
            next: certificate ? 'Далее' : null
          },
          {
            title: 'Авторизация в Diadoc',
            fn: AuthProcess
          },
          {
            title: 'Загружаем список организаций',
            fn: GetOrganizationList
          },
          {
            title: 'Выбор организации',
            fn: OrganizationSelect,
            cancel: 'Отменить',
            next: boxId ? 'Далее' : null
          },
          {
            title: 'Формируем запрос',
            fn: RevocationXml
          },
          {
            title: 'Подписываем запрос',
            fn: SigningRevocation
          },
          {
            title: 'Отправляем запрос',
            fn: RevocationRequest
          },
          {
            title: 'Создание заявления',
            fn: CreateContract
          },
          {
            title: 'Подписание и отправка',
            fn: SignManually
          },
          {
            title: 'Загружаем список организаций',
            fn: GetOrganizationList,
            cancel: 'Отменить',
            next: 'Далее'
          },
          {
            title: 'Подписание и отправка',
            fn: Signing
          },
          {
            title: 'Договор подписан',
            fn: Exit
          }
        ]}
      /> }
    </>
  )
}
