import './styles.sass'
import { DatePicker as MUIDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ruLocale from 'date-fns/locale/ru'
import TsToDate from 'common/TsToDate'
import { TextField, TextFieldProps, Box, Stack } from '@mui/material'
import { TS_IN_SECONDS } from 'constants/Time'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { forwardRef, ReactNode, useMemo } from 'react'
import { ValidateError } from 'validation/validate'
import { dayStart, getOffsetTZ } from 'util/date'

const clean = (ts: number) => dayStart((ts / TS_IN_SECONDS) - getOffsetTZ())

export interface RangeDate { startTs: number, endTs: number }
export interface Params {
  name?: string
  label?: string
  value?: number,
  range?: RangeDate,
  onChange: (startTs: number, endTs?: number) => void
  shouldDisableDate?: (day: Date) => boolean
  rangeDate?: boolean
  disabled?: boolean
  width?: string
  error?: boolean
  errors?: ValidateError
  errorMessage?: string
  placeholder?: string
  style?: React.CSSProperties
  hint?: ReactNode
}

const MainField = forwardRef<HTMLDivElement, TextFieldProps>(({ placeholder, ...props }, ref) => {
  return (<TextField
    fullWidth
    size='small'
    {...props}
    InputProps={{ ...props.InputProps, readOnly: true }}
    inputProps={{ ...props.inputProps, readOnly: true, placeholder }}
    ref={ref}
    sx={{
      height: '40px',
      background: '#F7F7F7',
      '& fieldset': {
        border: props.error ? '1px solid red !important' : '1px solid #EBEBEB !important',
        borderRadius: '4px'
      },
      ...props.sx
    }}
  />)
})

export default function DatePicker ({ name, label, hint, value, onChange, shouldDisableDate, rangeDate = false, range, disabled, width, errors, errorMessage, placeholder, style, ...props }: Params) {
  const eMessage = useMemo(() => {
    if (props.error === false) {
      return
    }

    if (errorMessage !== undefined) {
      return errorMessage
    }

    const required = errors?.find(({ keyword, params }) => keyword === 'required' && params.missingProperty === name)

    if (required) {
      return 'Поле обязательно к заполнению'
    }

    const error = errors?.find(item => name && item.instancePath.includes(name))

    return error?.message
  }, [errors, name, errorMessage, props.error])

  const handleChange = (value: Date | null) => {
    if (value !== null) {
      onChange(clean(value.getTime()))
    }
  }

  return (<div className='datePicker' style={{ width, ...style }}>
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
      <Box>
      { (label || hint) && <Stack direction='row' justifyContent='space-between' sx={{
        fontWeight: '500',
        fontSize: '13px',
        lineHeight: '16px',
        letterSpacing: '-0.02em',
        whiteSpace: 'nowrap',
        marginBottom: '8px'
      }}>
          <Box sx={{ color: '#111111' }}>{label}</Box>
          <Box sx={{ color: '#B2B2B2' }}>{hint}</Box>
        </Stack>}
      </Box>
      { !rangeDate
        ? <MUIDatePicker
          inputFormat='dd.MM.yyyy'
          disabled={disabled}
          value={value !== undefined ? TsToDate(value) : null}
          onChange={handleChange}
          renderInput={(params) => <MainField {...params} placeholder='дд.мм.гггг' error={eMessage !== undefined || props.error === true} />}
          shouldDisableDate={shouldDisableDate}
          PopperProps={{
            style: {
              border: '1px solid #EBEBEB',
              boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.1)',
              borderRadius: '4px'
            }
          }}
        />
        : <Box>
          <DateRangePicker
            disabled={disabled}
            value={range !== undefined ? [TsToDate(range.startTs), TsToDate(range.endTs)] : [null, null]}
            shouldDisableDate={shouldDisableDate}
            onChange={(newValue) => {
              if (newValue[0] !== null && newValue[1] !== null) {
                onChange(
                  (clean(newValue[0].getTime())),
                  (clean(newValue[1].getTime()))
                )
              }
            }}
            renderInput={(startProps, endProps) => {
              const start = startProps?.inputProps?.value as string
              const end = endProps?.inputProps?.value as string
              const value = start !== '' && end !== '' ? `${start} - ${end}` : ''

              return (
                <MainField
                  fullWidth
                  size='small'
                  placeholder={placeholder}
                  inputProps={{
                    ...startProps.inputProps,
                    value,
                    placeholder
                  }}
                  InputProps={{
                    endAdornment: <CalendarMonthIcon sx={{ color: '#94A3B8' }}/>
                  }}
                  sx={{
                    border: eMessage ? '1px solid red' : undefined,
                    borderRadius: '4px'
                  }}
                  error={eMessage !== undefined}
                />
              )
            }}

            PopperProps={{
              style: {
                border: '1px solid #EBEBEB',
                boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.1)',
                borderRadius: '4px'
              }
            }}
        />
      </Box>
    }
    </LocalizationProvider>
    <div style={{ color: 'red', fontSize: '10px', marginTop: '0.5em', height: '10px' }}>{eMessage}</div>
    </div>)
}
