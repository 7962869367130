import { Alert, Box, Button, Stack, Typography } from '@mui/material'
import { Id, isId } from 'api/Id'
import Modal from 'ui/Modal'
import { bidAllDelete } from 'api/bid'
import { useState } from 'react'
import { useAuthContext } from 'AuthContext'
import { Pieces } from 'util/Pieces'

export default function DeleteModal ({ id, onClose, onDelete }: { id?: Id, onClose: () => void, onDelete: () => void }) {
  const [conflicts, setConflicts] = useState<Awaited<ReturnType<typeof bidAllDelete>>['conflicts']>()
  const { handleResponseSuccess } = useAuthContext()

  const handleDelete = async () => {
    if (!isId(id)) {
      return
    }

    const { success, conflicts } = await bidAllDelete(id)

    if (success) {
      handleResponseSuccess('Заявка удалена')
      onDelete()
      return
    }

    setConflicts(conflicts)
  }

  return (<Modal
    maxWidth='lg'
    open={isId(id)}
    onClose={onClose}
    title='Удалить заявку'
    content={<Box minWidth='500px'>{conflicts === undefined
      ? <Typography fontWeight={600} fontSize='16px'>Вы уверенны, что хотите удалить заявку?</Typography>
      : <Alert severity='error'>
        <Typography fontWeight={600} fontSize='16px'>Невозможно удалить заявку!</Typography>
        <Typography fontWeight={300} fontSize='14px'>Так как на данную заявку есть {conflicts.length} {Pieces(conflicts.length, 'отклик', 'отклика', 'откликов')}</Typography>
      </Alert>
      }</Box>}
    actions={<>
      { conflicts === undefined && <Stack spacing={2} justifyContent='end' direction='row'>
        <Button variant='outlined' size='small' onClick={onClose}>
          Отменить
        </Button>
        <Button variant='contained' size='small' color='error' onClick={handleDelete} >
          Удалить
        </Button>
      </Stack> }
    </>}
/>)
}
