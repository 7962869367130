import { Box, Divider, Stack, Typography } from '@mui/material'
import { CreateProfileRequestAddDrivers, CreateProfileRequestUpdateData, ProfileRequest, ProfileRequestSlugs } from 'api/profile'
import Modal from 'ui/Modal'
import { getStatus, PROFILE_REQUESTS_MAP } from '.'
import TsToFormatDate from 'util/TsToFormatDate'
import Upload from 'ui/Upload'

type AttachedData = Partial<CreateProfileRequestUpdateData & CreateProfileRequestAddDrivers>

interface Params {
  open?: ProfileRequest
  onClose: () => void
}

export default function DetailsModal ({ open, onClose }: Params) {
  const data: AttachedData | undefined = open?.data

  if (!open) {
    return (<></>)
  }

  return (<>
    <Modal
      maxWidth='lg'
      title={PROFILE_REQUESTS_MAP.get(open.slug) ?? 'Запрос'}
      onClose={onClose}
      open={!!open}
      content={
        <Box minWidth='500px'>
          <Stack direction='column' gap={1}>
            <Typography><b>Отправлен: </b>{TsToFormatDate(open.createTs, 'dd.MM.yyyy HH:mm')}</Typography>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Typography fontWeight={600}>Статус:</Typography>
              {getStatus(open)}
            </Stack>
            { open.cancelComment && <Typography><b>Комментарий диспетчера: </b>{open.cancelComment}</Typography> }
            { !!data && <>
              <Divider />
              <Typography fontWeight={600} align='center'>Данные запроса</Typography>
              { open.slug === ProfileRequestSlugs.updateData && <>
                { data.comment && <Typography><b>Сообщение: </b>{data.comment}</Typography> }
                {data.scanStatement && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                  <Typography width='67%' >Скан заявления на изменение данных</Typography>
                  <Upload
                    name="scanStatement"
                    value={data.scanStatement}
                    disabled={true}
                    sx={{ width: '33%' }}
                  />
                </Stack>}
              </> }
              { open.slug === ProfileRequestSlugs.addDrivers && <>
                {data.scanInsuredPersons && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                  <Typography width='67%' >Сведения о застрахованных лицах Формы КНД 1151162 «Персонифицированные сведения о физических лицах», утвержденный Приказом ФНС России от 29.09.2022 N ЕД-7-11/878@ (в части сведений о водителях, направляемых для оказания услуги по Договору, с закрытыми на копии/скан-копии данными СНИЛС, ИНН и Сведениями о сумме выплат и иных вознаграждений, начисленных в пользу физического лица его Работодателем)</Typography>
                  <Upload
                    name="scanInsuredPersons"
                    value={data.scanInsuredPersons}
                    disabled={true}
                    sx={{ width: '33%' }}
                  />
                </Stack>}
                { data.scanReportEFS && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} mt={2} >
                  <Typography width='67%'>Удостоверенная уполномоченным лицом копия отчета ЕФС-1, подраздел 1.1 с титульным листом утвержденная Постановлением Правления ПФ РФ от 31.10.2022 N 245п (в части соответствующего физического лица) с отметкой о его принятии Фондом пенсионного и социального страхования РФ.)</Typography>
                  <Upload
                    name="scanReportEFS"
                    value={data.scanReportEFS}
                    disabled={true}
                    sx={{ width: '33%' }}
                  />
                </Stack> }
                { data.manningTable && <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={2} sx={{ mb: 2 }}>
                  <Typography width='67%' >Выписка из штатной расстановки</Typography>
                  <Upload
                    name='manningTable'
                    value={data.manningTable}
                    disabled={true}
                    sx={{ width: '33%' }}
                  />
                </Stack>}
              </> }
            </> }
          </Stack>
        </Box>
      }
      actions={<></>}
    />
  </>)
}
