import { Close } from '@mui/icons-material'
import { IconButton, Stack } from '@mui/material'
import { ReactNode, useState } from 'react'

export default function ClosingAlert ({ children }: { children?: ReactNode }) {
  const [close, setClose] = useState(false)

  if (close) {
    return (<></>)
  }

  return (<Stack
    bgcolor='#ee6a5f'
    p={1}
  >
    <Stack direction='row' justifyContent='space-between' alignItems='center'>
      <Stack flexGrow={3} >{children}</Stack>
      <Stack flexDirection='row' justifyContent='right'>
        <IconButton
          onClick={() => setClose(true)}
          sx={{
            width: '10px',
            height: '10px'
          }}
        >
          <Close />
        </IconButton>
      </Stack>
    </Stack>
  </Stack>)
}
