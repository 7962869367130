import { Typography } from '@mui/material'
import Tooltip from 'ui/Tooltip'
import formatWeight from 'util/formatWeight'

const OverWeight = ({ owerWeight }: {owerWeight?: number}) => {
  if (!owerWeight) {
    return (<></>)
  }

  return (<Tooltip
    title={<Typography fontSize='14px' fontWeight={600} >Вывезено больше, чем запланировано</Typography>}
    arrow
    placement='right'
    view
  >
    <Typography
      component='div'
      fontSize='13px'
      lineHeight='17px'
      minHeight='fit-content'
      color='#FFA800'
      fontWeight={600}
      letterSpacing='0.02em'
    >
      +{formatWeight(owerWeight)}
    </Typography>
  </Tooltip>)
}

export default OverWeight
