import './styles.sass'
import NavigatePanel from 'ui/NavigatePanel'
import { Box, Typography } from '@mui/material'
import ChipStatus from 'ui/ChipStatus'
import { useMainRoutes } from 'routes'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import DataTable, { Row } from 'ui/DataTable'
import { ClearSharp, MoreHoriz, ReportProblem } from '@mui/icons-material'
import { Act, ActStatus, actList as apiActList } from 'api/act'
import TsToFormatDate from 'util/TsToFormatDate'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { Id } from 'api/Id'
import ActionsMenu, { Action } from 'ui/ActionsMenu'
import ActRevocationWizard from 'ui/ActRevocationWizard'
import addressName from 'util/addressName'
import { isEnum } from 'util/isEnum'
import { CellDocs, CellStatus, CellSum, cellDocs, cellStatus, cellSum } from 'ui/ActRowComponents'
import Tooltip from 'ui/Tooltip'
import SquareButton from 'ui/SquareButton'
import metaAct from 'entity/Act'
import DownloadZipButton, { slug } from 'ui/DownloadZipButton'
import { actStatusTab } from 'common/actStatusTab'
import CarrierProfileCompletenessAlert from 'ui/CarrierProfileCompletenessAlert'

const statusMap = new Map<ActStatus | undefined, string>()
  .set(ActStatus.waitingSigning, 'Ожидают подписания')
  .set(ActStatus.signedByCustomer, 'Подписаны заказчиком')
  .set(ActStatus.refused, 'Отказано в подписании')
  .set(ActStatus.canceled, 'Аннулированы')
  .set(ActStatus.problem, 'Требуют внимания')

function getNameByActStatus (role: ActStatus | undefined): string {
  return statusMap.get(role) ?? ''
}

export const getChipsByStatus = (status: ActStatus) => {
  switch (status) {
    case ActStatus.dispatcherChecked:
    case ActStatus.waitingSigning:
      return <ChipStatus label='Ожидает подписания' color='yellow' />
    case ActStatus.signedByCustomer:
      return <ChipStatus label='Подписан' color='green' />
    case ActStatus.refused:
      return <ChipStatus label='Отказано в подписании' color='red' />
    case ActStatus.canceled:
      return <ChipStatus label='Аннулирован' color='red' />
    case ActStatus.changedInDiadoc:
      return <ChipStatus label='Изменен через Диадок' color='red' />
    case ActStatus.problem:
      return <ChipStatus label='Отклонен диспетчером' color='red' />
    default:
      return <></>
  }
}

interface MainMenuParams {
  id: Id
  filename: string
  mergedFilename?: string
  registryFilename?: string
  shortageFilename?: string
  agreementFilename?: string
  invoiceFilename?: string
  problemComment?: string
  status: ActStatus
  num?: number
  date?: string
  onCancel: (id: Id) => void
}

const MainMenu = memo(({
  id,
  status,
  onCancel, problemComment,
  filename,
  registryFilename,
  shortageFilename,
  invoiceFilename,
  agreementFilename,
  num,
  date
}: MainMenuParams) => {
  let actions: Action[] = []
  const { links } = useMainRoutes()
  const navigate = useNavigate()
  const pack: { filename: string, toName: string }[] = useMemo(() => {
    const files: Record<string, string | undefined> = { filename, registryFilename, shortageFilename, agreementFilename, invoiceFilename }
    return Object.entries(files)
      .map(([key, filename]) => ({
        filename: filename || '',
        toName: filename ? metaAct.title(key as keyof Act) : ''
      }))
      .filter(item => item.filename !== '')
  }, [filename, registryFilename, shortageFilename, agreementFilename, invoiceFilename])
  switch (status) {
    case ActStatus.waitingSigning:
      actions = [
        ...actions,
        {
          caption: 'Аннулировать',
          onClick: () => onCancel(id),
          icon: <ClearSharp />
        }
      ]
      break
  }
  const convertedDate = useMemo(() => {
    return date?.split(' ')[0].replaceAll('.', '_')
  }, [date])
  return <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
    <Box sx={{ width: '17px', height: '17px', display: 'block' }}>
      { problemComment && <Tooltip title={<Typography fontSize='14px' fontWeight={600} >{problemComment}</Typography>} arrow placement='right' view>
        <ReportProblem sx={{ color: '#EE6A5F', fontSize: '17px' }}/>
      </Tooltip> }
    </Box>
    { actions.length > 0 && <ActionsMenu key={`${id}`} actions = {actions} /> }
    { status === ActStatus.signedByCustomer && <DownloadZipButton iconOnly={true} pack={pack} archiveName={slug(`Пакет документов к УПД ${num} от ${convertedDate}`)}/> }
    <SquareButton variant='contained' onClick={() => navigate(`${links.CARRIER_DOCUMENTS_ACT_PAGE}/${id}`)}><MoreHoriz /></SquareButton>
  </Box>
})

const LIMIT = 20

const DEFAULT_STATUS = ActStatus.waitingSigning

export default function CarrierActs () {
  const { links, routesMap } = useMainRoutes()
  const [actList, setActList] = useState<Act[]>([])
  const [searched, setSearched] = useState<(number|string)[]>()
  const [revocationActId, setRevocationActId] = useState<Id>()
  const [openRevocationModal, setOpenRevocationModal] = useState(false)
  const [listCount, setListCount] = useState<number>(0)
  const [currentTablePage, setCurrentTablePage] = useState<number>(0)

  const { status } = useParams()
  const filterStatus = isEnum(ActStatus)(status) ? status : DEFAULT_STATUS

  const navigate = useNavigate()

  const loadActList = useCallback((page: number = 0) => {
    apiActList({ filters: { status: filterStatus === DEFAULT_STATUS ? [DEFAULT_STATUS, ActStatus.dispatcherChecked] : [filterStatus] }, limit: LIMIT, offset: page * LIMIT })
      .then(result => {
        setActList(result.list)
        setListCount(result.count)
        setCurrentTablePage(page)
      })
  }, [filterStatus])

  useEffect(() => {
    loadActList()
  }, [filterStatus, loadActList])

  const rows: Row[] = useMemo(() => {
    return actList.map(item => {
      const trips: { id: Id, name: string }[] = item.truckRuns
        .map(trucRun => ({
          id: trucRun.id,
          name: `${trucRun.num} ${TsToFormatDate(trucRun.loadingTs, 'dd.MM.yyyy')}. ${addressName(item.bid.loadingAddress)} - ${addressName(item.unloadingAddress ?? item.bid.unloadingAddress)}`
        })
        )
      const customer = `${item.bid.customer.fullName}`
      const shortageSum = item.truckRuns.reduce((prev, cur) => cur.shortage.cost + prev, 0)

      return {
        id: item.id,
        num: item.num,
        customer,
        trips,
        date: TsToFormatDate(item.sendTs, 'dd.MM.yyyy HH:mm'),
        status: item.status,
        sum: item.totalSum,
        filename: item.filename,
        registryFilename: item.registryFilename,
        shortageFilename: item.shortageFilename,
        agreementFilename: item.agreementFilename,
        mergedFilename: item.mergedFilename,
        invoiceFilename: item.invoiceFilename,
        problemComment: item.problemComment,
        statuses: {
          status: item.status,
          registryStatus: item.registryStatus,
          shortageStatus: item.shortageStatus,
          agreementStatus: item.agreementStatus,
          invoiceStatus: item.invoiceStatus,
          finalBidStatus: item.finalBidStatus
        },
        docs: {
          filename: item.filename,
          registryFilename: item.registryFilename,
          shortageFilename: item.shortageFilename,
          agreementFilename: item.agreementFilename,
          invoiceFilename: item.invoiceFilename,
          finalBidFilename: item.finalBidFilename
        },
        amounts: {
          actSum: item.totalSum,
          shortageSum: item.shortageFilename !== undefined ? shortageSum : undefined,
          finalSum: item.invoiceFilename !== undefined ? item.totalSum - shortageSum : undefined,
          hasFinalBid: item.finalBidFilename !== undefined
        }
      }
    })
  }, [actList])

  const breadcrumbsItems = useMemo(
    () => routesMap.getBreadcrumbs(links.CARRIER_DOCUMENTS_ACTS_PAGE),
    [links, routesMap]
  )

  const handleCancel = (id: Id) => {
    setRevocationActId(id)
    setOpenRevocationModal(true)
  }

  return (
    <>
      <div className='carrierActs'>
        <CarrierProfileCompletenessAlert />
        <NavigatePanel
          search={{
            rows: rows === undefined ? [] : rows,
            fields: [
              'num',
              'customer',
              'trips',
              'sum',
              'date'
            ],
            onChange: setSearched
          }}
          title='УПД'
          breadcrumbs={{
            items: breadcrumbsItems
          }}
          tabs={{
            items: [...statusMap.keys()].map(item => actStatusTab({ label: getNameByActStatus(item), value: `${item}` })),
            value: filterStatus,
            onChange: value => navigate(links.CARRIER_DOCUMENTS_ACTS_PAGE + (value === '' ? '' : `/${value}`)),
            scrollable: true
          }}
        />
        <div className='carrierActs__content'>
          <div className='carrierActs__content_table'>
            <DataTable
              currentPage={currentTablePage}
              changePage={loadActList}
              verticalAlign='top'
              columns = { [
                {
                  id: 'num',
                  label: 'Номер',
                  format: value => <Typography fontWeight={700} fontSize='14px'>{`${value}`}</Typography>
                },
                {
                  id: 'trips',
                  label: 'Включенные рейсы',
                  minWidth: 280,
                  format: (value) => <>{(value as { id: Id, name: string }[]).map((item, idx) =>
                    <Typography fontSize='14px' key={idx} >
                      <NavLink style={{ color: '#111111' }} to={`${links.CARRIER_TRIPS_PAGE}/${item.id}`}>{item.name}</NavLink>
                    </Typography>)}</>
                },
                {
                  id: 'customer',
                  label: 'Заказчик',
                  minWidth: 170
                },
                {
                  id: 'date',
                  label: 'Дата отправки'
                },
                {
                  id: 'docs',
                  label: 'Документ',
                  minWidth: 150,
                  format: (value) => cellDocs(value as CellDocs)
                },
                {
                  id: 'statuses',
                  label: 'Статус',
                  minWidth: 200,
                  format: value => cellStatus(value as CellStatus<ActStatus>)(getChipsByStatus)
                },
                {
                  id: 'amounts',
                  label: 'Сумма',
                  format: value => cellSum(value as CellSum)
                },
                {
                  id: ['id', 'filename', 'registryFilename', 'shortageFilename', 'status', 'agreementFilename', 'invoiceFilename', 'mergedFilename', 'problemComment', 'num', 'date'],
                  label: '',
                  align: 'center',
                  format: (id, filename, registryFilename, shortageFilename, status, agreementFilename, invoiceFilename, mergedFilename, problemComment, num, date) => <>
                  <MainMenu
                    id={id as Id}
                    filename={filename as string}
                    registryFilename={registryFilename as string | undefined}
                    shortageFilename={shortageFilename as string | undefined}
                    agreementFilename={agreementFilename as string | undefined}
                    mergedFilename={mergedFilename as string | undefined} // Перед демонстрацией необходимо закоментить эту строку
                    invoiceFilename={invoiceFilename as string | undefined}
                    status={status as ActStatus}
                    onCancel={handleCancel}
                    problemComment={problemComment as string | undefined}
                    num={num as number | undefined}
                    date={date as string | undefined}
                  />
                  </>
                }
              ] }
              rowsPage = {LIMIT}
              rows = {() => rows.filter(row => searched === undefined ? true : searched.includes(row.id))}
              rowsCount = { listCount }
            />
          </div>
        </div>
      </div>
      <ActRevocationWizard
        open={openRevocationModal}
        actId={revocationActId}
        onClose={() => {
          setOpenRevocationModal(false)
          loadActList()
        }}
      />
    </>
  )
}
