import './styles.sass'
import { Grid } from '@mui/material'
import { ChangeEvent } from 'react'
import InfoCard from 'ui/InfoCard'
import { ProfileForCreate } from 'api/profile'
import { SectionParams } from '../index'
import TextField from 'ui/TextField'
import { EmailMask } from 'common/Mask/EmailMask'

type Data = ProfileForCreate
type Params = SectionParams<Data> & { phone: string }

export default function BasicCarrierProfile ({ phone, data, setData, disabled, errors, onBlur }: Params) {
  const updateString = (key: keyof ProfileForCreate) =>
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
      setData((data) => ({ ...data, [key]: value === '' ? undefined : value }))

  return (
    <InfoCard title="Данные профиля">
      <Grid container spacing={2}>
        <Grid item sm={6} md={3}>
          <TextField
            name='representativeFio'
            label='ФИО представителя'
            placeholder='ФИО представителя'
            value={data.representativeFio}
            onChange={updateString('representativeFio')}
            disabled={disabled}
            errors={errors}
            onBlur={onBlur && onBlur('representativeFio')}
          />
        </Grid>
        <Grid item sm={6} md={3}>
          <TextField
            name='email'
            label='Email'
            value={data.email}
            onChange={updateString('email')}
            maskParams={{
              mask: EmailMask,
              guide: false
            }}
            disabled={disabled}
            errors={errors}
            onBlur={onBlur && onBlur('email')}
          />
        </Grid>
        <Grid item sm={6} md={2}>
          <TextField
            label='Телефон'
            value={phone}
            disabled={true}
          />
        </Grid>
      </Grid>
    </InfoCard>
  )
}
