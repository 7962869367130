import { bidList, Bid as BidInterface, BidStatus } from 'api/bid'
import { useEffect, useState } from 'react'
import BidCard from './BidCard'
import './styles.sass'
import ViewTable from './ViewTable'
import Map from 'ui/Osm'
import { Id } from 'api/Id'
import Filters from './Filters'
import { Button, ButtonGroup } from '@mui/material'
import { Public, TableRows } from '@mui/icons-material'
import NavigatePanel from 'common/CarrierBids/NavigatePanel'
import None from 'ui/None'
import CarrierProfileCompletenessAlert from 'ui/CarrierProfileCompletenessAlert'

const scrollContainerToElementWithId = (id: string) => {
  document.getElementById(id)?.scrollIntoView({ behavior: 'smooth', block: 'center' })
}

export default function CarrierBids () {
  const [selectedId, setSelectedId] = useState<Id>()
  const [initData, setInitData] = useState<BidInterface[]>()
  const [items, setItems] = useState<BidInterface[]>([])
  const [viewMap, setViewMap] = useState<boolean>(true)

  useEffect(() => {
    if (initData === undefined) {
      bidList(BidStatus.publish).then(r => {
        setInitData(r)
      })
    }
  }, [initData])

  const selectedBid = (id: Id) => {
    scrollContainerToElementWithId(id)
    setSelectedId(id)
  }

  const viewToMap = (bid: BidInterface) => {
    setSelectedId(bid.id)
  }

  return (
    <div className='carrierBids'>
      <div className='carrierBids_control'>
        <CarrierProfileCompletenessAlert />
        <NavigatePanel />
        <div className='carrierBids__control_filters'>
          <div className='carrierBids__control_filters_left'>
            <Filters initData={initData ?? []} onChangeFilters={setItems} />
          </div>
          <div className='carrierBids__control_filters_right'>
            <ButtonGroup variant='contained'>
              <Button variant={ viewMap ? 'contained' : 'outlined'} onClick={() => setViewMap(true)} style={{ background: viewMap ? '#0365F4' : undefined, borderColor: '#b2b2b2', borderRadius: '4px 0 0 4px' }}><Public sx={{ color: !viewMap ? '#B2B2B2' : '#FFFFFF' }} /></Button>
              <Button variant={!viewMap ? 'contained' : 'outlined'} onClick={() => setViewMap(false)} style={{ background: !viewMap ? '#0365F4' : undefined, borderColor: '#b2b2b2', borderRadius: '0 4px 4px 0' }}><TableRows sx={{ color: viewMap ? '#B2B2B2' : '#FFFFFF' }} /></Button>
            </ButtonGroup>
          </div>
        </div>
      </div>
      <div className='carrierBids__body'>
        <div className='carrierBids__body_content' style={{ width: !viewMap ? '100%' : undefined }}>
          { (viewMap && items.length === 0) && <div className='carrierBids__body_content_none'>
            <None desc='В настоящее время отсутствуют заявки на перевозки заданным параметрам сортировки.' m='1.5em' />
          </div> }
          { viewMap && items.map((v, i) => <BidCard data={v} selectedId={selectedId} key={i} viewToMap={viewToMap} />) }
          { !viewMap && <ViewTable data={items}/> }
        </div>
        { <div className='carrierBids__body_map' style={{ display: !viewMap ? 'none' : undefined }} >
            <Map
              zoom={12}
              markersBid={items}
              onOpenPopup={selectedBid}
              onClosePopup={() => setSelectedId(undefined)}
              viewBidById={selectedId}
            />
        </div> }
      </div>
    </div>
  )
}
