import './styles.sass'
import { useEffect, useRef, useState } from 'react'
import { Box, CircularProgress, Typography, Stack, Alert, Card as MUICard, styled } from '@mui/material'
import { StepProps } from 'ui/ModalSigning'
import { CarrierContractWizardType } from '.'
import { profileContractCreate } from 'api/profile'
import SquareButton from 'ui/SquareButton'
import download from 'util/download'
import { Visibility } from '@mui/icons-material'
import SendIcon from '@mui/icons-material/Send'
import EditNoteIcon from '@mui/icons-material/EditNote'

const Card = styled(MUICard)({
  width: '100%',
  border: '1px solid #EBEBEB',
  padding: '1em',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  userSelect: 'none',
  '&:hover': {
    borderColor: '#6DCD45',
    background: '#f3fbf0'
  }
})

const DOCUMENT_CREATE_ERROR = 'При создании договора, произошла ошибка.'

export default function Creating ({ isActive, data, handleToStep, handleNext }: StepProps) {
  const [error, setError] = useState<string>()
  const once = useRef(true)

  const d = data as CarrierContractWizardType

  useEffect(() => {
    if (!isActive) {
      return
    }

    if (!once.current) {
      return
    }
    once.current = false

    profileContractCreate(d.customer.id, d.dateTs)
      .then(result => {
        const { success, filename, conflicts } = result
        if (success) {
          d.onContract(filename)
          d.onCreated && d.onCreated(true)
          return
        }

        const { signedContract, documentCreateError } = conflicts ?? {}

        if (signedContract ?? documentCreateError) {
          setError(DOCUMENT_CREATE_ERROR)
        }
      })
      .catch(() => setError(DOCUMENT_CREATE_ERROR))
  }, [isActive, d, handleToStep, handleNext])

  const handleManually = () => {
    d.onManually(true)
    handleNext()
  }

  if (!isActive) {
    return <></>
  }

  return (<>
    <Box sx={{ display: 'flex', minWidth: '500px', minHeight: '250px' }}>
      { error === undefined
        ? d.contract === undefined
          ? <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100%' }}>
              <Stack display='flex' flexDirection='column' alignItems='center'>
                <CircularProgress />
                <Typography>Создаем заявление</Typography>
              </Stack>
          </Box>
          : <Box width='100%'>
              <Alert color='success'>
                <Typography>Заявление перевозчика успешно создано!</Typography>
              </Alert>
              <Stack direction='column'>
                <Stack direction='row' spacing={1} alignItems='center' mt='1em'>
                  <Typography>Договор оферты</Typography>
                  { d.customer.scanCarrierOffer && <SquareButton variant='outlined' color='secondary' onClick={() => download(d.customer.scanCarrierOffer as string)}>
                    <Visibility sx={{ color: '#b2b2b2' }} />
                  </SquareButton> }
                </Stack>
                <Stack direction='row' spacing={1} alignItems='center'>
                    <Typography>Заявление</Typography>
                    <SquareButton variant='outlined' color='secondary' onClick={() => download(d.contract)}><Visibility sx={{ color: '#b2b2b2' }} /></SquareButton>
                </Stack>
              </Stack>
              <Stack direction='row' spacing={1} mt='1em'>
                <Card onClick={handleManually}>
                  <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Typography>Распечатать и подписать заявление</Typography>
                    <EditNoteIcon />
                  </Stack>
                </Card>
                <Card onClick={() => handleToStep(d.diadocSignStap)}>
                  <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Typography>Подписать заявление и отправить через Диадок</Typography>
                    <SendIcon />
                  </Stack>
                </Card>
              </Stack>
          </Box>
        : <>
          <Alert severity="error">{error}</Alert>
        </>
      }
    </Box>
  </>)
}
