import { NotifyEvent } from '.'
import { useEffect } from 'react'

export interface Data {
  carrierName: string
  message: string
}

const SLUGS = ['profile_requests_new_add_drivers_event', 'profile_requests_new_update_data_event']

export default function ProfileRequestAddDriversNew ({ slug, data, toast } :NotifyEvent) {
  useEffect(() => {
    if (!slug || !SLUGS.includes(slug)) {
      return
    }

    if (toast === undefined) {
      return
    }

    if (data === undefined) {
      return
    }

    const { message } = data as Data

    toast(message, 'info')
  }, [slug, data, toast])

  return (<></>)
}
