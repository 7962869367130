import { EMAIL_PATTERN } from 'common/Mask/EmailMask'
import { makeJsonSchema, SimpleSchema } from './validate'
import { ProfileForCreate } from 'api/profile'

type Create = Required<Omit<ProfileForCreate, 'secondName' | 'contractNumber' |
  'contractTs' | 'hasHiredDrivers' | 'requests' |
  'passport' | 'organization' | 'preferredRegions' |
  'customerForwarderList' | 'representativeFio'>> & {
  secondName?: string
}

const simpleSchema: SimpleSchema<Create> = {
  type: 'object',
  properties: {
    familyName: { type: 'string', pattern: '^[а-яА-ЯёЁ\\- ]+$', minLength: 3, errorMessage: 'Некорректно заполнена фамилия' },
    firstName: { type: 'string', pattern: '^[а-яА-ЯёЁ\\- ]+$', minLength: 3, errorMessage: 'Некорректно заполнено имя' },
    secondName: { type: 'string', optional: true, pattern: '^[а-яА-ЯёЁ\\- ]+$', minLength: 3, errorMessage: 'Некорректно заполнено отчество' },
    email: { type: 'string', pattern: EMAIL_PATTERN, errorMessage: 'Некорректно заполнен Email' }
  }
}

const schema = makeJsonSchema<Create>(simpleSchema)

export default schema
