import './styles.sass'
import NavigatePanel from 'ui/NavigatePanel'
import { Box, Button, Stack, Typography, Alert as MUIAlert } from '@mui/material'
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useMainRoutes } from 'routes'
import { NavLink, useNavigate } from 'react-router-dom'
import SaveIcon from '@mui/icons-material/Save'
import LogoutIcon from '@mui/icons-material/Logout'
import { useAuthContext } from 'AuthContext'
import Alert from './Alert'
import BasicCarrierProfile from './BasicCarrierProfile'
import PasswordCarrierProfile, { Data as PasswordData, useValidate as useValidatePassword } from './PasswordCarrierProfile'
import OrganizationCarrierProfile from './OrganizationCarrierProfile'
import PassportCarrierProfile from './PassportCarrierProfile'
import DocumentsCarrierProfile from 'pages/CarrierProfile/DocumentsCarrierProfile'
import AccountingDocumentsProfile from './AccountingDocumentsProfile'
import InfoCard from 'ui/InfoCard'
import AntSwitch from 'ui/AntSwitch'
import { UserStatus } from 'api/UserStatus'
import formatDate from 'util/FormatDate'
import { userSetRegistered, userUpdatePass } from 'api/user'
import schemaProfile from 'validation/Profile'
import useValidate, { ValidateError } from 'validation/validate'
import { schemaLtd, schemaSt, schemaUploadForCreateLtdProfile, schemaUploadForCreateStProfile, clear as clearOrg } from 'validation/Organization'
import schemaPassport, { schemaPassportUploadForCreateProfile, clear as clearPassport } from 'validation/Passport'
import {
  Profile,
  ProfileForCreate,
  profileUpdate,
  profileUpdatePreferredRegions
} from 'api/profile'
import { Organization, TypeLegalPerson, TypeTaxation, organizationUpdate } from 'api/organization'
import { Passport, PassportForUpdate, passportUpdate } from 'api/passport'
import { RejectionExpand } from 'api/rejection'
import CrrierContracts from 'ui/CrrierContracts'
import { Information, informationGet } from 'api/information'
import ProfileRequests from 'ui/ProfileRequests'
import onlyFields from 'util/onlyFields'
import { metaOrganization } from 'entity/Organization'
import metaPassport from 'entity/Passport'
import DownloadZipButton, { slug } from 'ui/DownloadZipButton'
import TsToFormatDate from 'util/TsToFormatDate'
import { CBRBank } from 'api/cbr'
import checkCheckingRusAccount from 'util/checkCheckingRusAccount'
import PreferredRegions from './PreferredRegions'
import { ParticipantsCreate, ParticipantsUpdate, cleanParticipants, participantsMap, validateParticipants } from 'ui/OrganisationParticipants'
import { AddressData, getAddressDetails } from 'ui/AddressSuggestionsInput'
import excludeFields from 'util/excludeFields'
import { ACCOUNTING_DOCS_EMPTY_MSG, checkCompletenessAccountingDocs } from 'ui/CarrierProfileCompletenessAlert'
import CarrierUpdateDataRequestModal from 'ui/CarrierUpdateDataRequestModal'

export interface SectionParams<T> {
  data: T
  setData: Dispatch<SetStateAction<T>>
  disabled?: boolean
  errors?: ValidateError
  onBlur?: (key: keyof T) => () => void
}

const defaultOrg: Organization = {
  typeLegalPerson: TypeLegalPerson.st,
  typeTaxation: TypeTaxation.sts6
}

const clearOrgSplit = (org: Organization) => {
  return org.typeLegalPerson === TypeLegalPerson.organization
    ? [clearOrg(onlyFields(org,
        'inn', 'kpp', 'shortName', 'fullName',
        'okpo', 'typeTaxation', 'checkingAccountNumber',
        'bik', 'bankName', 'correspondentAccountNumber',
        'okveds', 'okved', 'ogrn', 'organizationHeadName',
        'position', 'appointmenDocumentTitle', 'legalAddress',
        'typeLegalPerson', 'mailingAddress', 'scanPowerOfAttorney'
      )),
      onlyFields(org,
        'manningTable',
        'scanInn', 'scanRegistration', 'scanTaxReturnOrFeeDeclaration',
        'scanCode', 'scanCertificate', 'accountingBalance',
        'scanInsuredPersons', 'scanReportEFS', 'scanConsentTaxpayer',
        'scanReceiptConsentTaxpayer', 'scanRegistrationAddress', 'scanRequisites',
        'scanBankCard', 'scanRegulations', 'scanAdministrationProtocol',
        'scanQuestionnaire', 'scanInformationLetter', 'scanPatent',
        'scanLicense', 'scanFinancialResults'
      )
      ]
    : [clearOrg(onlyFields(org,
        'inn', 'shortName', 'fullName',
        'ogrnip', 'okpo', 'typeTaxation', 'checkingAccountNumber',
        'bik', 'bankName', 'correspondentAccountNumber',
        'typeLegalPerson'
      )),
      onlyFields(org,
        'manningTable', 'scanTaxReturnOrFeeDeclaration',
        'scanInn', 'scanRegistration',
        'scanCode', 'scanCertificate', 'accountingBalance',
        'scanInsuredPersons', 'scanReportEFS', 'scanConsentTaxpayer',
        'scanReceiptConsentTaxpayer', 'scanRegistrationAddress', 'scanRequisites',
        'scanQuestionnaire', 'scanInformationLetter', 'scanPatent',
        'scanLicense', 'scanFinancialResults'
      )
      ]
}

const clearPassportSplit = ({ scan1, scan2, ...form }: PassportForUpdate) => [clearPassport(form), { scan1, scan2 }]

const passwordUpdate = async (oldPassword?: string, newPassword?: string): Promise<boolean | undefined> =>
  oldPassword && newPassword ? userUpdatePass(oldPassword, newPassword) : undefined

export default function CarrierProfile () {
  const { logout, handleResponseFailure, handleResponseSuccess, userStatus, profile, phone, profileRefresh } = useAuthContext()

  const [dataProfile, setDataProfile] = useState<ProfileForCreate>({})
  const { check: checkProfile, errors: errorsProfile } = useValidate(schemaProfile)

  const [dataPassword, setDataPassword] = useState<PasswordData>({})
  const { check: checkPassword, errors: errorsPassword } = useValidatePassword()

  const [dataOrg, setDataOrg] = useState<Organization>(defaultOrg)
  const { check: checkLtd, errors: errorsLtd } = useValidate(schemaLtd)
  const { check: checkLtdUp, errors: errorsLtdUp } = useValidate(schemaUploadForCreateLtdProfile)
  const { check: checkSt, errors: errorsSt } = useValidate(schemaSt)
  const { check: checkStUp, errors: errorsStUp } = useValidate(schemaUploadForCreateStProfile)

  const [dataPassport, setDataPassport] = useState<PassportForUpdate>({})
  const { check: checkPassport, errors: errorsPassport } = useValidate(schemaPassport)
  const { check: checkPassportUp, errors: errorsPassportUp } = useValidate(schemaPassportUploadForCreateProfile)

  const [rejection, setRejection] = useState<RejectionExpand>()
  const [archive, setArchive] = useState<Pick<Profile, 'archiveTs' | 'archiveComment'>>({})
  const [info, setInfo] = useState<Information>({})

  const [bankData, setBankData] = useState<CBRBank>()
  const [checkBik, setCheckBik] = useState(false)
  const [disabledBankField, setDisabledBankField] = useState(true)
  const [checkAccount, setCheckAccount] = useState(false)

  const [preferredRegions, setPreferredRegions] = useState<string[]>([])
  const [participants, setParticipants] = useState<ParticipantsUpdate[]>()

  const [registrationAddressData, setRegistrationAddressData] = useState<AddressData>()
  const [legalAddressData, setLegalAddressData] = useState<AddressData>()
  const [mailingAddressData, setMailingAddressData] = useState<AddressData>()

  const [updateDataRequest, setUpdateDataRequest] = useState(false)

  const { links, routesMap } = useMainRoutes()
  const navigate = useNavigate()

  const isActive = userStatus === UserStatus.active
  const isEdit = useMemo(() =>
    userStatus === UserStatus.rejected
  , [userStatus])

  useEffect(() => profileRefresh(), [profileRefresh])

  useEffect(() => {
    if (!profile) {
      return
    }

    const { organization, passport, archiveTs, archiveComment, rejection, requests, preferredRegions: regionsData, ...data } = profile
    const { status, registrationAddressDetails, ...passp } = passport ?? {}
    const { emails, phones, participants: prts = [], ...org } = excludeFields(organization ?? defaultOrg,
      'legalAddressDetails', 'mailingAddressDetails', 'kisQueuedId', 'kisRequestHistory', 'kisRequestHistory'
    )
    prts.length > 0 && setParticipants(participantsMap(prts))
    setDataProfile(data)
    setDataOrg(org)
    setDataPassport(passp)
    setRejection(rejection)
    setArchive({ archiveTs, archiveComment })
    if (regionsData) setPreferredRegions(regionsData)
    informationGet().then(setInfo)
  }, [profile])

  const isRegionsChanged = useMemo(() => {
    if (profile?.preferredRegions?.length !== preferredRegions.length) {
      return false
    }

    const sortedRegions1 = profile?.preferredRegions.slice().sort()
    const sortedRegions2 = preferredRegions.slice().sort()

    for (let i = 0; i < sortedRegions2.length; i++) {
      if (sortedRegions1[i] !== sortedRegions2[i]) {
        return false
      }
    }

    return true
  }, [profile, preferredRegions])

  const handleSaveClick = useCallback(async () => {
    const promises: PromiseLike<{
      success: boolean
      changed: boolean
      finalize: boolean
      message: string
    }>[] = []

    const [dataOrgForm, dataOrgUp] = clearOrgSplit(dataOrg)

    if (isEdit) {
      const isLtd = dataOrg.typeLegalPerson === TypeLegalPerson.organization
      const checkOrg = isLtd ? checkLtd : checkSt
      const checkOrgUp = isLtd ? checkLtdUp : checkStUp
      const [dataPassportForm, dataPassportUp] = clearPassportSplit(dataPassport)

      const vbik = bankData?.bik === dataOrg.bik
      setCheckBik(vbik)

      const vAccaunt = (!!bankData?.accounts.find(item => item.correspondentAccountNumber === dataOrg.correspondentAccountNumber) && checkCheckingRusAccount(dataOrg.correspondentAccountNumber))
      const vParticipants = validateParticipants(participants, setParticipants)

      setCheckAccount(vAccaunt)

      const clearProfile = onlyFields(dataProfile, 'email', 'firstName', 'familyName', 'secondName', 'hasHiredDrivers', 'representativeFio')
      const errorMessages = [
        checkProfile(clearProfile) || 'Данные профиля заполнены не правильно',
        checkPassword(dataPassword) || 'Пароли заполнены не правильно',
        (checkOrg(dataOrgForm) && checkOrgUp(dataOrgUp) && vbik && vAccaunt && vParticipants) || 'Данные организации заполнены не правильно',
        (checkPassport(dataPassportForm) && checkPassportUp(dataPassportUp)) || 'Паспортные данные заполнены не правильно'
      ].filter((x): x is string => typeof x === 'string')

      if (errorMessages.length > 0) {
        handleResponseFailure(<Stack gap={1}>
          { errorMessages.map((message, idx) => <Box key={idx}>{ message }</Box>) }
        </Stack>)
        return
      }

      promises.push(
        profileUpdate(clearProfile)
          .then((changed) => ({
            success: true,
            changed,
            finalize: true,
            message: changed ? 'Данные профиля записаны' : ''
          }))
          .catch(() => ({
            success: false,
            changed: false,
            finalize: true,
            message: 'Ошибка записи профиля'
          })),
        passwordUpdate(dataPassword.passwordOld, dataPassword.passwordNew)
          .then((success) => ({
            success: success ?? true,
            changed: success === true,
            finalize: false,
            message: success === undefined ? '' : success ? 'Пароль обновлён' : 'Старый пароль не подходит'
          }))
          .catch(() => ({
            success: false,
            changed: false,
            finalize: false,
            message: 'Ошибка обновления пароля'
          })),
        organizationUpdate({
          ...dataOrgForm,
          ...dataOrgUp,
          participants: participants?.length ? cleanParticipants(participants as ParticipantsCreate[]) : undefined,
          legalAddressDetails: legalAddressData ? getAddressDetails(legalAddressData) : undefined,
          mailingAddressDetails: mailingAddressData ? getAddressDetails(mailingAddressData) : undefined
        })
          .then((changed) => ({
            success: true,
            changed,
            finalize: true,
            message: changed ? 'Данные организации записаны' : ''
          }))
          .catch(() => ({
            success: false,
            changed: false,
            finalize: true,
            message: 'Ошибка записи организации'
          })),
        passportUpdate({
          ...dataPassport,
          registrationAddressDetails: registrationAddressData ? getAddressDetails(registrationAddressData) : undefined
        })
          .then((changed) => ({
            success: true,
            changed,
            finalize: true,
            message: changed ? 'Паспортные данные записаны' : ''
          }))
          .catch(() => ({
            success: false,
            changed: false,
            finalize: true,
            message: 'Ошибка записи паспортных данных'
          }))
      )
    } else {
      promises.push(
        organizationUpdate({
          ...dataOrgUp
        })
          .then((changed) => ({
            success: true,
            changed,
            finalize: true,
            message: changed ? 'Данные организации записаны' : ''
          }))
          .catch(() => ({
            success: false,
            changed: false,
            finalize: true,
            message: 'Ошибка записи организации'
          })),
        passwordUpdate(dataPassword.passwordOld, dataPassword.passwordNew)
          .then((success) => ({
            success: success ?? true,
            changed: success === true,
            finalize: false,
            message: success === undefined ? '' : success ? 'Пароль обновлён' : 'Старый пароль не подходит'
          }))
          .catch(() => ({
            success: false,
            changed: false,
            finalize: false,
            message: 'Ошибка обновления пароля'
          }))
      )
    }

    const results = await Promise.all<{
      success: boolean;
      changed: boolean;
      finalize: boolean;
      message: string;
    }>(promises)

    const finalize = results.filter(({ finalize }) => finalize)

    if (isEdit && finalize.every(({ success }) => success) && finalize.some(({ changed }) => changed)) {
      results.push(
        await userSetRegistered()
          .then((changed) => ({
            success: true,
            changed,
            finalize: true,
            message: changed ? 'Статус пользователя изменён' : ''
          }))
          .catch(() => ({
            success: false,
            changed: false,
            finalize: true,
            message: 'Ошибка изменения статуса пользователя'
          }))
      )
    }

    profileRefresh()

    const success = results.filter(({ success, message }) => success && message)
    const failure = results.filter(({ success, message }) => !success && message)

    if (success.length > 0) {
      handleResponseSuccess(<Stack gap={1}>
        { success.map(({ message }, idx) => <Box key={idx}>{ message }</Box>) }
      </Stack>, 'update-carrier-profile-success')
    }

    if (failure.length > 0) {
      handleResponseFailure(<Stack gap={1}>
        { failure.map(({ message }, idx) => <Box key={idx}>{ message }</Box>) }
      </Stack>, 'update-carrier-profile-failure')
    }

    if (success.length === 0 && failure.length === 0) {
      handleResponseSuccess('Данные не изменены')
    }
  }, [checkLtd, checkLtdUp, checkPassport, checkPassportUp, checkPassword,
    checkProfile, checkSt, checkStUp, dataOrg, dataPassport, dataPassword,
    dataProfile, handleResponseFailure, handleResponseSuccess, isEdit,
    profileRefresh, bankData, participants, legalAddressData, mailingAddressData, registrationAddressData])

  const addPreferredRegions = (value: string[]) => {
    profileUpdatePreferredRegions(value)
      .then((success) => {
        if (success === undefined) {
          return
        }

        if (success) {
          handleResponseSuccess('Предпочтительные регионы изменены')
        } else {
          handleResponseFailure('Ошибка изменений регионов')
        }
      })
      .catch((e) => {
        handleResponseFailure('Ошибка изменений регионов')
      })
  }
  // debug
  useEffect(() => {
    const out = {
      errorsProfile,
      errorsPassword,
      errorsLtd,
      errorsLtdUp,
      errorsSt,
      errorsStUp,
      errorsPassport,
      errorsPassportUp
    }
    const entries = Object.entries(out).filter(([_key, value]) => value)
    entries.length > 0 && console.error(Object.fromEntries(entries))
  }, [
    errorsLtd,
    errorsLtdUp,
    errorsPassport,
    errorsPassportUp,
    errorsPassword,
    errorsProfile,
    errorsSt,
    errorsStUp
  ])

  const pack = useMemo(() => {
    const filesOrgs = onlyFields(dataOrg,
      'scanInn', 'scanRegistration', 'scanCode',
      'scanCertificate', 'scanTaxReturnOrFeeDeclaration', 'scanRequisites',
      'scanConsentTaxpayer', 'scanReceiptConsentTaxpayer',
      'scanRegistrationAddress', 'scanQuestionnaire',
      'scanInformationLetter', 'scanPatent', 'scanInsuredPersons',
      'scanLicense', 'scanFinancialResults',
      'scanBankCard', 'scanRegulations', 'scanAdministrationProtocol',
      'scanReportEFS', 'manningTable', 'accountingBalance')

    const filesPassport = onlyFields(dataPassport, 'scan1', 'scan2')

    const metaOrg = metaOrganization(dataOrg.typeLegalPerson)

    const pack = [...Object.entries(filesOrgs)]
      .map(([key, filename]) => ({ filename, toName: metaOrg.title(key as keyof Organization) }))

    pack.push(...[...Object.entries(filesPassport)]
      .map(([key, filename]) => ({ filename, toName: metaPassport.title(key as keyof Passport), inFolder: 'Паспорт' })))

    return pack.filter(item => item.filename)
  }, [dataOrg, dataPassport])

  return (
    <>
      <div className='carrierProfile'>
        <NavigatePanel
          title='Профиль перевозчика'
          breadcrumbs={{
            items: routesMap.getBreadcrumbs(links.PROFILE_PAGE)
          }}
          actions={
            <Stack direction='row' spacing={2} justifyContent='end'>
              { !isEdit && <Button variant='contained' onClick={() => setUpdateDataRequest(true)}>Запрос изменения данных</Button> }
              <DownloadZipButton pack={pack} archiveName={slug(`перевозчик_${dataOrg.shortName}`)} />
              <Button variant='contained' size='small' onClick={() => logout()} className='address__btn' sx={{ background: '#EE6A5F', color: '#FFF' }}>
                Выйти из профиля <LogoutIcon sx={{ width: '15px', height: '15px', ml: '10px' }}/>
              </Button>
              <Button variant='outlined' size='small' onClick={() => navigate(0)} className='address__btn'>
                Отменить
              </Button>
              <Button variant='contained' size='small' onClick={handleSaveClick} className='address__btn' sx={{ background: '#6DCD45' }}>
                Сохранить <SaveIcon sx={{ width: '15px', height: '15px', ml: '10px' }}/>
              </Button>
            </Stack>
          }
        />
        <div className='carrierProfile__body'>
          <div className='carrierProfile__content'>
          { profile && checkCompletenessAccountingDocs(dataOrg, profile) && userStatus === UserStatus.active && <MUIAlert color='error'>
            <Typography>{ACCOUNTING_DOCS_EMPTY_MSG}</Typography>
          </MUIAlert> }
            { userStatus && [UserStatus.registered, UserStatus.confirmed].includes(userStatus) && <Alert
              color="gray"
              title="Аккаунт на рассмотрении"
            >
              В данный момент ваш аккаунт находится на рассмотрении у администраторов.<br/>
              Оставайтесь в системе, чтобы знать свой статус. Спасибо за ожидание!
            </Alert>}
            { userStatus === UserStatus.rejected && <Alert
              color="red"
              title = {<>Отказано в регистрации {rejection?.createTs ? TsToFormatDate(rejection.createTs, 'dd.MM.yyyy') : ''}</>}
            >
              Мы рассмотрели ваши данные, указанные при регистрации.<br/>
              К сожалению, добавление в систему невозможно.<br/>

              {rejection && <>
                Причина отказа: {rejection.reason.name}<br />
                { rejection.comment && <Typography sx={{ whiteSpace: 'pre-wrap' }}>Комментарий: {rejection.comment}</Typography> }
              </>}

              Дополнительную информацию вы можете получить по адресу info@aston.ru
            </Alert>}
            { userStatus === UserStatus.blocked && <Alert
              color="red"
              title="Профиль заблокирован"
            >
              Дополнительную информацию вы можете получить по адресу info@aston.ru
            </Alert>}
            { userStatus === UserStatus.archive && <Alert
              color="gray"
              title = {<>Аккаунт был архивирован {archive.archiveTs ? formatDate(new Date(archive.archiveTs * 1000)) : ''}</>}
            >
              {archive.archiveComment && <Box sx={{ whiteSpace: 'pre-wrap' }}>{archive.archiveComment}</Box>}
            </Alert>}
            <BasicCarrierProfile
              phone={phone ?? ''}
              data={dataProfile}
              setData={setDataProfile}
              disabled={!isEdit}
              errors={errorsProfile}
            />
            <PasswordCarrierProfile
              data={dataPassword}
              setData={setDataPassword}
              errors={errorsPassword}
            />
            { isActive && <CrrierContracts profile={profile} signing /> }
            { isActive && <ProfileRequests profile={profile} /> }
            <OrganizationCarrierProfile
              data={dataOrg}
              setData={setDataOrg}
              disabled={!isEdit}
              errors={errorsLtd ?? errorsSt}
              bankData={bankData}
              setBankData={setBankData}
              checkBik={checkBik}
              setCheckBik={setCheckBik}
              disabledBankField={disabledBankField}
              setDisabledBankField={setDisabledBankField}
              checkAccount={checkAccount}
              setCheckAccount={setCheckAccount}
              participants={participants}
              setParticipants={setParticipants}
              legalAddressData={legalAddressData}
              setLegalAddressData={setLegalAddressData}
              mailingAddressData={mailingAddressData}
              setMailingAddressData={setMailingAddressData}
            />
            <PassportCarrierProfile
              data={dataPassport}
              setData={setDataPassport}
              disabled={!isEdit}
              errors={errorsPassport}
              registrationAddressData={registrationAddressData}
              setRegistrationAddressData={setRegistrationAddressData}
              dataProfile={dataProfile}
              setDataProfile={setDataProfile}
              errorsProfile={errorsProfile}
            />
            <DocumentsCarrierProfile
              organization={{
                data: dataOrg,
                setData: setDataOrg,
                disabled: !isEdit,
                errors: errorsLtdUp ?? errorsStUp
              }}
              passport={{
                data: dataPassport,
                setData: setDataPassport,
                disabled: !isEdit,
                errors: errorsPassportUp
              }}
            />
            <AccountingDocumentsProfile
              data={dataOrg}
              setData={setDataOrg}
              profile={dataProfile}
              setProfile={setDataProfile}
              // disabled={!isEdit}
              errors={errorsLtdUp ?? errorsStUp}
              info={info}
            />
            <InfoCard>
              <Stack direction='row' justifyContent='left' alignItems='center' sx={{ gap: '10px' }}>
                <AntSwitch disabled={true} checked={true} inputProps={{ 'aria-label': 'ant design' }} />
                <Typography><NavLink to={links.POLICY_PERSONAL_DATA_PAGE} style={{ color: '#111111' }}>Cогласен на обработку персональных данных</NavLink></Typography>
              </Stack>
            </InfoCard>
            { isActive && <PreferredRegions
              data={preferredRegions}
              setData={setPreferredRegions}
              addPreferredRegions={addPreferredRegions}
              isRegionsChanged={isRegionsChanged}
            /> }
          </div>
        </div>
      </div>
      <CarrierUpdateDataRequestModal
        open={updateDataRequest}
        onCancel={() => setUpdateDataRequest(false)}
        onSuccess={() => {
          setUpdateDataRequest(false)
          profileRefresh()
        }}
      />
    </>
  )
}
